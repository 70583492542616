import styled from "styled-components";
import { Grid } from "@mui/material";

export const StyledContainer = styled.div`
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
  //   text-align: center;
`;

export const StyledGrid = styled(Grid)`
  && {
    margin-top: 20px;
  }
`;
