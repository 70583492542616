/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getGameLists } from '@api/cms';
import { Button, Icon, } from "@mui/material";
import GameListModal from '@components/games/GameListModal';



const GameListTab = (props) => {

    const { needRefresh } = props;

    const [gameLists, setGameLists] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const getData = async () => {
        // const res = await getGroups(lang);
        // setGroups(res ? res.map(r => {
        //     return { ...r, imgUrl: `${IMAGE_DOMAIN}/${r.imagePath}` }
        // }) : [])
        const listRes = await getGameLists(true);

        setGameLists(listRes ?? [])
    }

    useEffect(() => {
        getData();
        // addItem()
    }, [needRefresh])

    const addItem = () => {
        setSelectedItem({
            isAddNew: true,
            id: -1,
            name: '',
            image: null,
            imgUrl: '',
            grp: '',
            defaultTab: false,
            sort: 1
        })
    }

    const handleCloseModal = async (reGetData) => {
        if (reGetData) await getData()
        setSelectedItem(null)
    }

    return (
        (<GroupPageWrap>
            <Button
                variant="contained"
                color="secondary"
                onClick={addItem}
                mt={10}
            >
                <Icon>add</Icon>&nbsp;
                新增遊戲清單
            </Button>
            <GroupsCard>
                {
                    gameLists && gameLists.map(n => (
                        <div key={n.id} onClick={() => setSelectedItem(n)}>
                            <p>{`名稱: ${n.name}`}</p>
                            <p>{`型態: ${n.type === 0 ? '一般' : '進階'}`}</p>
                            <p>{`${n.listItems.length}項遊戲`}</p>
                        </div>
                    ))
                }
            </GroupsCard>
            <GameListModal show={selectedItem !== null} item={selectedItem} allLists={gameLists} onClose={handleCloseModal} />
        </GroupPageWrap>)
    );
};

export default GameListTab;
const GroupPageWrap = styled.div`

    margin-top: 20px;
    display: flex;
    flex-direction: column;    
    width: 100%;

    >button {
        margin-left: auto; // Pushes the button to the right
        margin-top: 10px; // Keeps the top margin
        margin-bottom: 10px; // Keeps the bottom margin
        width: 200px;
    }
`;

const GroupsCard = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;

    > div {
        width: 200px;
        min-width: 120px;
        cursor: pointer;
        background: antiquewhite;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        >p {
            margin: 0;
            margin-bottom: 5px;
        }
        >img {       
            height: 100px;
            width: 100px;
            margin: auto;
        }
    }
`