import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import BaseModal from "src/components/shared/modals/BaseModal";
import { FlexDirectionConfigs } from "@constants/config";
import { UploadImageWithTitle } from "@components/shared/ImageUploadZone";
import { deleteGame, postGame, putGame } from "@api/cms";
import {
  Button,
} from "@mui/material";
import { IMAGE_DOMAIN } from '@constants/api';

const GamesModal = (props) => {
  const { show, item, onClose } = props;
  const [gameId, setGameId] = useState("");
  const [nameCH, setNameCH] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [source, setSource] = useState("AW");
  const [enabled, setEnabled] = useState(true);
  const [isFreeGame, setIsFreeGame] = useState(true);
  const [tempImageFilesCH, setTempImageFilesCH] = useState({}); //id: Files
  const [tempPreviewCH, setTempPreviewCH] = useState({}); //id: preview url
  const [tempImageFilesEN, setTempImageFilesEN] = useState({}); // For English image files
  const [tempPreviewEN, setTempPreviewEN] = useState({}); // For English image previews
  const [isLoading, setIsLoading] = useState(false);

  const initStates = () => {
    setNameCH("");
    setNameEN("");
    setSource("AW");
    setEnabled(true);
    setTempImageFilesCH({});
    setTempPreviewCH({});
    setTempImageFilesEN({});
    setTempPreviewEN({});
    setIsFreeGame(true);
    setGameId("");

  };
  useEffect(() => {
    if (!show) {
      initStates();
    } else if (item) {
      console.log(" --->   ", item.source)

      setGameId(item.gameID);
      setNameCH(item.nameCH);
      setNameEN(item.nameEN);
      if (!item.source || item.source === 'null' || item.source === 'undefined') {
        setSource('AW');
      } else {
        setSource(item.source);
      }

      setEnabled(item.enabled ?? true);
      setIsFreeGame(item.isFree ?? true);

    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, [show]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const setTempImageCH = (id, file, preview) => {
    if (file && preview) {
      setTempImageFilesCH({ ...tempImageFilesCH, [id]: file });
      setTempPreviewCH({ ...tempPreviewCH, [id]: preview });
    }
  };

  const setTempImageEN = (id, file, preview) => {
    if (file && preview) {
      setTempImageFilesEN({ ...tempImageFilesEN, [id]: file });
      setTempPreviewEN({ ...tempPreviewEN, [id]: preview });
    }
  };


  const handleDeleteImage = async (id) => {
    if (tempImageFilesCH.hasOwnProperty(id)) {
      let newTempFileCH = { ...tempImageFilesCH };
      let newTempPreviewCH = { ...tempPreviewCH };
      delete newTempFileCH[id];
      delete newTempPreviewCH[id];
      setTempImageFilesCH({ ...newTempFileCH });
      setTempPreviewCH({ ...newTempPreviewCH });
    }
    if (tempImageFilesEN.hasOwnProperty(id)) {
      let newTempFileEN = { ...tempImageFilesEN };
      let newTempPreviewEN = { ...tempPreviewEN };
      delete newTempFileEN[id];
      delete newTempPreviewEN[id];
      setTempImageFilesEN({ ...newTempFileEN });
      setTempPreviewEN({ ...newTempPreviewEN });
    }
  };
  const handleSubmit = async () => {

    if (gameId.length !== 4) {
      // Display error toast
      toast.error("遊戲ID必須是4位數字");
      return; // Exit the function early
    }


    setIsLoading(true);
    let request = {
      ID: item.isAddNew ? undefined : item.id,
      NameEN: nameEN,
      NameCH: nameCH,
      GameID: gameId,
      Source: source,
      Enabled: enabled,
      IsFree: isFreeGame
    };
    if (item.isAddNew) {
      delete request.ID;
    }
    if (tempImageFilesCH[item.id]) {
      request.ImageCH = tempImageFilesCH[item.id];
    } else {
      request.ImagePathCH = item.imagePathCH;
    }

    if (tempImageFilesEN[item.id]) {
      request.ImageEN = tempImageFilesEN[item.id];
    } else {
      request.ImagePathEN = item.imagePathEN;
    }


    let res;

    try {
      if (item.isAddNew) res = await postGame(request);
      else res = await putGame(request);
      if (res?.errors)
        try {
          toast.error(`${res?.status}. ${JSON.stringify(res?.errors)}`);

        } catch (error) {
          toast.error(`${res?.status}. ${res?.errors}`);

        }
      else if (res) {
        toast.success(item.isAddNew ? "新增成功" : "修改成功");
        onClose(true);
      }
    } catch (error) {
      toast.error(`${error}`);
    }


    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    let res = await deleteGame(item.id);
    try {
      if (JSON.stringify(res) === "{}") {
        toast.success("修改成功");
        onClose(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    toast.error("修改失敗");
    setIsLoading(false);
  };

  return (
    <BaseModal
      show={show}
      justifyContent={FlexDirectionConfigs.Center}
      alignItems={FlexDirectionConfigs.Center}
      handleOutsideClick={stopPropagation}
    >
      {item && (
        <GroupsContent onClick={stopPropagation}>
          {item.isAddNew && <p>新增遊戲</p>}
          <div className="groups-modal-head">
            <div className="upload-section">
              <UploadImageWithTitle
                width="150px"
                classnames="groups-modal-upload-warp"
                outerClassname="groups-modal-upload-image"
                // title={`${item.imagePathCH?.split("/")[1] || ""}`}
                title={`中文Icon`}
                data={{ ...item, tempImageUrl: tempPreviewCH[item.id], imgUrl: item.imagePathCH ? `${IMAGE_DOMAIN}/${item.imagePathCH}` : null }}
                setTempImage={setTempImageCH}
                onDelete={handleDeleteImage}
                hideTrashCan={!tempPreviewCH[item.id]}
              />


              <UploadImageWithTitle
                width="150px"
                classnames="groups-modal-upload-warp"
                outerClassname="groups-modal-upload-image"
                // title={`${item.imagePathEN?.split("/")[1] || ""}`} // Use a separate path or naming convention for English images
                title={`英文Icon`}
                data={{ ...item, tempImageUrl: tempPreviewEN[item.id], imgUrl: item.imagePathEN ? `${IMAGE_DOMAIN}/${item.imagePathEN}` : null }} // Use the English preview state
                setTempImage={setTempImageEN} // Use the set function for English images
                onDelete={handleDeleteImage} // You may need a separate delete function for English images
                hideTrashCan={!tempPreviewEN[item.id]}
              />

            </div>

            <div>
              <span>來源</span>
              <select value={source} onChange={(e) => setSource(e.target.value)}>
                <option value="AW">AW</option>
                <option value="BBAG">BBAG</option>
              </select>
              <span>遊戲ID</span>
              <input value={gameId} onChange={(e) => setGameId(e.target.value)} />
              <span>中文名稱</span>
              <input value={nameCH} onChange={(e) => setNameCH(e.target.value)} />
              <span>英文名稱</span>
              <input value={nameEN} onChange={(e) => setNameEN(e.target.value)} />

              <span>啟用</span>
              <div>
                <input
                  type="radio"
                  id="enabled-true"
                  value={enabled}
                  checked={enabled === true}
                  onChange={(e) => setEnabled(true)}
                />
                <label htmlFor="enabled-true">是</label>
                <input
                  type="radio"
                  id="enabled-false"
                  value={enabled}
                  checked={enabled === false}
                  onChange={(e) => setEnabled(false)}
                />
                <label htmlFor="enabled-false">否</label>
              </div>

              <span>鎖定遊戲</span>
              <div>
                <input
                  type="radio"
                  id="is-free-true"
                  value={isFreeGame}
                  checked={isFreeGame === false}
                  onChange={(e) => setIsFreeGame(false)}
                />
                <label htmlFor="is-free-true">是</label>
                <input
                  type="radio"
                  id="is-free-false"
                  value={isFreeGame}
                  checked={isFreeGame === true}
                  onChange={(e) => setIsFreeGame(true)}
                />
                <label htmlFor="is-free-false">否</label>
              </div>
            </div>
          </div>
          <div className="close-icon" onClick={() => onClose()} />

          <br />
          <div className="groups-modal-btn-wrap">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              儲存
            </Button>
            {!item.isAddNew && (
              <Button
                // className="delete-btn"
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isLoading}
              >
                刪除
              </Button>
            )}
          </div>
        </GroupsContent>
      )}
    </BaseModal>
  );
};

export default GamesModal;

const GroupsContent = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background: white;
  padding: 10px;
  width: 70%;

  .groups-modal-head {
    display: flex;
    width: 80%;
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      width: 100%;
      > input {
        width: 100%;
        padding-left: 10px;
      }
      > span:nth-child(odd) {
        margin-top: 20px;
      }
    }

    .upload-section {
      
    
    }


  }

  .groups-modal-upload-warp {
    margin: 0px 20px 10px 0px;

    .groups-modal-upload-image {
      
      width: 150px;
      height: 150px;
      > div {
        height: 100%;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    mask-image: url("/images/shared/close.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    min-width: 18px;
    min-height: 18px;
    background: black;
  }

  .groups-modal-btn-wrap {
    display: flex;
    margin-bottom: 5px;
    gap: 50px;
    .delete-btn {
      color: red;
    }
  }
`;
