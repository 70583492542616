import { SWITCH_ROUTES } from "./routes";
import { getPermissionsFromLS } from "@utils/ls";
// import HomeIcon from "@mui/icons-material/Home";

const GW_PROD_DOMAIN = "bo.gw123.net";
const GW_STG_DOMAIN = "bo-stg.gw123.net";

const AW_PROD_KH_DOMAIN = "kh-bo.aw123.cc";
const AW_PROD_MM_DOMAIN = "mm-bo.aw123.cc";
const AW_PROD_VN_DOMAIN = "vn-bo.aw123.cc";
const AW_PROD_BD_DOMAIN = "bd-bo.aw123.cc";
const AW_PROD_LA_DOMAIN = "la-bo.aw123.cc";
const AW_PROD_KE_DOMAIN = "ke-bo.aw123.cc";
const AW_PROD_NL_DOMAIN = "nl-bo.aw123.cc";


const AW_STG_KH_DOMAIN = "kh-bo-stg.aw123.cc";
const AW_STG_MM_DOMAIN = "mm-bo-stg.aw123.cc";
const AW_STG_VN_DOMAIN = "vn-bo-stg.aw123.cc";
const AW_STG_BD_DOMAIN = "bd-bo-stg.aw123.cc";
const AW_STG_LA_DOMAIN = "la-bo-stg.aw123.cc";
const AW_STG_KE_DOMAIN = "ke-bo-stg.aw123.cc";
const AW_STG_NL_DOMAIN = "nl-bo-stg.aw123.cc";

const PECH_PROD_DOMAIN = "bo.pech.mobi";
const PECH_STG_DOMAIN = "bo-stg.pech.mobi";

const BITSBET_PROD_DOMAIN = "bo.bitsbet.co.ke";
const BITSBET_STG_DOMAIN = "bo-stg.bitsbet.co.ke";

const LOCALHOST = "localhost";

export const IS_LOCALHOST = window.location.hostname === LOCALHOST;


export const IS_GW_PORD = window.location.hostname === GW_PROD_DOMAIN;
export const IS_GW_STG = window.location.hostname === GW_STG_DOMAIN;

export const IS_GW = IS_GW_PORD || IS_GW_STG;
export const IS_PECH_PROD = window.location.hostname === PECH_PROD_DOMAIN;
export const IS_PECH_STG = window.location.hostname === PECH_STG_DOMAIN;

export const IS_BITSBET_PROD = window.location.hostname === BITSBET_PROD_DOMAIN;
export const IS_BITSBET_STG = window.location.hostname === BITSBET_STG_DOMAIN;

export const IS_AW_KH_PROD = window.location.hostname === AW_PROD_KH_DOMAIN;
export const IS_AW_MM_PROD = window.location.hostname === AW_PROD_MM_DOMAIN;
export const IS_AW_VN_PROD = window.location.hostname === AW_PROD_VN_DOMAIN;
export const IS_AW_BD_PROD = window.location.hostname === AW_PROD_BD_DOMAIN;
export const IS_AW_LA_PROD = window.location.hostname === AW_PROD_LA_DOMAIN;
export const IS_AW_KE_PROD = window.location.hostname === AW_PROD_KE_DOMAIN;
export const IS_AW_NL_PROD = window.location.hostname === AW_PROD_NL_DOMAIN;


export const IS_AW_KH_STG = window.location.hostname === AW_STG_KH_DOMAIN;
export const IS_AW_MM_STG = window.location.hostname === AW_STG_MM_DOMAIN;

export const IS_AW_VN_STG = window.location.hostname === AW_STG_VN_DOMAIN;
// export const IS_AW_VN_STG = true;

export const IS_AW_BD_STG = window.location.hostname === AW_STG_BD_DOMAIN;
export const IS_AW_LA_STG = window.location.hostname === AW_STG_LA_DOMAIN;
export const IS_AW_KE_STG = window.location.hostname === AW_STG_KE_DOMAIN;
export const IS_AW_NL_STG = window.location.hostname === AW_STG_NL_DOMAIN;

export const IS_AW_KH = IS_AW_KH_PROD || IS_AW_KH_STG;
export const IS_AW_MM = IS_AW_MM_PROD || IS_AW_MM_STG;
export const IS_AW_VN = IS_AW_VN_PROD || IS_AW_VN_STG;
export const IS_AW_BD = IS_AW_BD_PROD || IS_AW_BD_STG;
export const IS_AW_LA = IS_AW_LA_PROD || IS_AW_LA_STG;
export const IS_AW_KE = IS_AW_KE_PROD || IS_AW_KE_STG;
export const IS_AW_NL = IS_AW_NL_PROD || IS_AW_NL_STG;
export const IS_BITSBET = IS_BITSBET_PROD || IS_BITSBET_STG;


export const IS_PECH = IS_PECH_PROD || IS_PECH_STG;

export const IS_KH = IS_AW_KH || IS_PECH;

export const TINY_EDITOR_KEY =
  "qr1leyki1b6omgfas7y1c1bpcoo5hvmssxgvlkh7txjky47h";

export const NEW_MENU_ITEMS = () => {
  const permissions = getPermissionsFromLS();
  const items = [];
  for (const menuItem of MENU_ITEMS) {
    if (permissions[menuItem.key]) {
      items.push(menuItem);
    }
  }
  return items;
};

export const MENU_ITEMS = [
  // {
  //     "menuName": "首頁",
  //     "icon": "home",
  //     "path": "/"
  // },
  {
    menuName: "跑馬燈",
    icon: "directions_run",
    path: SWITCH_ROUTES.MARQUEE,
    key: "marquee",
  },
  {
    menuName: "橫幅",
    icon: "view_carousel",
    path: SWITCH_ROUTES.BANNER,
    key: "banner",
  },
  {
    menuName: "公告",
    icon: "campaign",
    path: SWITCH_ROUTES.ANNOUNCEMENT,
    key: "announcement",
  },
  {
    menuName: "News",
    icon: "newspaper",
    path: SWITCH_ROUTES.NEWS,
    key: "news",
  },
  {
    menuName: "分類",
    icon: "category",
    path: SWITCH_ROUTES.GROUPS,
    key: "groups",
  },
  {
    menuName: "遊戲",
    icon: "games",
    path: SWITCH_ROUTES.GAMES,
    key: "games",
  },
  {
    menuName: "存/提Policy",
    icon: "currency_exchange",
    path: SWITCH_ROUTES.D_W_POLICY,
    key: "policy",
  },
  {
    menuName: "Logos",
    icon: "auto_awesome",
    path: SWITCH_ROUTES.LOGOS,
    key: "logos",
  },
  {
    menuName: "主題色",
    icon: "format_color_fill",
    path: SWITCH_ROUTES.THEME,
    key: "theme",
  },
  {
    menuName: "維護",
    icon: "construction",
    path: SWITCH_ROUTES.MAINTENANCES,
    key: "maintenances",
  },
  {
    menuName: "前台設定",
    icon: "settings",
    path: SWITCH_ROUTES.SYSTEM,
    key: "system",
  },
  {
    menuName: "後台設定",
    icon: "palette",
    path: SWITCH_ROUTES.ADMIN_PANEL,
    key: "adminPanel",
  },
  {
    menuName: "後台使用者",
    icon: "supervisor_account",
    path: SWITCH_ROUTES.USERS,
    key: "users",
  },

  // {
  //     "menuName": "登入",
  //     "icon": "home",
  //     "path": "/login"
  // }
];

export const API_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const DISPLAY_DATE_TIME_FORMAT = "yyyy/MM/dd HH:mm";
export const DISPLAY_MUI_DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm";

export const LANGS = {
  Cn: "cn",
  En: "en",
  Vietnamese: "vietnamese",
  Bahasa: "bahasa",
  Khmer: "khmer",
  Myanmar: "myanmar",
  Bengali: "bengali",
  Laotian: "laotian",
  Thai: "thai",
  Nederland: "nederland"
};

function getLanguageSelections() {
  // return [
  //   { label: "简体中文", value: LANGS.Cn },
  //   { label: "English", value: LANGS.En },
  //   { label: "Tiếng Việt", value: LANGS.Vietnamese },
  //   { label: "မြန်မာဘာသာ", value: LANGS.Myanmar },
  //   { label: "ភាសាខ្មែរ", value: LANGS.Khmer },
  // ];

  const baseLangs = [
    { label: "简体中文", value: LANGS.Cn, code: 'zh-cn' },
    { label: "English", value: LANGS.En, code: 'en-us' },
  ];
  if (IS_GW) {
    return [
      ...baseLangs,
      { label: "Bahasa (马来文)", value: LANGS.Bahasa, code: 'ms-my' },
      { label: "Tiếng Việt (越文)", value: LANGS.Vietnamese, code: 'vi-vn' },
    ];
  } else if (IS_KH) {
    return [...baseLangs, { label: "ភាសាខ្មែរ（柬文）", value: LANGS.Khmer, code: '' }];
  } else if (IS_AW_MM) {
    return [
      ...baseLangs,
      { label: "မြန်မာဘာသာ (缅甸文)", value: LANGS.Myanmar, code: 'my-mm' },
    ];
  } else if (IS_AW_VN) {
    return [
      ...baseLangs,
      { label: "Tiếng Việt (越文)", value: LANGS.Vietnamese, code: 'vi-vn' },
    ];
  } else if (IS_AW_BD) {
    return [...baseLangs, { label: "বাংলা (孟加拉文)", value: LANGS.Bengali, code: '' }];
  } else if (IS_AW_LA) {
    return [
      ...baseLangs,
      { label: "ອັກສອນລາວ (老撾文)", value: LANGS.Laotian, code: '' },
      { label: "ภาษาไทย (泰文)", value: LANGS.Thai, code: 'th-th' },
    ];
  } else if (IS_AW_KE || IS_BITSBET) {
    return baseLangs;
  } else if (IS_AW_NL) {
    return [...baseLangs, { label: "Nederland", value: LANGS.Nederland, code: '' }];
  }

  return baseLangs;
}

export const LANG_SELECTOR_ITEMS = getLanguageSelections();

export const FlexDirectionConfigs = {
  FlexStart: "flex-start",
  FlexEnd: "flex-end",
  Center: "center",
};

export const HTML_EDITOR_INIT_CONFIG = {
  selector: "textarea#default-editor",
  height: 500,
  // automatic_uploads: true,
  image_uploadtab: true,
  // images_upload_url: APIS.HTML_EDITOR.ANNOUNCES,
  // menubar: false,
  // plugins: 'advlist autolink link image lists charmap preview',
  plugins:
    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount",
  // toolbar: 'undo redo | formatselect | ' +
  // 'bold italic backcolor | alignleft aligncenter ' +
  // 'alignright alignjustify | bullist numlist outdent indent | ' +
  // 'removeformat | help',
  // menubar: 'file edit view insert format tools table tc help',
  toolbar:
    "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link codesample | a11ycheck ltr rtl | showcomments addcomment",
  autosave_ask_before_unload: true,
  content_style:
    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
  video_template_callback: function (data) {
    return (
      '<video width="' +
      data.width +
      '" height="' +
      data.height +
      '"' +
      (data.poster ? ' poster="' + data.poster + '"' : "") +
      ' controls="controls">\n' +
      '<source src="' +
      data.source +
      '"' +
      (data.sourcemime ? ' type="' + data.sourcemime + '"' : "") +
      " />\n" +
      (data.altsource
        ? '<source src="' +
        data.altsource +
        '"' +
        (data.altsourcemime ? ' type="' + data.altsourcemime + '"' : "") +
        " />\n"
        : "") +
      "</video>"
    );
  },
};

export const LOGO_SETUP_ITEMS = [
  {
    id: "website-logo",
    name: "網站 Logo",
    filename: "website-logo",
    extension: ".ico",
    limitInfo: "192x192",
  },
  {
    id: "desktop-logo",
    name: "桌機版Logo",
    filename: "desktop-logo",
    extension: ".png",
    limitInfo: "350x192(長 x 高)",
  },
  {
    id: "mobile-logo",
    name: "手機版Logo",
    filename: "mobile-logo",
    extension: ".png",
    limitInfo: "192x192",
  },
  {
    id: "pwa-logo",
    name: "PWA Logo",
    filename: "pwa-logo",
    extension: ".png",
    limitInfo: "192x192",
  },
];

function getMetaTitle() {
  if (IS_GW) {
    return "GW123 Back Office";
  } else if (IS_AW_KH) {
    return "AW123 KH Back Office";
  } else if (IS_PECH) {
    return "PECH Back Office";
  } else if (IS_AW_MM) {
    return "AW123 MM Back Office";
  } else if (IS_AW_VN) {
    return "AW123 VN Back Office";
  } else if (IS_AW_BD) {
    return "AW123 BD Back Office";
  } else if (IS_AW_LA) {
    return "AW123 Laos Back Office";
  } else if (IS_AW_KE) {
    return "AW123 Kenya Back Office";
  } else if (IS_AW_NL) {
    return "AW123 Nederland Back Office";
  } else if (IS_BITSBET) {
    return "BITSBET Back Office";
  }

  return "GW123 Back Office";
}

export const META_TITLE = getMetaTitle();

function getMetaDescription() {
  if (IS_GW) {
    return "The Best Online Game Platform in Malaysia";
  } else if (IS_KH) {
    return "The Best Online Game Platform in Combodia";
  } else if (IS_AW_MM) {
    return "The Best Online Game Platform in Myanmar";
  } else if (IS_AW_VN) {
    return "The Best Online Game Platform in Vietnam";
  } else if (IS_AW_BD) {
    return "The Best Online Game Platform in Bangladesh";
  } else if (IS_AW_LA) {
    return "The Best Online Game Platform in Laos";
  } else if (IS_AW_KE || IS_BITSBET) {
    return "The Best Online Game Platform in Kenya";
  } else if (IS_AW_NL) {
    return "The Best Online Game Platform in Nederland";
  }

  return "The Best Online Game Platform in Malaysia";
}

export const META_DESCRIPTION = getMetaDescription();

export const ACTIVE_TINYMCE_PLUGINS = [
  "chiffer",
  "advlist",
  "autolink",
  "lists",
  "link",
  "image",
  "charmap",
  "preview",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "help",
  "wordcount",
];

export const ACTIVE_TINYMCE_TOOLBARS =
  "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link codesample | a11ycheck ltr rtl | showcomments addcomment";

export const SYSTEM_METAS_MAP = {
  allowUserGrading: "允許用戶分級",
  displayProfilePic: "顯示用戶大頭照",
  currencyDecimalPlace: "貨幣顯示小數點位數",
  depositAmountOptions: "充值快選選項",
  displayDepositAmountOptions: "顯示充值快選",
  withdrawAmountOptions: "提款快選選項",
  displayWithdrawAmountOptions: "顯示提款快選",
  isLiveChatOn: "啟用即時客服",
  isTelegramOn: "啟用Telegram",
  isWhatsappOn: "啟用WhatsApp",

  telegramAccount: "Telegram帳號",
  whatsappAccount: "WhatsApp帳號/連結 (輸入http開頭連結即能直接跳轉)",

  installPromptNews: "輕安裝說明News",

  menuItemKeys: "目錄管理 (排除首頁)",

  isForgotPasswordEnabled: "啟用忘記密碼",

  registerFlowType: "註冊流程",
  forgotPasswordFlowType: "忘記密碼流程",

  shareDialogLayout: "分享視窗版型",

  defaultLanguage: "預設語言",

  depositIsSemiAuto: "開啟半自動",

  //Grouped local-generated items
  depositSettings: "充值設定",
  withdrawSettings: "提款設定",
  customerService: "客服管理",

  metaPixelCodes: "FB Pixel碼設定",
  metaPixelCodeInfos: "FB Pixel碼設定",

  depositQRCodeOptions: "充值QRCode設定",

  depositStepperOptions: "充值累加減設定",

  withdrawStepperOptions: "提款累加減設定",

  adPageAnnouncement: "推廣頁公告"


};

export const ADMIN_SYSTEM_METAS_MAP = {
  admin_menuBackgroundColor: '目錄底色',
  admin_menuTextColor: '目錄文字顏色',
  admin_primaryButtonColor: '主要按鈕底色',
  admin_primaryButtonTextColor: '主要按鈕文字顏色',
  admin_loginBackgroundImagePath: '登入頁底圖',
};

export const ADMIN_SYSTEM_METAS_DEFAULT_VALUE = {
  admin_menuBackgroundColor: 'rgb(28, 37, 54)',
  admin_menuTextColor: 'rgb(181, 188, 196)',
  admin_primaryButtonColor: 'rgb(158, 119, 237)',
  admin_primaryButtonTextColor: 'rgb(255, 255, 255)',
  admin_loginBackgroundImagePath: '/images/shared/login_background.png',
};


export const MENU_NAME_MAP = {
  'deposit': '充值',
  'withdrawal': '提款',
  'transfer': "轉帳",
  'bankaccount': "銀行戶口",
  'transaction-log': "交易紀錄",
  'bet-log': "遊戲紀錄",
  'affiliate-log': "佣金紀錄",
  'profile': '會員中心'
}

export const MENU_LIST = ['deposit', 'withdrawal', 'transfer', 'bankaccount', 'transaction-log', 'bet-log', 'affiliate-log', 'profile'];


const getLongLogoPath = () => {
  if (IS_PECH) {
    return "/images/logo/logo-long-pech.png";
  } else if (IS_GW) {
    return "/images/logo/logo-long-gw.png";
  }
  return "/images/logo/logo-long-aw.png";
}

export const LONG_LOGO_PATH = getLongLogoPath();