import React, { useState } from 'react';
import styled from 'styled-components';
import { LANGS, LANG_SELECTOR_ITEMS } from '@constants/config';
import { useSelectLang } from '@hooks/useLang';

const COPY_MARKET_OPTIONS = [...LANG_SELECTOR_ITEMS].filter(l => l.value !== LANGS.En && l.value !== LANGS.Cn)
const QuicklyCopySettings = (props) => {
    const { onSubmit, } = props
    const { lang } = useSelectLang()
    const [canCopy, setCanCopy] = useState(false)

    const handleChange = () => {
        setCanCopy(!canCopy);
    };
    const handleSubmit = (toLanguage) => () => {
        if(!canCopy) return;
        
        onSubmit(toLanguage);
    };

    if(!onSubmit || lang !== LANGS.En) return null
    return (
        <QuicklyCopySettingsWrap canCopy={canCopy}>
            <label className="quick-copy-label">
                <input type="checkbox" checked={canCopy} onChange={handleChange}/>
                {`開啟快速複製(勾選後，才可使用複製按鈕) ->`}
            </label>
            將此設定快速複製到：
            {COPY_MARKET_OPTIONS.map(q => <button key={q.value} disabled={!canCopy} onClick={handleSubmit(q.value)}>{q.label}</button>)}
            {/* <button onClick={handleSubmit('test')} disabled={!canCopy}>tt</button> */}
        </QuicklyCopySettingsWrap>
    );
};

export default QuicklyCopySettings;

const QuicklyCopySettingsWrap = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
    color: ${({ canCopy }) => canCopy ? 'black' : 'lightgray'};
    
    button:disabled,
    button[disabled] {
        color: gray;
        background: lightgray;
    }

    .quick-copy-label {
        color: black;
    }

    
`;
