/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useSelectLang } from "@hooks/useLang";
import { copyallNews, getNews } from "@api/cms";
import { IMAGE_DOMAIN } from "@constants/api";
import NewsModal from "@components/news/NewsModal";
import QuicklyCopySettings from "@components/shared/QuicklyCopySettings";
import { Card, Button, Icon } from "@mui/material";

export default function NewsPage() {
  const { lang } = useSelectLang();
  const [news, setNews] = useState(null);

  const [selectedNews, setSelectedNews] = useState(null);

  const getData = async () => {
    const res = await getNews(lang);
    setNews(
      res
        ? res.map((r) => {
          return { ...r, imgUrl: `${IMAGE_DOMAIN}/${r.imagePath}` };
        })
        : []
    );

  };

  useEffect(() => {
    getData();
  }, [lang]);

  const addNews = () => {
    setSelectedNews({
      isAddNew: true,
      id: -1,
      title: "",
      image: null,
      imgUrl: "",
      language: lang,
      sort: 1,
    });
  };

  const handleCloseModal = async (reGetData) => {
    if (reGetData) await getData();
    setSelectedNews(null);
  };

  const handleQuicklyCopySubmit = async (toLanguage) => {
    if (!lang || !toLanguage) {
      toast.error("請重新整理網頁後再嘗試");
    } else {
      const res = await copyallNews({ fromLanguage: lang, toLanguage });
      if (res && typeof res === "object" && news)
        if (res?.length === news?.length)
          toast.success(`複製到 ${toLanguage} 成功`);
    }
  };

  return (
    <Card
      elevation={3}
      className="w-full overflow-auto"
      style={{ padding: "24px" }}
    >
      <NewsPageWrap>
        <QuicklyCopySettings onSubmit={handleQuicklyCopySubmit} />
        News - 250 x 250（長x寬） 。<strong>圖片檔名不可重複</strong>
        <br />
        {`教學： 點擊“新增News“按鈕，或者圖片 來打開新增/修改視窗。 -> 右上角"X"按鈕用於關閉。 視窗內最下方的儲存/刪除按鈕提供該名稱所對應的功能。`}

        <Button
          variant="contained"
          color="secondary"
          onClick={addNews}
          mt={10}
        >
          <Icon>add</Icon>&nbsp;
          新增News
        </Button>

        <div className="news-wrap">
          {news &&
            news.map((n) => (
              <div key={n.id} onClick={() => setSelectedNews(n)}>
                <img src={n.imgUrl} alt={`${n.title}-${n.id}`} />
              </div>
            ))}
        </div>
        <NewsModal
          show={selectedNews !== null}
          item={selectedNews}
          onClose={handleCloseModal}
        />
      </NewsPageWrap>
    </Card>
  );
}

const NewsPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .news-wrap {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;

    > div {
      margin: auto 0px;
      width: 200px;
      cursor: pointer;
      > img {
        width: 100%;
        height: auto;
      }
    }
  }

  > button {
    margin: 10px 0px;
    width: 200px;
  }
`;
