import { createGlobalStyle } from 'styled-components';


const GlobalStyles = createGlobalStyle`
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    body, #root {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }

    * {
      font-family: 'Microsoft JhengHei', '微軟正黑體', 'Microsoft YaHei', 'Segoe UI', Verdana, Arial, 'SF Pro', 'PingFang SC', 'Inter', 'Noto Sans', sans-serif;
      // Firefox hide scrollbar
      scrollbar-width: none;
    }

    @font-face {
      font-family: 'MonsalGothic-ExtraBoldItalic';
      src: url(/fonts/monsal-gothic-bold-italic.otf) format('woff');
    }

   ::-webkit-scrollbar {
     // hide all scrollbar
      display: none;
    }
    
    html,
    body {
        height: 100%;
        margin: 0;
        line-height: 1.5;
        color: #121212;
    }
    body {
      transition: all 0.50s linear;
      margin: 0px !important;
      overflow: hidden;
    }

  
    #bo-content-view {
      flex: 1;
      padding: 2rem 4rem;
      width: 100%;
    }
    
    #bo-content-view.loading {
        opacity: 0.25;
        transition: opacity 200ms;
        transition-delay: 200ms;
    }

    button {
      font-size: 1rem;
      font-family: inherit;
      border: none;
      border-radius: 8px;
      padding: 0.5rem 0.75rem;
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
      background-color: white;
      line-height: 1.5;
      margin: 0;
      cursor: pointer;
      color: #3992ff;
      font-weight: 500;
    }
    button:hover {
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
    }

    button:active {
      box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
      transform: translateY(1px);
    }

`;
export default GlobalStyles;
