/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getUsers } from "@api/cms";
import UserModal from "@components/users/UserModal";
import { Card, Button, Icon } from "@mui/material";

export default function UsersPage() {
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  // const [setSelectedUserForPermissions] = useState(null);

  const getData = async () => {
    const res = await getUsers();

    setUsers(
      res
        ? res.map((r) => {
          return { ...r };
        })
        : []
    );
  };
  useEffect(() => {
    getData();
  }, []);

  const addUser = () => {
    setSelectedUser({
      isAddNew: true,
      id: -1,
      email: "",
      password: "",
      phone: "",
      remark: "",
    });
  };

  const handleCloseModal = async (reGetData) => {
    if (reGetData) await getData();
    setSelectedUser(null);
  };

  return (
    <Card
      elevation={3}
      className="w-full overflow-auto"
      style={{ padding: "24px" }}
    >
      <NewsPageWrap>
        <Button
          sx={{ mt: 8 }}
          variant="contained"
          color="secondary"
          onClick={addUser}
          mt={10}
        >
          <Icon>add</Icon>&nbsp;
          新增User
        </Button>
        <div className="users-wrap">
          {users && (
            <table>
              <thead>
                <tr>
                  <th>帳號</th>
                  <th>手機號碼</th>
                  <th>備註</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {users.map((n) => (
                  <tr key={n.id}>
                    <th>{n.email}</th>
                    <th>{n.phone}</th>
                    <th>{n.remark}</th>
                    <th>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectedUser(n)}
                        mt={10}
                      >
                        修改
                      </Button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <UserModal
          show={selectedUser !== null}
          item={selectedUser}
          onClose={handleCloseModal}
        />
      </NewsPageWrap>
    </Card>
  );
}

const NewsPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .users-wrap {
    padding-top: 40px;
    max-width: 600px;

    table,
    td,
    th {
      border: 1px solid black;
      font-weight: normal;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      > thead {
        tr:first-child {
          th {
            font-weight: bold;
            text-aling: start;
          }
        }
      }
      tr {
        > th {
          text-align: left;
          padding-left: 10px;
        }
        > th:last-child {
          text-align: center;
        }
      }
    }
    td,
    th {
      padding: 10px 5px;
    }
  }

  > button {
    margin: 10px 0px;
    width: 20%;
  }
`;
