export const SIDEBAR_WIDTH_EXPAND = 200;
export const SIDEBAR_WIDTH_COLLAPSE = 80;
export const ROOT_HEADER_HEIGHT = 40;
export const MENU_WITDH_DEFAULT = 160;
export const Z_INDEXS = {
    ANNOUNCEMENT_GRID: 10,
    MODAL: 800,
    SELEC_LANG_POPUP: 100
}

export const INTERVAL_TIMES = {
    GET_USERS: 60 * 1000
}