import React, { useRef } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faImage,
  faTrashCan,
  faCheck,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
// import { toast } from 'react-toastify';

function ImageUploadZone(props) {
  const {
    data,
    setTempImage,
    onDelete,
    onCheck,
    onEdit,
    outerClassname,
    hideTrashCan,
  } = props;
  const { id, imgUrl, tempImageUrl } = data;
  const imageSelector = useRef(null);

  const onDrop = async (acceptedFiles) => {
    if (JSON.stringify(acceptedFiles) === "[]") {
      imageSelector?.current?.click?.();
      return;
    }

    let preview;
    acceptedFiles.forEach((file) => {
      preview = URL.createObjectURL(file);
    });
    setTempImage(id, acceptedFiles, preview);

    // onDropRejected(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/svg+xml": [], //todo ???
      "image/x-icon": [],
    },
    maxFiles: 1,
    //   useFsAccessApi: false,
  });
  const handleCheck = (e) => {
    e.stopPropagation();
    onCheck(id);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(id);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(id);
  };

  return (
    <div {...getRootProps()} className={outerClassname}>
      <input {...getInputProps()} />
      <ImageWrap>
        <ThumbsDiv ref={imageSelector}>
          <img
            className="thumbsImg"
            src={tempImageUrl || imgUrl}
            alt={`banner-${id}`}
          />

          <div
            className={classNames("cover", {
              empytImg: !imgUrl && !tempImageUrl,
            })}
          >
            <div className="icons">
              {imgUrl || tempImageUrl ? (
                <>
                  <span>
                    <FontAwesomeIcon icon={faImage} color="gray" />
                  </span>
                  {onEdit && (
                    <span onClick={handleEdit}>
                      <FontAwesomeIcon icon={faEdit} color="gray" />
                    </span>
                  )}
                  {!hideTrashCan && (
                    <span onClick={handleDelete}>
                      <FontAwesomeIcon icon={faTrashCan} color="red" />
                    </span>
                  )}
                  {tempImageUrl && onCheck && (
                    <span className="img-check-icon" onClick={handleCheck}>
                      <FontAwesomeIcon icon={faCheck} color="green" />
                    </span>
                  )}

                  {imgUrl && !tempImageUrl && (
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      className="open-url-icon"
                      color="white"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(imgUrl, "_blank");
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faImage} color="gray" />
                  <p>點擊此區域來選擇上傳圖片</p>
                </>
              )}
            </div>
          </div>
        </ThumbsDiv>
      </ImageWrap>
    </div>
  );
}

export default ImageUploadZone;

export function UploadImageWithTitle(props) {
  const { onCreate, title, width, classnames, data, ...rest } = props;
  const { id, imgUrl, tempImageUrl } = data;

  const handleCreate = (e) => {
    e.stopPropagation();
    onCreate(id);
  };

  return (
    <BannerWrapper width={width} className={classnames}>
      <p className="banner-name">{title}</p>
      <div className="upload-zone-wrap">
        {onCreate && !imgUrl && !tempImageUrl ? (
          <ImageWrap>
            <ThumbsDiv>
              <div
                className={classNames("cover", {
                  empytImg: !imgUrl && !tempImageUrl,
                })}
              >
                <div className="icons" onClick={handleCreate}>
                  <FontAwesomeIcon icon={faImage} color="gray" />
                  <p>點擊此區域來新增項目</p>
                </div>
              </div>
            </ThumbsDiv>
          </ImageWrap>
        ) : (
          <ImageUploadZone {...{ ...rest, data }} />
        )}
      </div>
    </BannerWrapper>
  );
}

const ImageWrap = styled.div`
  width: 100%;
  height: 151px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  vertical-align: top;

  .cover {
    display: none;
    position: absolute;
    padding: 15px;
    text-align: left;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    top: 0px;

    .icons {
      > span {
        cursor: pointer;
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        background: rgb(255, 255, 255);
        margin-right: 10px;
        color: rgb(254, 112, 94);
        font-size: 18px;
        text-align: center;
        padding-top: 2px;
      }

      .img-check-icon {
        position: absolute;
        right: 0px;
      }
    }

    .open-url-icon {
      position: absolute;
      right: 15px;
      bottom: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  &:hover {
    .cover {
      display: block;
    }
  }

  .empytImg {
    display: block;
    background: lightgray;
    .icons {
      height: 100%;
      width: 100%;
      border: 1px dashed white;
      border-width: medium;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > svg {
        font-size: 40px;
        color: white;
        cursor: pointer;
      }
      > p {
        margin: 0px;
        margin-top: 10px;
      }
    }
  }
`;
const ThumbsDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  .thumbsImg {
    width: 100%;
    object-fit: ${(props) => (props.imageFit ? props.imageFit : "cover")};
    height: 100% !important;
    cursor: pointer;
  }
`;

const BannerWrapper = styled.div`
  width: ${(props) => (props.width ? props.width : "270px")};
  display: inline-block;
  margin: 30px 10px 30px 0;
  vertical-align: top;
  .banner-name {
    font-size: 15px;
    line-height: 1.13;
    text-align: center;
    color: #223754;
    margin-bottom: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .upload-zone-wrap {
    height: ${(props) => (props.showLink ? "228px" : "auto")};
    border-radius: 10px;
    box-shadow: 0 2px 7px 0 rgba(119, 120, 128, 0.31);
    background-color: #ffffff;
    cursor: pointer;
  }
`;
