/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import { useSelectLang } from "@hooks/useLang";
import {
  copyDPolicy,
  copyWPolicy,
  getDPolicy,
  getWPolicy,
  postDPolicy,
  postWPolicy,
  putDPolicy,
  putWPolicy,
} from "@api/cms";
import { HTML_EDITOR_INIT_CONFIG, TINY_EDITOR_KEY } from "@constants/config";
import { toast } from "react-toastify";
import { APIS } from "@constants/api";
import QuicklyCopySettings from "@components/shared/QuicklyCopySettings";
import {
  ACTIVE_TINYMCE_PLUGINS,
  ACTIVE_TINYMCE_TOOLBARS,
} from "@constants/config";
import { Card, Button, Icon } from "@mui/material";

export default function DWPolicyPage() {
  const { lang } = useSelectLang();
  const [depositPolicy, setDepositPolicy] = useState(null);
  const [withdrawalPolicy, setWithdrawalPolicy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const depositEditorRef = useRef(null);
  const withdrawalEditorRef = useRef(null);

  const getDData = async () => {
    const dRes = await getDPolicy(lang);
    const firstD = dRes?.[0];
    if (firstD) setDepositPolicy(firstD);
    else setDepositPolicy(null);

    if (depositEditorRef.current)
      depositEditorRef.current.setContent(firstD?.content || "");
  };
  const getWData = async () => {
    const wRes = await getWPolicy(lang);
    const firstW = wRes?.[0];
    if (firstW) setWithdrawalPolicy(firstW);
    else setWithdrawalPolicy(null);

    if (withdrawalEditorRef.current)
      withdrawalEditorRef.current.setContent(firstW?.content || "");
  };
  useEffect(() => {
    getDData();
    getWData();
  }, [lang]);

  const handleDSubmit = async () => {
    if (!depositEditorRef.current) {
      toast.error("抓不到Tiny Editor，請重新整理並在嘗試一次。");
      return;
    }

    setIsLoading(true);
    const request = {
      id: depositPolicy ? depositPolicy.id : 0,
      content: depositEditorRef.current.getContent(),
      language: depositPolicy ? depositPolicy.language : lang,
    };

    let res;
    if (depositPolicy === null) res = await postDPolicy(request);
    else res = await putDPolicy(request);

    if (res) {
      await getDData();
      toast.success("修改成功");
    } else {
      toast.error("修改失敗");
    }
    setIsLoading(false);
  };
  const handleWSubmit = async () => {
    if (!withdrawalEditorRef.current) {
      toast.error("抓不到Tiny Editor，請重新整理並在嘗試一次。");
      return;
    }
    setIsLoading(true);
    const request = {
      id: withdrawalPolicy ? withdrawalPolicy.id : 0,
      content: withdrawalEditorRef.current.getContent(),
      language: withdrawalPolicy ? withdrawalPolicy.language : lang,
    };

    let res;
    if (withdrawalPolicy === null) res = await postWPolicy(request);
    else res = await putWPolicy(request);

    if (res) {
      await getWData();
      toast.success("修改成功");
    } else {
      toast.error("修改失敗");
    }
    setIsLoading(false);
  };

  const handleQuicklyCopyDSubmit = async (toLanguage) => {
    if (!depositPolicy?.language || !toLanguage) {
      toast.error("請重新整理網頁後再嘗試");
    } else {
      const res = await copyDPolicy({ id: depositPolicy.id, toLanguage });
      if (res && res.id) toast.success(`複製到 ${toLanguage} 成功`);
    }
  };
  const handleQuicklyCopyWSubmit = async (toLanguage) => {
    if (!withdrawalPolicy?.language || !toLanguage) {
      toast.error("請重新整理網頁後再嘗試");
    } else {
      const res = await copyWPolicy({ id: withdrawalPolicy.id, toLanguage });
      if (res && res.id) toast.success(`複製到 ${toLanguage} 成功`);
    }
  };

  return (
    <Card
      elevation={3}
      className="w-full overflow-auto"
      style={{ padding: "24px" }}
    >
      <DWPolicyPageWrap>
        <div className="dwpolicy-section">
          <div>
            <div>
              <p>存款 Policy</p>

              <Button
                variant="contained"
                color="primary"
                onClick={handleDSubmit}
                disabled={isLoading}
              >
                <Icon>save</Icon>&nbsp;
                儲存
              </Button>


            </div>
            <QuicklyCopySettings onSubmit={handleQuicklyCopyDSubmit} />
          </div>
          <Editor
            apiKey={TINY_EDITOR_KEY}
            onInit={(evt, editor) => (depositEditorRef.current = editor)}
            initialValue={depositPolicy ? depositPolicy.content : ""}
            init={{
              ...HTML_EDITOR_INIT_CONFIG,
              images_upload_url: APIS.HTML_EDITOR.DEPOSIT_POLICY,
              plugins: [...ACTIVE_TINYMCE_PLUGINS, "video-upload"],
              toolbar: ACTIVE_TINYMCE_TOOLBARS + "| video-upload",
              external_plugins: { "video-upload": "/tinymce-plugin/plugin.js" },
              media_live_embeds: true,
              file_picker_callback(callback, value, meta) {
                console.log(" file picker  ");
                if (meta.filetype === "media") {
                  callback("");
                }
              },
            }}
          />
        </div>

        <div className="dwpolicy-section-divide" />

        <div className="dwpolicy-section">
          <div>
            <div>
              <p>提款 Policy</p>

              <Button
                variant="contained"
                color="primary"
                onClick={handleWSubmit}
                disabled={isLoading}
              >
                <Icon>save</Icon>&nbsp;
                儲存
              </Button>
            </div>
            <QuicklyCopySettings onSubmit={handleQuicklyCopyWSubmit} />
          </div>
          <Editor
            apiKey={TINY_EDITOR_KEY}
            onInit={(evt, editor) => (withdrawalEditorRef.current = editor)}
            initialValue={withdrawalPolicy ? withdrawalPolicy.content : ""}
            init={{
              ...HTML_EDITOR_INIT_CONFIG,
              images_upload_url: APIS.HTML_EDITOR.WITHDRAWAL_POLICY,
              plugins: [...ACTIVE_TINYMCE_PLUGINS, "video-upload"],
              toolbar: ACTIVE_TINYMCE_TOOLBARS + "| video-upload",
              external_plugins: { "video-upload": "/tinymce-plugin/plugin.js" },
              media_live_embeds: true,
              file_picker_callback(callback, value, meta) {
                console.log(" file picker  ");
                if (meta.filetype === "media") {
                  callback("");
                }
              },
            }}
          />
        </div>
      </DWPolicyPageWrap>
    </Card>
  );
}

const DWPolicyPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;

  .dwpolicy-section-divide {
    height: 2px;
    background: gray;
    width: 100%;
    margin: 40px 0px 20px 0px;
    border-bottom: 2px dotted lightcyan;
  }

  .dwpolicy-section {
    > div:first-child {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > div:first-child {
        display: flex;
        align-items: center;
        > button {
          margin-left: 50px;
          height: fit-content;
        }
      }
    }
  }
`;
