import React, { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import ReactSelect from "react-select";
import Sidebar from "@components/sidebar/Sidebar";
import {
  LANG_SELECTOR_ITEMS,
  MENU_ITEMS
} from "@constants/config";

import { useSelectLang } from "@hooks/useLang";
import { getEmailFromLS, getTokenFromLS, setEmailToLS, setPermissionsToLS, setTokenToLS } from "@utils/ls";
import { getUsers, logout, getPermissions } from "@api/cms";
import { INTERVAL_TIMES, Z_INDEXS } from "@constants/numbers";
import { SWITCH_ROUTES } from "@constants/routes";
import jwt from "jwt-decode";
import { Hidden, MenuItem, Icon, Avatar } from '@mui/material'
import MatxMenu from '@components/shared/MatxMenu'
export default function Root() {
  const [checkStatus, setCheckStatus] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedMenuItem = MENU_ITEMS.filter(
    (m) => m.path === location.pathname
  )[0];
  useEffect(() => {
    let interval = null;
    if (getTokenFromLS()) {
      const checkUserStatus = async () => {
        try {
          const res = await getUsers();
          if (res !== undefined && !checkStatus) setCheckStatus(true);
        } catch (e) { }
      };

      checkUserStatus();
      interval = setInterval(checkUserStatus, INTERVAL_TIMES.GET_USERS);
    } else {
      navigate(SWITCH_ROUTES.LOGIN);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);


  const fetchPermissions = async () => {
    const token = getTokenFromLS();
    if (token) {
      const user = jwt(token);
      const user_id =
        user[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
        ];

      const res = await getPermissions({ id: user_id });
      if (res) setPermissionsToLS(res);
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  if (!checkStatus) return null;
  return (
    <RootWrap>
      <Sidebar />
      <Detail>
        <RootHeader />
        <OutletWrap>
          <h2>{selectedMenuItem?.menuName}</h2>
          {/* context={{t}} */}
          <Outlet />
        </OutletWrap>
      </Detail>
    </RootWrap>
  );
}

function RootHeader() {
  // const location = useLocation();
  const { lang, changeLang } = useSelectLang();
  const [email, setEmail] = useState(null);

  // const selectedMenuItem = MENU_ITEMS.filter(
  //   (m) => m.path === location.pathname
  // )[0];
  const selectedItem = LANG_SELECTOR_ITEMS.filter((m) => m.value === lang)[0];

  const handleLogout = () => {
    logout();
    setTokenToLS(null);
    setEmailToLS(null);
    window.location.href = SWITCH_ROUTES.LOGIN;
  };

  useEffect(() => {
    const emailFromLS = getEmailFromLS();
    if (emailFromLS) setEmail(emailFromLS)

  }, [])


  return (
    <RootHeaderWrap>
      {/* {selectedMenuItem?.menuName} */}
      <div />
      <div>
        {/* <Button
          variant="contained"
          color="success"
          onClick={handleLogout}
        >
          登出
        </Button> */}
        <MatxMenu
          account={email}
          menuButton={
            <AvatarWrap>
              <Hidden mdDown>
                <span style={{ marginRight: '8px' }}>
                  Hi <strong>{email}</strong>
                </span>
              </Hidden>
              <Avatar
                style={{ backgroundColor: 'rgb(158, 119, 237)', color: '#fff', width: '30px', height: '30px' }}
                className="cursor-pointer"

              > {email && email.charAt(0).toUpperCase()} </Avatar>
            </AvatarWrap>
          }
        >

          <MenuItem
            onClick={handleLogout}
            style={{
              padding: '10px 10px',
              marginTop: '10px',
              gap: '10px'
            }}
          // className={classes.menuItem}
          >
            <Icon> power_settings_new </Icon>
            <span className="pl-4"> 登出 </span>
          </MenuItem>
        </MatxMenu>
        <ReactSelect
          className="lang-selector"
          styles={CustomSelectStyles}
          value={selectedItem}
          options={LANG_SELECTOR_ITEMS}
          onChange={(e) => {
            changeLang(e.value);
          }}
        />
      </div>
    </RootHeaderWrap>
  );
}

export function useBOContext() {
  return useOutletContext();
}

const RootWrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

`;
const Detail = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;

const RootHeaderWrap = styled.div`
  height: 40px;
  width: 100%;
  // background: lightblue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  border-bottom: 1px solid #ccc; // Add a border to the bottom

  > div {
    display: flex;
    gap: 20px;
    > button {
      font-size: revert;
      line-height: initial;
    }
  }

  .lang-selector {
    z-index: ${Z_INDEXS.SELEC_LANG_POPUP};
  }
`;

const OutletWrap = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
`;

const AvatarWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;

  @media only screen and (min-width: 900px) {
    &:hover {
      background-color: rgb(0, 0, 0, 0.1);
      border-radius: 15px;
  
    }
  }
  
`;


const CustomSelectStyles = {
  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
    width: 160,
    // marginTop: 2,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 30,
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30,
  }),
};
