import styled from "styled-components";
import { FormControlLabel } from "@mui/material";

export const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: 15px;

    .MuiFormControlLabel-label {
      font-size: 16px;
      color: #333;
    }

    .MuiSwitch-track {
      background-color: #ccc;
    }
  }
`;
