/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { getSystemMetas, putSystemMetas } from "@api/cms";

import { Grid, FormGroup, Switch, CircularProgress, Card } from "@mui/material";

import { StyledGrid } from "@/styled_components/styled_grid";
import { StyledButton } from "@/styled_components/styled_button";
import { StyledFormControlLabel } from "@/styled_components/styled_form_control_label";
import { LoaderContainer } from "@/styled_components/common";

import { SYSTEM_METAS_MAP, LANG_SELECTOR_ITEMS } from "@constants/config";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Box,
  Tab,
  Tabs,
  Icon
} from "@mui/material";

import { AmountOptionForm } from "@components/shared/AmountOptionForm";
import { NewsOptionForm } from "@components/shared/NewsOptionForm";
import { BankQRCodeOptionForm } from "@components/shared/BankQRCodeOptionForm";

import MenuTransferList from "@components/shared/MenuTransferList";
import { TextListForm } from "@components/shared/TextListForm";
import { PixelListForm } from "@components/shared/PixelListForm";

import { StepperOptionForm } from "@components/shared/StepperOptionForm";

export default function SystemMetasPage() {

  const csOrderedKeys = [
    "isLiveChatOn",
    "isTelegramOn",
    "telegramAccount",
    "isWhatsappOn",
    "whatsappAccount",
  ];

  const [metas, setMetas] = useState(null);

  const infos = useRef(null);
  const groupedInfos = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const getData = async () => {
    const res = await getSystemMetas();

    if (res) {
      const obj = {};
      const infoObj = {};
      const groupedObj = { depositSettings: {}, withdrawSettings: {}, customerService: {} }
      for (const item of res) {
        if (item.id.startsWith('admin_')) continue;
        if (item.dataType === "boolean") {
          obj[item.id] = item.value === "1";
        } else if (item.dataType === "object" || item.dataType === "array") {
          obj[item.id] = JSON.parse(item.value);
        } else {
          obj[item.id] = item.value;
        }
        infoObj[item.id] = item;
        if (item.id === 'displayDepositAmountOptions' || item.id === 'depositAmountOptions' || item.id === 'depositIsSemiAuto' || item.id === 'depositQRCodeOptions' || item.id === 'depositStepperOptions') {
          groupedObj.depositSettings[item.id] = item;
        } else if (item.id === 'displayWithdrawAmountOptions' || item.id === 'withdrawAmountOptions' || item.id === 'withdrawStepperOptions') {
          groupedObj.withdrawSettings[item.id] = item;
        } else if (csOrderedKeys.includes(item.id)) {
          groupedObj.customerService[item.id] = item;
        }
      }
      infos.current = infoObj;
      groupedInfos.current = groupedObj;
      setMetas(obj);
    }
  };
  useEffect(() => {
    getData();
  }, []);


  const handleSubmit = async () => {
    setIsLoading(true); // Set loading to true when submitting

    // Simplify and make use of the metas state.
    let res = await putSystemMetas({ metas });

    if (res) {
      toast.success("修改成功");
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    setMetas((prevMetas) => ({
      ...prevMetas,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  const handleMetasChange = (key, newOption) => {
    console.log(' key -> ', key)
    console.log(' newOption -> ', newOption)

    setMetas((prevMetas) => ({
      ...prevMetas,
      [key]: newOption,
    }));
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const handleIndexChange = (event, newValue) => {
    setCurrentIndex(newValue);
  };



  if (metas === null || metas.length === 0)
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );

  const orderedKeys = [
    // "allowUserGrading",
    "currencyDecimalPlace",
    "displayProfilePic",
    "depositSettings",
    // "displayDepositAmountOptions",
    // "depositAmountOptions",
    "withdrawSettings",
    // "displayWithdrawAmountOptions",
    // "withdrawAmountOptions",
    "customerService",
    // "isLiveChatOn",
    // "isTelegramOn",
    // "telegramAccount",
    "menuItemKeys",
    "installPromptNews",
    "isForgotPasswordEnabled",
    "registerFlowType",
    "forgotPasswordFlowType",
    "shareDialogLayout",
    "defaultLanguage",
    "metaPixelCodes",
    "metaPixelCodeInfos",
    "adPageAnnouncement"
    // ... all other keys in the desired order
  ];



  const renderItems = (key, settingItem) => {
    return (
      <React.Fragment key={key}>
        {settingItem.formType === "switch" && (
          <Grid item xs={12}>
            <FormGroup key={key}>
              <StyledFormControlLabel
                control={
                  <Switch
                    name={key}
                    onChange={handleChange}
                    checked={metas[key]}
                  />
                }
                label={SYSTEM_METAS_MAP[key]}
              />
            </FormGroup>
          </Grid>
        )}
        {settingItem.formType === "radio" &&
          key === "currencyDecimalPlace" && (
            <Grid item xs={12}>
              <FormGroup key={key}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {SYSTEM_METAS_MAP[key]}
                  </FormLabel>
                  <RadioGroup
                    aria-label={key}
                    name={key}
                    value={metas[key]}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="none"
                      control={<Radio />}
                      label="無"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="1位"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="2位"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label="3位"
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Grid>
          )}
        {settingItem.formType === "radio" &&
          key === "shareDialogLayout" && (
            <Grid item xs={12}>
              <FormGroup key={key}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {SYSTEM_METAS_MAP[key]}
                  </FormLabel>
                  <RadioGroup
                    aria-label={key}
                    name={key}
                    value={metas[key]}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="版型ㄧ"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="版型二"
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Grid>
          )}
        {settingItem.formType === "radio" &&
          key === "registerFlowType" && (
            <Grid item xs={12}>
              <FormGroup key={key}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {SYSTEM_METAS_MAP[key]}
                  </FormLabel>
                  <RadioGroup
                    aria-label={key}
                    name={key}
                    value={metas[key]}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="流程ㄧ"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="流程二"
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Grid>
          )}
        {settingItem.formType === "radio" &&
          key === "forgotPasswordFlowType" && (
            <Grid item xs={12}>
              <FormGroup key={key}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {SYSTEM_METAS_MAP[key]}
                  </FormLabel>
                  <RadioGroup
                    aria-label={key}
                    name={key}
                    value={metas[key]}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="流程ㄧ"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="流程二"
                    />
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Grid>
          )}
        {settingItem.formType === "radio" &&
          key === "defaultLanguage" && (
            <Grid item xs={12}>
              <FormGroup key={key}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {SYSTEM_METAS_MAP[key]}
                  </FormLabel>
                  <RadioGroup
                    aria-label={key}
                    name={key}
                    value={metas[key]}
                    onChange={handleChange}
                  >
                    {LANG_SELECTOR_ITEMS.map((item) => <FormControlLabel
                      value={item.value}
                      control={<Radio />}
                      label={`${item.label}${item.code && `- (${item.code})`}`}
                    />)}

                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Grid>
          )}
        {settingItem.formType === "amountOption" && (
          <Grid item xs={12}>
            <FormGroup key={key}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ marginBottom: 20 }}
                  component="legend"
                >
                  {SYSTEM_METAS_MAP[key]}
                </FormLabel>
                <AmountOptionForm
                  key={key}
                  optionKey={key}
                  value={metas[key]}
                  onChange={handleMetasChange}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        )}
        {settingItem.formType === "text" && (
          <Grid item xs={12}>
            <FormGroup key={key}>
              <TextField
                label={SYSTEM_METAS_MAP[key]}
                name={key}
                value={metas[key] || ''}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </FormGroup>
          </Grid>
        )}
        {key === "menuItemKeys" && (
          <Grid item xs={12}>
            <FormGroup>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ marginBottom: 20 }}
                  component="legend"
                >
                  {SYSTEM_METAS_MAP['menuItemKeys']}
                </FormLabel>
                <MenuTransferList optionKey={'menuItemKeys'} list={metas[key] || []} onChange={handleMetasChange} />
              </FormControl>

            </FormGroup>
          </Grid>
        )}
        {settingItem.formType === "newsOption" && (
          <Grid item xs={12}>
            <FormGroup key={key}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ marginBottom: 20 }}
                  component="legend"
                >
                  {SYSTEM_METAS_MAP[key]}
                </FormLabel>
                <NewsOptionForm
                  key={key}
                  optionKey={key}
                  value={metas[key]}
                  onChange={handleMetasChange}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        )}
        {settingItem.formType === "qrCodeOption" && (
          <Grid item xs={12}>
            <FormGroup key={key}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ marginBottom: 20 }}
                  component="legend"
                >
                  {SYSTEM_METAS_MAP[key]}
                </FormLabel>
                <BankQRCodeOptionForm
                  key={key}
                  optionKey={key}
                  value={metas[key]}
                  onChange={handleMetasChange}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        )}
        {settingItem.formType === "stepperOption" && (
          <Grid item xs={12}>
            <FormGroup key={key}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ marginBottom: 20 }}
                  component="legend"
                >
                  {SYSTEM_METAS_MAP[key]}
                </FormLabel>
                <StepperOptionForm
                  key={key}
                  optionKey={key}
                  value={metas[key]}
                  onChange={handleMetasChange}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        )}
        {settingItem.formType === "textList" && (
          <Grid item xs={12}>
            <FormGroup key={key}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ marginBottom: 20 }}
                  component="legend"
                >
                  {SYSTEM_METAS_MAP[key]}
                </FormLabel>
                <TextListForm
                  key={key}
                  optionKey={key}
                  value={metas[key]}
                  onChange={handleMetasChange}
                  itemName={'代碼'}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        )}
        {settingItem.formType === "pixelList" && (
          <Grid item xs={12}>
            <FormGroup key={key}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ marginBottom: 20 }}
                  component="legend"
                >
                  {SYSTEM_METAS_MAP[key]}
                </FormLabel>
                <PixelListForm
                  key={key}
                  optionKey={key}
                  value={metas[key]}
                  onChange={handleMetasChange}
                  itemName={'代碼'}
                />
              </FormControl>
            </FormGroup>
          </Grid>
        )}

      </React.Fragment>
    );
  }

  return (
    <Card
      elevation={3}
      className="w-full overflow-auto"
      style={{ padding: "24px" }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable"
          scrollButtons="auto" value={currentIndex} onChange={handleIndexChange} aria-label="basic tabs example">
          {metas &&
            orderedKeys.map((key, index) => {
              if (!infos.current[key] && !groupedInfos.current[key]) return <></>;
              return <Tab key={key} label={SYSTEM_METAS_MAP[key]} {...a11yProps(index)} />;

            })}
        </Tabs>
      </Box>

      <StyledGrid container spacing={3}>
        {metas &&
          orderedKeys.map((key, index) => {
            if (index !== currentIndex) return <></>;
            if (groupedInfos.current[key]) {
              const groupedItemInfo = groupedInfos.current[key];
              if (key === 'customerService') {
                return csOrderedKeys.map((subkey) => {
                  const item = groupedItemInfo[subkey];
                  return renderItems(subkey, item);
                });
              }

              //exception: 
              let subkeys = Object.keys(groupedItemInfo);

              // if (key === 'depositSettings') {
              //   subkeys = subkeys.reverse();
              // }
              return subkeys.map((subkey) => {
                const item = groupedItemInfo[subkey];
                return renderItems(subkey, item);
              });
            }

            if (!infos.current[key] || index !== currentIndex) return <></>;


            return renderItems(key, infos.current[key]);

          })}



        <Grid item xs={12}>
          <Grid container justifyContent={"end"}>
            <Grid>
              <StyledButton
                sx={{ mt: 8 }}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
                mt={10}
              >
                <Icon>save</Icon>&nbsp;
                儲存
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>

      {/* </StyledContainer> */}
    </Card>
  );
}
