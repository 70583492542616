import React, {
    useState,
    createContext,
    useContext,
    useMemo
} from 'react';
import { getSelectedLang, setSelectedLang } from '@utils/ls';
import { LANGS } from '@constants/config';

const SelectedLangContext = createContext({
    lagn: LANGS.Cn,
    changeLang: () => {},
});


const SelectedLangProvider = ({ children }) => {
    const [lang, setLang] = useState(
        getSelectedLang() || LANGS.Cn
    );
    const value = useMemo(() => {
        const changeLang = (s) => {
            setSelectedLang(s);
            setLang(s);
        };
        return {
            lang,
            changeLang,
        };
    }, [lang]);

    return (
        <SelectedLangContext.Provider value={value}>
            {children}
        </SelectedLangContext.Provider>
    );
};

export const useSelectLang = () => useContext(SelectedLangContext);

export default SelectedLangProvider;
