/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Divider, Switch, FormGroup } from "@mui/material";
import { StyledFormControlLabel } from "@/styled_components/styled_form_control_label";

export function AmountOptionForm({ optionKey, value, onChange }) {
  const [options, setOptions] = useState({
    display: true,
    currency: "",
    pointUnit: "",
    rate: 1,
    amounts: [],
  });

  const notifyChange = (newOptions) => {
    setOptions(newOptions);
    if (typeof onChange === "function") {
      onChange(optionKey, newOptions);
    }
  };

  useEffect(() => {
    if (Object.keys(value).length > 0) setOptions(value);
  }, []);

  const handleDisplayChange = (e) => {
    const target = e.target;
    const name = target.name;

    setOptions((prev) => {
      return { ...prev, [name]: target.checked };
    });
    notifyChange({
      ...options,
      [name]: target.checked,
    });
  };


  const handleTextChange = (e) => {
    const target = e.target;
    const name = target.name;
    setOptions((prev) => {
      return { ...prev, [name]: target.value };
    });
    notifyChange({
      ...options,
      [name]: target.value,
    });
  };

  const handleAddAmountItem = (items) => {
    const newAmounts = [...options.amounts, 0];
    setOptions({ ...options, amounts: newAmounts });

    notifyChange({
      ...options,
      amounts: newAmounts,
    });
  };

  const handleAmountChange = (index, e) => {
    const newAmounts = [...options.amounts];
    newAmounts[index] = e.target.value; // Set the new value at the specific index
    setOptions({ ...options, amounts: newAmounts }); // Update the state with the new amounts array
    notifyChange({
      ...options,
      amounts: newAmounts,
    });
  };

  const handleRemoveAmountItem = (index) => {
    // Create a new array without the item at the specified index
    const newAmounts = options.amounts.filter((_, i) => i !== index);
    // Update the state with the new array
    setOptions({ ...options, amounts: newAmounts });
    notifyChange({
      ...options,
      amounts: newAmounts,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Switch
                  name="display"
                  onChange={handleDisplayChange}
                  checked={options.display}
                />
              }
              label="顯示快選"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            required
            label="主貨幣單位"
            variant="outlined"
            name={`currency`}
            value={options.currency}
            onChange={handleTextChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="次貨幣單位"
            variant="outlined"
            name={`pointUnit`}
            value={options.pointUnit}
            onChange={handleTextChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="次貨幣比例(次貨幣金額=主貨幣金額*比例)"
            variant="outlined"
            number
            name={`rate`}
            value={options.rate}
            onChange={handleTextChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: "16px 0px" }}>貨幣金額</Divider>
      <Grid container spacing={2}>
        {[...options.amounts].map((amount, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                label="金額"
                variant="outlined"
                name={`amount_${index}`}
                value={amount}
                onChange={(e) => handleAmountChange(index, e)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Button
                variant="contained"
                color="error" // or another appropriate color
                onClick={() => handleRemoveAmountItem(index)}
              >
                刪除
              </Button>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleAddAmountItem(options.amounts)}
              disabled={options.amounts.length >= 6}
            >
              新增項目
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
