/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { getGames, deleteGame } from '@api/cms';
import { IMAGE_DOMAIN } from '@constants/api';
// import GroupsModal from '@components/groups/GroupsModal';
import {
    Button, Icon, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Chip
} from "@mui/material";
import GamesModal from '@components/games/GamesModal';


const GamesTab = (props) => {

    // Inside your component
    const { needRefresh } = props;


    const [games, setGames] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);


    const openDeleteConfirmDialog = (item) => {
        setDeleteItem(item);
        setOpenConfirmDialog(true);
    };

    const handleDeleteItem = async () => {
        if (deleteItem) {
            await deleteGame(deleteItem.id); // Replace with your actual API call
            await getData();
            setOpenConfirmDialog(false);
            setDeleteItem(null);
        }
    };

    const handleCloseDialog = () => {
        setOpenConfirmDialog(false);
        setDeleteItem(null);
    };


    const getData = async () => {
        const listRes = await getGames();

        setGames(listRes ?? [])

    }

    const gameCount = useCallback((source = 'AW') => {
        let count = 0;
        if (games) {
            const awGames = games.filter((e) => e.source === source)
            count += awGames.length;
        }
        return count;
    }, [games]);



    useEffect(() => {
        getData();
        // addItem()
    }, [needRefresh])

    const addItem = () => {
        setSelectedItem({
            isAddNew: true,
            id: -1,
            name: '',
            image: null,
            imgUrl: '',
            grp: '',
            defaultTab: false,
            sort: 1,
            source: 'AW'
        })
    }


    const handleCloseModal = async (reGetData) => {
        if (reGetData) await getData()
        setSelectedItem(null)
    }


    return (
        <GamePageWrap>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p> <span>AW: {`${gameCount('AW')}個, `} </span><span>BAAG: {`${gameCount('BBAG')}個`} </span></p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '10px', marginBottom: '10px' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={addItem}
                        mt={10}
                    >
                        <Icon>add</Icon>&nbsp;
                        新增遊戲
                    </Button>
                </div>

            </div>



            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>遊戲ID</TableCell>
                            <TableCell align="right">鎖定</TableCell>
                            <TableCell align="right">名稱</TableCell>
                            <TableCell align="right">Icon</TableCell>
                            <TableCell align="right">所屬清單</TableCell>
                            <TableCell align="right">操作</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {games && games.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <Typography mr={1}>{`${row.source && row.source !== 'null' && row.source !== 'undefined' ? `${row.source}-` : ''}${row.gameID}`}</Typography>

                                        {!row.enabled && <Chip label={`已停用`} variant="contained" color='error' />}
                                    </div>

                                </TableCell>
                                <TableCell align="right">
                                    {!row.isFree && <Chip label={`鎖定`} variant="contained" color='primary' />}
                                    {row.isFree && <Typography>{'否'}</Typography>}
                                </TableCell>
                                <TableCell align="right">
                                    <Typography>{row.nameCH}</Typography>
                                    <Typography>{row.nameEN}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <img src={`${IMAGE_DOMAIN}/${row.imagePathEN}`} alt="English Icon" style={{ maxWidth: '50px', maxHeight: '50px', marginRight: '8px' }} />
                                    <img src={`${IMAGE_DOMAIN}/${row.imagePathCH}`} alt="Chinese Icon" style={{ maxWidth: '50px', maxHeight: '50px' }} />
                                </TableCell>
                                <TableCell align="right">

                                    <ChipContainer>
                                        {row.listItems.map(e => <Chip label={`${e.list.name}`} variant="outlined" />)}

                                    </ChipContainer>
                                </TableCell>

                                <TableCell align="right">
                                    <IconButton onClick={() => { setSelectedItem(row) }}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                    <IconButton onClick={() => { openDeleteConfirmDialog(row) }}>
                                        <Icon>delete</Icon>
                                    </IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <GamesModal show={selectedItem !== null} item={selectedItem} onClose={handleCloseModal} />
            <Dialog open={openConfirmDialog} onClose={handleCloseDialog}>
                <DialogTitle>刪除遊戲</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        您確定要刪除此遊戲嗎？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant="contained"
                        color="secondary">取消</Button>
                    <Button onClick={handleDeleteItem} variant="contained"
                        color="error">刪除</Button>
                </DialogActions>
            </Dialog>
        </GamePageWrap>
    );
};

export default GamesTab;

const GamePageWrap = styled.div`

    margin-top: 20px;
    display: flex;
    flex-direction: column;    
    width: 100%;

    >button {
        margin-left: auto; // Pushes the button to the right
        margin-top: 10px; // Keeps the top margin
        margin-bottom: 10px; // Keeps the bottom margin
        width: 200px;
    }
`;


const ChipContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 8px; // You can adjust the spacing between chips here
`;
