import styled from "styled-components";
import { Typography } from "@mui/material";

export const LoaderContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
});

export const FormTitleTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: 1.6,
  letterSpacing: "0.0075em",
  marginRight: "32px",
  marginBottom: "16px",
  minWidth: "96px",
});
