import moment from "moment";

export const NUMBER_REGEX = /^[0-9]*$/;

export function objectToQueryString(object) {
    if (!object) return '';
    let str = '';
    if (typeof object === 'object') {
        str += '?';
        const suffix = Object.keys(object)
            .map(item => `${item}=${object[item]}&`)
            .join('');
        str += suffix;
        str = str.substring(0, str.length - 1);
        return str;
    }
    console.error(`use objectToString but parameter's type isn't object`);
    return str;
}

/* eslint-disable */
export function lowerCaseObjectKeys(obj) {
    let str = JSON.stringify(obj);
    const pattern = /\"([a-z0-9_-]{0,})\"\:/gi;
    str = str.replace(pattern, function () {
        return `"${arguments[0]
            .charAt(1)
            .toLowerCase()}${arguments[0].slice(2)}`;
    });
    return JSON.parse(str);
}

const DATE_YYYYMMDDhhmmss_FORMAT = 'YYYYMMDDhhmmss'
export function getCurrentYYYYMMDDhhmmss() {
    return moment().format(DATE_YYYYMMDDhhmmss_FORMAT)
}