// import React from "react";
// import styled from "styled-components";
// import classNames from "classnames";
// import { Primary } from "@constants/colors";

// const ExpandSideBarItem = (props) => {
//   const { menuName, path, active, navigate, noHover } = props;
//   const onItemClick = () => {
//     if (path) navigate(path);
//   };

//   return (
//     <SidebarGroupWrap
//       className={classNames("", { active })}
//       onClick={onItemClick}
//       noHover={noHover}
//     >
//       <div className="sb-item-wrap">
//         {/* {icon && (
//                     <div>
//                         <img
//                             alt={menuName}
//                             className="sb-itme-icon"
//                             src={`/images/menu/${icon}.png`}
//                         />
//                     </div>
//                 )} */}
//         <p>{menuName}</p>
//       </div>
//     </SidebarGroupWrap>
//   );
// };

// const SidebarGroupWrap = styled.div`
//   box-sizing: border-box;
//   height: ${({ isOpen }) => (isOpen ? "auto" : "60px")};
//   background: ${Primary.White};
//   color: ${Primary.Black};
//   font-weight: 600;
//   border-radius: 10px;
//   margin: 10px;
//   cursor: pointer;
//   text-align: center;

//   &.active {
//     background: ${Primary.Green};
//     color: ${Primary.White};
//   }
//   ${(props) =>
//     !props.noHover &&
//     `
//         &:hover {
//             background: ${Primary.Green};
//             color: ${Primary.White};
//         }
//     `}

//   .sb-item-wrap {
//     display: inline-flex;
//     align-items: center;
//     height: 60px;
//     > div:first-child {
//       width: 60px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }

//     .sb-itme-icon {
//       mask-position: center;
//       path {
//         fill: white;
//       }
//     }
//   }
// `;

// export default ExpandSideBarItem;

import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
// import { Primary } from "@constants/colors";
import Icon from '@mui/material/Icon';
import { usePanelTheme } from "@hooks/usePanelTheme";

// const ICON_ACTIVE_COLOR = "rgb(158, 119, 237)"; // Example: green[500]
const TEXT_COLOR = "#FFFFFF"; // Example: white
const HOVER_COLOR = "rgba(255, 255, 255, 0.04)"; // Example: green[100]

const ExpandSideBarItem = (props) => {
  // const { menuName, path, active, navigate, noHover } = props;
  const { theme } = usePanelTheme(); // Assumed custom hook
  const { menuName, path, icon } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = path === location.pathname;

  const onItemClick = () => {
    if (path) navigate(path);
  };
  // color: ${({ menuTextColor }) => menuTextColor ?? Primary.MunuItemText};
  //menuTextColor={theme.admin_menuTextColor}
  return (
    <CustomListItem onClick={onItemClick} active={isActive ? 1 : 0} menuTextColor={theme.admin_menuTextColor}>
      <div className="innerWrapper">
        <Icon sx={{ fontSize: 19, marginRight: 1, color: isActive ? theme.admin_primaryButtonColor : theme.admin_menuTextColor }}>{icon}</Icon>
        <ListItemText primaryTypographyProps={{ fontSize: '20px', fontWeight: 600 }} primary={menuName} />
      </div>
    </CustomListItem>
  );
};
const CustomListItem = styled(ListItem)`
  color: ${(props) => (props.active ? TEXT_COLOR : props.menuTextColor)};
  .innerWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: ${(props) => (props.active ? "rgba(255, 255, 255, 0.04)" : "transparent")};
    border-radius: 8px;
    width: 100%; // Adjust width as needed
    padding: 0px 16px; // Adjust padding as needed
    &:hover {
      background-color: ${HOVER_COLOR};
    }
  }
`;


export default ExpandSideBarItem;
