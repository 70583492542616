const ROUTES = {
  ROOT: "/",
  LOGIN: "login",
  MARQUEE: "marquee",
  BANNER: "banner",
  ANNOUNCEMENT: "announcement",
  NEWS: "news",
  GROUPS: "groups",
  GAMES: "games",
  LOGOS: "logos",
  THEME: "theme",
  MAINTENANCES: "maintenances",
  USERS: "users",
  SYSTEM: "system",
  ADMIN_PANEL: "adminPanel",
  D_W_POLICY: "dwPolicy",
  EMPTY: "empty",
};

export const SWITCH_ROUTES = {
  ROOT: ROUTES.ROOT,
  LOGIN: `/${ROUTES.LOGIN}`,
  MARQUEE: `${ROUTES.ROOT}${ROUTES.MARQUEE}`,
  BANNER: `${ROUTES.ROOT}${ROUTES.BANNER}`,
  ANNOUNCEMENT: `${ROUTES.ROOT}${ROUTES.ANNOUNCEMENT}`,
  NEWS: `${ROUTES.ROOT}${ROUTES.NEWS}`,
  GROUPS: `${ROUTES.ROOT}${ROUTES.GROUPS}`,
  GAMES: `${ROUTES.ROOT}${ROUTES.GAMES}`,
  LOGOS: `${ROUTES.ROOT}${ROUTES.LOGOS}`,
  THEME: `${ROUTES.ROOT}${ROUTES.THEME}`,
  MAINTENANCES: `${ROUTES.ROOT}${ROUTES.MAINTENANCES}`,
  USERS: `${ROUTES.ROOT}${ROUTES.USERS}`,
  SYSTEM: `${ROUTES.ROOT}${ROUTES.SYSTEM}`,
  ADMIN_PANEL: `${ROUTES.ROOT}${ROUTES.ADMIN_PANEL}`,
  EMPTY: `${ROUTES.ROOT}${ROUTES.EMPTY}`,
  D_W_POLICY: `${ROUTES.ROOT}${ROUTES.D_W_POLICY}`,
};

export default ROUTES;
