import React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useSidebarMenu } from "@hooks/useSidebarMenu";
import { usePanelTheme } from "@hooks/usePanelTheme";

import ExpandSideBarItem from "@components/sidebar/ExpandSideBarItem";
import CollapseSideBarItem from "@components/sidebar/CollapseSideBarItem";
import {
  SIDEBAR_WIDTH_COLLAPSE,
  SIDEBAR_WIDTH_EXPAND,
} from "@constants/numbers";
import { NEW_MENU_ITEMS, META_TITLE, LONG_LOGO_PATH } from "@constants/config";
import { Primary } from "@constants/colors";

const Sidebar = () => {
  const { isExpand, onToggle } = useSidebarMenu(); // Assumed custom hook
  const { theme } = usePanelTheme(); // Assumed custom hook

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Wrapper isExpand={isExpand} menuBackgroundColor={theme.admin_menuBackgroundColor} >
      <img src={LONG_LOGO_PATH} alt="Logo" style={{ width: '70%', height: 'auto', display: 'block', margin: '0 auto' }} draggable="false" /> {/* Non-draggable logo */}
      <h5>{META_TITLE}</h5>
      <div className="sidebar-items">
        {isExpand
          ? NEW_MENU_ITEMS().map((m) => (
            <ExpandSideBarItem
              key={m.menuName}
              active={m.path === location.pathname}
              {...m}
              navigate={navigate}
            />
          ))
          : NEW_MENU_ITEMS().map((m) => (
            <CollapseSideBarItem
              key={m.menuName}
              active={m.path === location.pathname}
              {...m}
              navigate={navigate}
              onToggleMenu={onToggle}
            />
          ))}
      </div>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  width: ${({ isExpand }) =>
    isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
  min-width: ${({ isExpand }) =>
    isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
  max-width: ${({ isExpand }) =>
    isExpand ? `${SIDEBAR_WIDTH_EXPAND}px` : `${SIDEBAR_WIDTH_COLLAPSE}px`};
  color: white;
  flex-grow: 1;
  border-right: 1px solid black;
  background: ${({ menuBackgroundColor }) => menuBackgroundColor ?? Primary.SideBar};

  > h5 {
    color: ${Primary.MunuItemText};
    width: 100%;
    text-align: center;
    margin-top: 0px;
  }
  .sidebar-items {
    height: 100%;
    max-height: calc(100% - 100px);
    overflow: auto;
    padding-bottom: 20px;
  }
`;
export default Sidebar;
