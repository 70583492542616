/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormLabel,
  FormGroup
} from "@mui/material";
import {
  LANG_SELECTOR_ITEMS,
} from "@constants/config";
import { getNews } from "@api/cms";
import { IMAGE_DOMAIN } from "@constants/api";

export function NewsOptionForm({ optionKey, value, onChange }) {
  const [options, setOptions] = useState({

  });
  const [newsListMap, setNewsListMap] = useState({});
  // const { lang } = useSelectLang();

  const notifyChange = (newOptions) => {
    if (typeof onChange === "function") {
      onChange(optionKey, newOptions);
    }
  };

  useEffect(() => {
    if (value && Object.keys(value).length > 0) setOptions(value);
  }, []);


  const handleNewsItemChange = (langKey, e) => {

    setOptions((prev) => ({
      ...prev,
      [langKey]:
        e.target.value,
    }));
    notifyChange({
      ...options,
      [langKey]: e.target.value,
    });
  }


  const getData = async () => {
    const res = await getNews('');
    const tNewsList = res
      ? res.map((r) => {
        return { ...r, imgUrl: `${IMAGE_DOMAIN}/${r.imagePath}` };
      })
      : [];
    const map = {};
    for (const langItem of LANG_SELECTOR_ITEMS) {
      const lang = langItem.value;
      map[lang] = tNewsList.filter((e) => {
        return e.language === lang;
      });
    }

    setNewsListMap(
      map
    );


  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {LANG_SELECTOR_ITEMS.map((langItem) => (
          <Grid key={`news-lang-select-${langItem.value}`} item xs={12} sm={4}>
            <FormGroup>
              <FormLabel id="demo-row-select-lang-buttons-group-label">
                {langItem.label}
              </FormLabel>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <Select
                  id={`demo-simple-select-news-${langItem.value}`}
                  value={options[langItem.value] || ''}
                  onChange={(e) => { handleNewsItemChange(langItem.value, e) }}
                  autoWidth
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>無顯示</em>
                  </MenuItem>
                  {newsListMap[langItem.value] && newsListMap[langItem.value].map(e => <MenuItem key={e.id} value={e.id}>{e.title}</MenuItem>)}
                </Select>
              </FormControl>
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
