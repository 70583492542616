import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Button, ButtonGroup } from "@mui/material";

const TIME_RANGE_KEY = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
};
const QUICK_TIME_RAGE = [
  { title: "本日", key: TIME_RANGE_KEY.DAY },
  { title: "本週", key: TIME_RANGE_KEY.WEEK },
  { title: "本月", key: TIME_RANGE_KEY.MONTH },
];
const QuicklySelectTimeRange = (props) => {
  const { setStartDate, setEndDate } = props;

  const handleClick = (k) => {
    if (!setStartDate || !setEndDate) return null;
    if (k === TIME_RANGE_KEY.DAY) {
      setStartDate(moment().startOf("day"));
      setEndDate(moment().endOf("day"));
    } else if (k === TIME_RANGE_KEY.WEEK) {
      setStartDate(moment().startOf("week").startOf("day"));
      setEndDate(moment().endOf("week").endOf("day"));
    } else if (k === TIME_RANGE_KEY.MONTH) {
      setStartDate(moment().startOf("month").startOf("day"));
      setEndDate(moment().endOf("month").endOf("day"));
    }
  };


  return (
    <QuicklySelectTimeRangeWrap>
      時間快選
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {QUICK_TIME_RAGE.map((q, index) => {
          const baseStyle = { fontSize: '1rem', borderRadius: 0 }
          let style = { ...baseStyle };
          if (index === 0) {
            style = { ...baseStyle, borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }
          } else if (index === QUICK_TIME_RAGE.length - 1) {
            style = { ...baseStyle, borderTopRightRadius: 5, borderBottomRightRadius: 5 }
          }

          return <Button style={style} key={q.key} onClick={() => handleClick(q.key)}>
            {q.title}
          </Button>
        })}
      </ButtonGroup>
    </QuicklySelectTimeRangeWrap>
  );
};

export default QuicklySelectTimeRange;

const QuicklySelectTimeRangeWrap = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;
