import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Primary } from "@constants/colors";
import { getUsers, login } from "@api/cms";
import { setTokenToLS, setPermissionsFromTokenToLS, setEmailToLS } from "@utils/ls";
import { SWITCH_ROUTES } from "@constants/routes";
import { toast } from "react-toastify";
import { NEW_MENU_ITEMS, META_TITLE, LONG_LOGO_PATH } from "@constants/config";
import {
  Card,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import clsx from 'clsx'
import { usePanelTheme } from "@hooks/usePanelTheme";


const INIT_INPUT_FORM = {
  email: "", //test@gw123.net
  password: "", //123123
};
function LoginPage() {
  const [inputForm, setInputForm] = useState(INIT_INPUT_FORM);
  const [loading, setLoading] = useState(false);
  const emailInputRef = useRef(null);
  const pasInputRef = useRef(null);
  const [isAutoFill, setIsAutoFill] = useState(false);
  const [autoFillHappend, setAutoFillHappend] = useState(false);
  const { theme } = usePanelTheme(); // Assumed custom hook

  const navigate = useNavigate();

  useEffect(() => {
    getUsers().then((r) => {
      if (r !== undefined) window.location.href = SWITCH_ROUTES.MARQUEE;
    }).catch(e => {

    });
  }, []);

  const handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setInputForm({ ...inputForm, [name]: value });
  };

  const handleLogin = async () => {
    setLoading(true);
    const res = await login({
      ...inputForm,
    });

    if (res && res?.loginStatus === "success") {
      console.log(' res ---->  ', res)
      const { token, email } = res;
      console.log(' token ->  ', token)
      setEmailToLS(email);
      setTokenToLS(token);
      setPermissionsFromTokenToLS(token);

      const firstRoute = NEW_MENU_ITEMS()[0];

      if (firstRoute) navigate(firstRoute.path);
      else navigate(SWITCH_ROUTES.EMPTY);
    } else {
      toast.error("登入失敗");
    }
    setLoading(false);
  };
  const isValid = () => {
    const { email, password } = inputForm;
    return (email.length > 0 && password.length > 0) || autoFillHappend;
  };

  return (
    <StyleWrapper backgroundImagePath={theme.admin_loginBackgroundImagePath}>
      <Card style={{
        maxWidth: 800,
        margin: '0.5rem',
        padding: '1rem'
      }}>
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className={clsx({
              'py-8 px-14 h-full': true,
            })} style={{
              backgroundSize: 'cover',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }} >
              <Logo><img src={LONG_LOGO_PATH} alt="logo" /></Logo>
              <MainTitle>{META_TITLE}</MainTitle>
            </div>
          </Grid>

          <Grid item container spacing={3} lg={6} md={6} sm={6} xs={12} style={{ padding: '1.2rem' }}>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                inputRef={emailInputRef}
                variant="outlined"
                name="email"
                type="email"
                placeholder="請輸入您的Email帳號"
                label={"帳號"}
                maxLength={255}
                size="small"
                value={inputForm.email}
                onChange={handleInputChange}
                autoComplete="email"
                onKeyUp={(e) => {
                  if (e.keyCode === 13 && pasInputRef.current) {
                    pasInputRef.current.focus();
                  }
                }}
                InputLabelProps={{ shrink: isAutoFill || undefined }}
                InputProps={{
                  onAnimationStart: (e) => {
                    if (e.animationName === 'mui-auto-fill') {
                      setIsAutoFill(true);
                      setAutoFillHappend(true)
                    }
                  },
                  onAnimationEnd: (e) => {
                    if (e.animationName === 'mui-auto-fill-cancel') {
                      setIsAutoFill(false);
                    }
                  },
                  onFocus: (e) => {
                    setIsAutoFill(false)
                  },
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                autoComplete='password'
                inputRef={pasInputRef}
                name="password"
                type="password"
                placeholder="請輸入您的密碼"
                label={"密碼"}
                maxLength={255}
                size="small"
                value={inputForm.password}
                onChange={handleInputChange}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    handleLogin();
                  }
                }}
                InputLabelProps={{ shrink: isAutoFill || undefined }}
                InputProps={{
                  onAnimationStart: (e) => {
                    if (e.animationName === 'mui-auto-fill') {
                      setIsAutoFill(true);
                      setAutoFillHappend(true)
                    }
                  },
                  onAnimationEnd: (e) => {
                    if (e.animationName === 'mui-auto-fill-cancel') {
                      setIsAutoFill(false);
                    }
                  },
                  onFocus: () => setIsAutoFill(false),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || !isValid()}
                onClick={handleLogin}
                style={{ width: '100%' }}
              >
                登入
              </Button>
            </Grid>


          </Grid>
        </Grid>
      </Card>
    </StyleWrapper >
  );
}

export default LoginPage;

const StyleWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16%;
  width: 100%;
  background: ${Primary.Color2};
  color: black;
  background-image: url("${({ backgroundImagePath }) => backgroundImagePath}"); 
  background-size: cover;

  @media only screen and (max-width: 950px) {
    flex-direction: column;
    justify-content: center;
  }

  > div:first-child {
    @media only screen and (max-width: 950px) {
      // display: none;
      padding-bottom: 40px;
    }
    p {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-family: Helvetica;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      color: black;
    }
    > p {
      margin-bottom: 4%;
      font-size: 3rem;
      letter-spacing: 1.39px;
    }
  }
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
   
    & span {
        font-size: 26px;
        line-height: 1.3;
        font-weight: 800;
    }

    > img {
      
      max-width: 200px;
    }
`;

const MainTitle = styled.div`
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 24px;
`;