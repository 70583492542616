import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Card, Grid, Button, Icon, CircularProgress, TextField, FormGroup, Typography } from "@mui/material";
import { LoaderContainer } from "@/styled_components/common";
import { getSystemMetas, putSystemMetas } from "@api/cms";
import { ADMIN_SYSTEM_METAS_MAP, ADMIN_SYSTEM_METAS_DEFAULT_VALUE } from "@constants/config";
import { usePanelTheme } from "@hooks/usePanelTheme";
import LinearProgress from '@mui/material/LinearProgress';


export default function AdminPanelThemePage() {
    const [metas, setMetas] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const { changeTheme } = usePanelTheme(); // Assumed custom hook

    const getSystemMeta = async () => {
        const res = await getSystemMetas();
        console.log(' res ===>  ', res);

        const themes = res.filter((e) => e.id.startsWith('admin_'));

        if (themes) {
            const obj = {};
            for (const item of themes) {
                obj[item.id] = item.value;
            }
            setMetas(obj);
        }
    }

    useEffect(() => {
        getSystemMeta()
    }, [])

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setMetas(prev => { return { ...prev, [name]: target.value } });
    }

    const uploadFile = (file) => {
        const formData = new FormData();
        formData.append('file', file); // Adjust 'file' depending on your server's expected parameter

        const xhr = new XMLHttpRequest();

        xhr.open('POST', window.API_DOMAIN + "/File/upload", true);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = Math.round((event.loaded / event.total) * 100);
                setUploadProgress(percentComplete);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                // Handle the response. The response might contain the URL of the uploaded file.
                const response = JSON.parse(xhr.responseText);
                setMetas(prev => { return { ...prev, admin_loginBackgroundImagePath: response.location } });
            } else {
                // Handle error
            }
        };

        xhr.send(formData);
    };

    const handleFileChange = (event) => {
        // Get the file from the event
        const file = event.target.files[0];
        if (file) {
            // setUploadedFile(file);
            uploadFile(file);
        }
    };

    const resumeOriginImagePath = () => {
        setMetas(prev => { return { ...prev, admin_loginBackgroundImagePath: ADMIN_SYSTEM_METAS_DEFAULT_VALUE.admin_loginBackgroundImagePath } });
    }

    const handleSubmit = async () => {
        try {
            const res = await putSystemMetas({ metas })
            if (res) {
                toast.success("修改成功")
                changeTheme(metas);
            } else {
                toast.error(`修改失敗`)
            }

        } catch (error) {
            toast.error(`修改失敗: ${error.toString()}`)

        }


    }
    console.log(' metas -> ', metas)
    if (!metas)
        return (
            <LoaderContainer>
                <CircularProgress />
            </LoaderContainer>
        );
    console.log(' uploadProgress ->  ', uploadProgress);
    return (
        <Card
            elevation={3}
            className="w-full overflow-auto"
            style={{ padding: "24px" }}
        >
            <ThemePageWrap>

                <strong>登入頁底圖</strong>
                <strong>{`(1024x1024(長 x 高).png)`}</strong>

                <Grid container>
                    <Grid item xs={6} md={3}>
                        <img
                            style={{ width: '100%', marginTop: '20px' }}
                            src={metas['admin_loginBackgroundImagePath']}
                            alt={'imagePth'}
                            loading="lazy"
                        />
                        {(uploadProgress > 0 && uploadProgress < 100) && (
                            <LinearProgress variant="determinate" value={uploadProgress} />
                        )}
                    </Grid>
                </Grid>

                <Grid container gap={3} justifyItems={'center'} style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Grid item xs={6} md={3} >
                        <Button component="label" color='secondary' variant="contained" startIcon={<Icon>cloud_upload</Icon>}>
                            上傳檔案
                            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="subtitle1">
                            或
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <FormGroup>
                            <TextField
                                label="輸入位址"
                                variant="outlined"
                                name={`admin_loginBackgroundImagePath`}
                                value={metas['admin_loginBackgroundImagePath']}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container justifyItems={'center'} style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <Button onClick={resumeOriginImagePath} style={{ fontSize: '10px', width: '120px', height: '30px', backgroundColor: '#fff', color: '#666' }} component="label" color='success' variant="contained" >
                        恢復原始值
                    </Button>
                </Grid>




                <strong>主題色</strong>
                <strong>{`輸入色碼。 ex. #ec7000 / rgb(255, 246, 220)`}</strong>

                <div className='color-list'>
                    <div>名稱</div>
                    <div>值</div>
                    <div>原始值</div>
                    {Object.keys(metas).map(k => k !== 'admin_loginBackgroundImagePath' && (
                        <>
                            <div>{ADMIN_SYSTEM_METAS_MAP[k]}</div>
                            <div className='flex'>
                                <ColorArea bgColor={metas[k]} />
                                <input style={{ width: '150px' }} name={k} value={metas[k]} onChange={handleInputChange} />
                            </div>
                            <div className='flex'>
                                <ColorArea bgColor={ADMIN_SYSTEM_METAS_DEFAULT_VALUE[k]} />
                                {/* <span>{ADMIN_SYSTEM_METAS_DEFAULT_VALUE[k]}</span> */}
                                <Button onClick={() => {

                                    setMetas(prev => { return { ...prev, [k]: ADMIN_SYSTEM_METAS_DEFAULT_VALUE[k] } });

                                }} style={{ fontSize: '10px', width: '120px', height: '30px', backgroundColor: '#fff', color: '#666' }} component="label" variant="contained" >
                                    恢復原始值
                                </Button>
                            </div>

                        </>
                    ))}
                </div>

                <Grid container justifyContent={"end"}>
                    <Grid>
                        <Button
                            sx={{ mt: 8 }}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            mt={10}
                        >
                            <Icon>save</Icon>&nbsp;
                            儲存
                        </Button>
                    </Grid>
                </Grid>
            </ThemePageWrap>
        </Card>
    );
}

const ThemePageWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1rem;

    .color-list {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        max-width: 700px;
        padding: 50px 0px 20px 0px;
        grid-gap: 10px;
    }

    .flex {
        display: flex;
        margin-right: 20px;
        align-items: center;
        > input {
            height: 80%;
            width: 60%;
        }
    }
`;

const ColorArea = styled.div`  
    height: 30px;
    width: 30px;
    margin: 10px;
    border: 0.5px solid #666;
background:${({ bgColor }) => bgColor};
`

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});