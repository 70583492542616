/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useSelectLang } from '@hooks/useLang';
import { copyallGroups, getGroups } from '@api/cms';
import { IMAGE_DOMAIN } from '@constants/api';
import GroupsModal from '@components/groups/GroupsModal';
import QuicklyCopySettings from '@components/shared/QuicklyCopySettings';
import { Card, Button, Icon } from "@mui/material";
import { getGameLists } from '@api/cms';


export default function GroupsPage() {
    const { lang } = useSelectLang()
    const [groups, setGroups] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [gameLists, setGameLists] = useState([]);

    const getData = async () => {
        const res = await getGroups(lang);
        setGroups(res ? res.map(r => {
            return { ...r, imgUrl: `${IMAGE_DOMAIN}/${r.imagePath}` }
        }) : [])

        const listRes = await getGameLists(false);

        setGameLists(listRes ?? [])
    }
    useEffect(() => {
        getData();
        // addItem()
    }, [lang])

    const addItem = () => {
        setSelectedItem({
            isAddNew: true,
            id: -1,
            name: '',
            image: null,
            imgUrl: '',
            language: lang,
            grp: '',
            defaultTab: false,
            sort: 1
        })
    }

    const handleCloseModal = async (reGetData) => {
        if (reGetData) await getData()
        setSelectedItem(null)
    }

    const handleQuicklyCopySubmit = async (toLanguage) => {
        if (!lang || !toLanguage) {
            toast.error("請重新整理網頁後再嘗試")
        } else {
            const res = await copyallGroups({ fromLanguage: lang, toLanguage })
            if (res && typeof res === 'object' && groups)
                if (res?.length === groups?.length) toast.success(`複製到 ${toLanguage} 成功`)
        }
    }

    const getGameListName = (listID) => {
        const gameList = gameLists.find(gl => gl.id === listID);
        return gameList ? gameList.name : 'N/A';
    };

    return (
        <Card
            elevation={3}
            className="w-full overflow-auto"
            style={{ padding: "24px" }}
        >
            <GroupPageWrap>
                <QuicklyCopySettings onSubmit={handleQuicklyCopySubmit} />
                圖片為 SVG檔案。細節請參考現有的分類圖片。
                <strong>圖片檔名不可重複</strong>
                <strong>順序(sort)號碼越小越靠前</strong>
                <strong>News的grp只能設定為 “NEWS”</strong>

                {`教學： 點擊“新增分類“按鈕，或者圖片 來打開新增/修改視窗。 -> 右上角"X"按鈕用於關閉。 視窗內最下方的儲存/刪除按鈕提供該名稱所對應的功能。`}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={addItem}
                    mt={10}
                >
                    <Icon>add</Icon>&nbsp;
                    新增分類
                </Button>
                <GroupsCard>
                    {
                        groups && groups.map(n => (
                            <div key={n.id} onClick={() => setSelectedItem(n)}>
                                <img src={n.imgUrl} alt={`${n.name}-${n.id}`} />
                                <p>{`名稱: ${n.name} ${n?.defaultTab ? '(Default Tab)' : ''}`}</p>
                                <p>{`grp: ${n?.grp || ''}`}</p>
                                <p>{`sort: ${n?.sort || ''}`}</p>
                                <p>{`綁定清單: ${getGameListName(n.listID)}`}</p>
                            </div>
                        ))
                    }
                </GroupsCard>

                <GroupsModal show={selectedItem !== null} item={selectedItem} gameLists={gameLists} onClose={handleCloseModal} />
            </GroupPageWrap>
        </Card>
    );
}

const GroupPageWrap = styled.div`
    display: flex;
    flex-direction: column;    
    width: 100%;

    >button {
        margin: 10px 0px;
        width: 200px;
    }
`;

const GroupsCard = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;

    > div {
        width: 200px;
        min-width: 120px;
        cursor: pointer;
        background: antiquewhite;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        >p {
            margin: 0;
            margin-bottom: 5px;
        }
        >img {       
            height: 100px;
            width: 100px;
            margin: auto;
        }
    }
`