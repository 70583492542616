import React, { useState, createContext, useContext, useMemo, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Assuming the API fetch function is defined elsewhere in your utilities
// import { fetchThemeFromAPI } from '@utils/api';
import { getSystemMetas } from "@api/cms";

const PanelThemeContext = createContext({
    theme: {},
    changeTheme: () => { },
});

const PanelThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState({
        admin_menuBackgroundColor: 'rgb(28, 37, 54)',
        admin_menuTextColor: 'rgb(181, 188, 196)',
        admin_primaryButtonColor: 'rgb(158, 119, 237)',
        admin_primaryButtonTextColor: 'rgb(255, 255, 255)',
        admin_loginBackgroundImagePath: '/images/shared/login_background.png',
    });

    useEffect(() => {
        getSystemMetas().then(res => {
            if (res) {
                const themes = res.filter((e) => e.id.startsWith('admin_'));
                if (themes) {
                    const obj = {};
                    for (const item of themes) {
                        obj[item.id] = item.value;
                    }
                    setTheme(obj);
                }
            }

        });
    }, []);

    const muiTheme = useMemo(() => {
        const theTheme = createTheme({
            palette: {
                primary: {
                    main: theme.admin_primaryButtonColor,
                },
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            fontSize: '20px', // Set your desired font size
                            borderRadius: 50,
                            color: theme.admin_primaryButtonTextColor,
                        },
                    }
                },
                MuiSwitch: {
                    styleOverrides: {
                        switchBase: {
                            color: "#999"
                        },
                    },
                },
            }
        });
        return theTheme;
    }, [theme]);

    const value = useMemo(() => {
        const changeTheme = (newTheme) => {
            setTheme(newTheme);
        };
        return {
            theme,
            changeTheme,
        };
    }, [theme]);

    return (
        <PanelThemeContext.Provider value={value}>
            <ThemeProvider theme={muiTheme}>
                {children}
            </ThemeProvider>
        </PanelThemeContext.Provider>
    );
};

export const usePanelTheme = () => useContext(PanelThemeContext);

export default PanelThemeProvider;
