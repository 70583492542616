import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { getThemeColors, patchThemeColors } from '@api/cms';
import { Card, Grid, Button, Icon } from "@mui/material";

export default function ThemePage() {
    const [config, setConfig] = useState(null)
    const [modifiedConfig, setModifiedConfig] = useState(null)

    const getConfig = async () => {
        const res = await getThemeColors();
        try {
            if (res && typeof res === 'object') {
                setConfig({ ...res })
                setModifiedConfig({ ...res })
                return
            }
        } catch (e) {
            toast.error("此設定檔有錯誤，請查看原檔是否符合JSON格式")
        }
        setConfig({})
        setModifiedConfig({})
        toast.error("獲取資料有誤，請重新整理網頁後再嘗試")
    }
    useEffect(() => {
        getConfig()
    }, [])

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setModifiedConfig(prev => { return { ...prev, [name]: target.value } });
    }
    const handleSubmit = async () => {
        // const res = await patchThemeColors({
        //     "primary": "rgb(220,40,1)",
        //     "minor": "rgb(255,234,223)"
        //     })
        const res = await patchThemeColors(modifiedConfig)
        if (res?.endsWith("ThemeColor.json")) {
            toast.success("修改成功")
            await getConfig()
        } else {
            toast.error("修改失敗")
        }
    }

    if (!config) return <div>Loading....</div>

    return (
        <Card
            elevation={3}
            className="w-full overflow-auto"
            style={{ padding: "24px" }}
        >
            <ThemePageWrap>
                <strong>Theme Colors</strong>
                <strong>{`輸入色碼。 ex. #ec7000 / rgb(255, 246, 220)`}</strong>

                <div className='color-list'>
                    <div>名稱</div>
                    <div>值</div>
                    <div>原始值</div>
                    {Object.keys(modifiedConfig).map(k => (
                        <>
                            <div>{k}</div>
                            <div className='flex'>
                                <ColorArea bgColor={modifiedConfig[k]} />
                                <input name={k} value={modifiedConfig[k]} onChange={handleInputChange} />
                            </div>
                            <div className='flex'>
                                <ColorArea bgColor={config[k]} />
                                {config[k]}
                            </div>
                        </>
                    ))}
                </div>

                <Grid container justifyContent={"end"}>
                    <Grid>
                        <Button
                            sx={{ mt: 8 }}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            mt={10}
                        >
                            <Icon>save</Icon>&nbsp;
                            儲存
                        </Button>
                    </Grid>
                </Grid>
            </ThemePageWrap>
        </Card>
    );
}

const ThemePageWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1rem;

    .color-list {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        max-width: 700px;
        padding: 50px 0px 20px 0px;
        grid-gap: 10px;
    }

    .flex {
        display: flex;
        margin-right: 20px;
        align-items: center;
        > input {
            height: 80%;
            width: 60%;
        }
    }
`;

const ColorArea = styled.div`  
    height: 30px;
    width: 30px;
    margin: 10px;
background:${({ bgColor }) => bgColor};
`