/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";

export function TextListForm({ optionKey, value, onChange, itemName }) {
  const [options, setOptions] = useState([]);

  const notifyChange = (newOptions) => {
    setOptions(newOptions);
    if (typeof onChange === "function") {
      onChange(optionKey, newOptions);
    }
  };

  useEffect(() => {
    if (Object.keys(value).length > 0) setOptions(value);
  }, []);

  const handleAddAmountItem = (items) => {
    const newCodes = [...options, 0];
    setOptions(newCodes);

    notifyChange(newCodes);
  };

  const handleAmountChange = (index, e) => {
    const newCodes = [...options];
    newCodes[index] = e.target.value; // Set the new value at the specific index
    setOptions(newCodes); // Update the state with the new amounts array
    notifyChange(newCodes);
  };

  const handleRemoveAmountItem = (index) => {
    // Create a new array without the item at the specified index
    const newCodes = options.filter((_, i) => i !== index);
    // Update the state with the new array
    setOptions(newCodes);
    notifyChange(newCodes);
  };

  return (
    <>
      <Grid container spacing={2}>
        {[...options].map((code, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={11}>
              <TextField
                required
                label={itemName ?? "內容"}
                variant="outlined"
                name={`pixel_code_${index}`}
                value={code}
                onChange={(e) => handleAmountChange(index, e)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Button
                variant="contained"
                color="error" // or another appropriate color
                onClick={() => handleRemoveAmountItem(index)}
              >
                刪除
              </Button>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleAddAmountItem(options)}
              disabled={options.length >= 6}
            >
              新增
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
