import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import BaseModal from "src/components/shared/modals/BaseModal";
import { FlexDirectionConfigs } from "@constants/config";
import { UploadImageWithTitle } from "@components/shared/ImageUploadZone";
import { deleteGroups, postGroups, putGroups } from "@api/cms";
import { NUMBER_REGEX } from "@utils/util";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@mui/material";
const GroupsModal = (props) => {
  const { show, item, onClose, gameLists } = props;
  const [name, setName] = useState("");
  const [grp, setGrp] = useState("");
  const [sort, setSort] = useState(90);
  const [defaultTab, setDefaultTab] = useState(false);
  const [tempImageFiles, setTempImageFiles] = useState({}); //id: Files
  const [tempPreview, setTempPreview] = useState({}); //id: preview url
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGameList, setSelectedGameList] = useState("");

  const initStates = () => {
    setName("");
    setGrp("");
    setSort("");
    setDefaultTab(false);
    setTempImageFiles({});
    setTempPreview({});
    setSelectedGameList(gameLists?.[0]?.id || ""); // Initialize with the first game list ID

  };
  useEffect(() => {
    if (!show) {
      initStates();
    } else if (item) {
      setName(item.name);
      setGrp(item.grp);
      setSort(item.sort);
      setDefaultTab(item.defaultTab);
      setSelectedGameList(item.listID || gameLists?.[0]?.ID || "");
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, [show, gameLists]);

  const handleSelectGameList = (event) => {
    console.log(' value ->  ', event.target.value)
    setSelectedGameList(event.target.value);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const setTempImage = (id, file, preview) => {
    if (file && preview) {
      setTempImageFiles({ ...tempImageFiles, [id]: file });
      setTempPreview({ ...tempPreview, [id]: preview });
    }
  };
  const handleSortInput = (e) => {
    const v = e.target.value;
    if (!NUMBER_REGEX.test(v)) {
      toast.warn("只能輸入整數");
      return;
    }
    try {
      const number = parseInt(v);
      if (number > 100) toast.warn("輸入得值最大是100");
      else setSort(number);
    } catch (e) {
      toast.warn("只能輸入整數");
    }
  };

  const handleDeleteImage = async (id) => {
    if (tempImageFiles.hasOwnProperty(id)) {
      let newTempFile = { ...tempImageFiles };
      let newTempPreview = { ...tempPreview };
      delete newTempFile[id];
      delete newTempPreview[id];
      setTempImageFiles({ ...newTempFile });
      setTempPreview({ ...newTempPreview });
    }
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    let request = {
      ID: item.isAddNew ? 0 : item.id,
      Name: name,
      Sort: sort, //TODO to integer
      Language: item.language,
      DefaultTab: defaultTab,
      grp,
      ListID: selectedGameList
    };
    if (tempImageFiles[item.id]) {
      request.Image = tempImageFiles[item.id];
    } else {
      request.ImagePath = item.imagePath;
    }

    let res;

    try {
      if (item.isAddNew) res = await postGroups(request);
      else res = await putGroups(request);
      if (res?.errors)
        try {
          toast.error(`${res?.status}. ${JSON.stringify(res?.errors)}`);

        } catch (error) {
          toast.error(`${res?.status}. ${res?.errors}`);

        }
      else if (res) {
        toast.success("修改成功");
        onClose(true);
      }
    } catch (error) {
      toast.error(`${error}`);
    }


    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    let res = await deleteGroups(item.id);
    try {
      if (JSON.stringify(res) === "{}") {
        toast.success("修改成功");
        onClose(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    toast.error("修改失敗");
    setIsLoading(false);
  };

  return (
    <BaseModal
      show={show}
      justifyContent={FlexDirectionConfigs.Center}
      alignItems={FlexDirectionConfigs.Center}
      handleOutsideClick={stopPropagation}
    >
      {item && (
        <GroupsContent onClick={stopPropagation}>
          {item.isAddNew && <p>新增分類</p>}
          <div className="groups-modal-head">
            <UploadImageWithTitle
              width="170px"
              classnames="groups-modal-upload-warp"
              outerClassname="groups-modal-upload-image"
              title={`${item.imagePath?.split("/")[1] || ""}`}
              data={{ ...item, tempImageUrl: tempPreview[item.id] }}
              setTempImage={setTempImage}
              onDelete={handleDeleteImage}
              hideTrashCan={!tempPreview[item.id]}
            />
            <div>
              <span>顯示名稱</span>
              <input value={name} onChange={(e) => setName(e.target.value)} />

              <span>對應的GRP值</span>
              <input value={grp} onChange={(e) => setGrp(e.target.value)} />

              <span>預設Tab</span>
              <div>
                <input
                  type="radio"
                  id="default-tab-true"
                  value={defaultTab}
                  checked={defaultTab === true}
                  onChange={(e) => setDefaultTab(true)}
                />
                <label htmlFor="default-tab-true">True</label>
                <input
                  type="radio"
                  id="default-tab-false"
                  value={defaultTab}
                  checked={defaultTab === false}
                  onChange={(e) => setDefaultTab(false)}
                />
                <label htmlFor="default-tab-false">False</label>
              </div>

              <span>順序（僅限數字，越小越靠前）</span>
              <input value={sort} type="number" onChange={handleSortInput} />



              <br />
              <FormControl>
                <InputLabel id="game-list-select-label">綁定遊戲清單</InputLabel>
                <Select
                  labelId="game-list-select-label"
                  id="game-list-select"
                  value={selectedGameList || ''}
                  label="遊戲清單"
                  onChange={handleSelectGameList}
                >
                  <MenuItem value="">
                    <em>無</em>
                  </MenuItem>
                  {gameLists.map((game) => (
                    <MenuItem key={game.id} value={game.id}>
                      {game.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </div>
          </div>
          <div className="close-icon" onClick={() => onClose()} />

          <br />
          <div className="groups-modal-btn-wrap">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              儲存
            </Button>
            {!item.isAddNew && (
              <Button
                // className="delete-btn"
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isLoading}
              >
                刪除
              </Button>
            )}
          </div>
        </GroupsContent>
      )}
    </BaseModal>
  );
};

export default GroupsModal;

const GroupsContent = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background: white;
  height: 50vh;
  width: 70%;

  .groups-modal-head {
    display: flex;
    align-items: center;
    width: 80%;
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      width: 100%;
      > input {
        width: 100%;
        padding-left: 10px;
      }
      > span:nth-child(odd) {
        margin-top: 20px;
      }
    }
  }

  .groups-modal-upload-warp {
    margin: 0px 20px 10px 0px;

    .groups-modal-upload-image {
      width: 150px;
      height: 150px;
      > div {
        height: 100%;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    mask-image: url("/images/shared/close.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    min-width: 18px;
    min-height: 18px;
    background: black;
  }

  .groups-modal-btn-wrap {
    display: flex;
    margin-bottom: 5px;
    gap: 50px;
    .delete-btn {
      color: red;
    }
  }
`;
