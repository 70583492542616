import { APIS, IMAGE_DOMAIN } from "@constants/api";
import { generalFetch } from "@utils/generalFetch";

// import { objectToQueryString } from "@utils/util";

export const getMarquee = (language) => {
  return generalFetch(`${APIS.MARQUEE}?language=${language}`, "GET");
};
export const postMarquee = (req) => {
  return generalFetch(APIS.MARQUEE, "POST", req);
};
export const putMarquee = (req) => {
  return generalFetch(`${APIS.MARQUEE}/${req.id}`, "PATCH", req);
};
export const copyMarquee = (req) => {
  const { id, toLanguage } = req;
  return generalFetch(
    `${APIS.MARQUEE_COPY}?id=${id}&language=${toLanguage}`,
    "GET"
  );
};

export const getBanners = (language) => {
  return generalFetch(`${APIS.BANNERS}?language=${language}`, "GET");
};
export const postBanners = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(APIS.BANNERS, "POST", form, true);
};
export const putBanners = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(`${APIS.BANNERS}/${req.ID}`, "PATCH", form, true);
};
export const deleteBanners = (id) => {
  return generalFetch(`${APIS.BANNERS}/${id}`, "DELETE");
};
export const copyallBanners = (req) => {
  const { fromLanguage, toLanguage } = req;
  return generalFetch(
    `${APIS.BANNERS_COPYALL}?fromLanguage=${fromLanguage}&toLanguage=${toLanguage}`,
    "GET"
  );
};

export const getAnnouncement = (language) => {
  return generalFetch(`${APIS.ANNOUNCES}?language=${language}`, "GET");
};
export const postAnnouncement = (req) => {
  return generalFetch(APIS.ANNOUNCES, "POST", req);
};
export const putAnnouncement = (req) => {
  return generalFetch(`${APIS.ANNOUNCES}/${req.id}`, "PATCH", req);
};
export const copyAnnouncement = (req) => {
  const { id, toLanguage } = req;
  return generalFetch(
    `${APIS.Announces_COPY}?id=${id}&language=${toLanguage}`,
    "GET"
  );
};

export const getNews = (language) => {
  return generalFetch(`${APIS.NEWS}?language=${language}`, "GET");
};
export const postNews = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(APIS.NEWS, "POST", form, true);
};
export const putNews = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(`${APIS.NEWS}/${req.ID}`, "PATCH", form, true);
};
export const deleteNews = (id) => {
  return generalFetch(`${APIS.NEWS}/${id}`, "DELETE");
};
export const copyallNews = (req) => {
  const { fromLanguage, toLanguage } = req;
  return generalFetch(
    `${APIS.NEWS_COPYALL}?fromLanguage=${fromLanguage}&toLanguage=${toLanguage}`,
    "GET"
  );
};

export const getMaintenances = (language) => {
  return generalFetch(`${APIS.MAINTENANCES}?language=${language}`, "GET");
};
export const postMaintenances = (req) => {
  return generalFetch(APIS.MAINTENANCES, "POST", req);
};
export const putMaintenances = (req) => {
  return generalFetch(`${APIS.MAINTENANCES}/${req.id}`, "PUT", req);
};

export const getGroups = (language) => {
  return generalFetch(`${APIS.GROUPS}?language=${language}`, "GET");
};
export const postGroups = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(APIS.GROUPS, "POST", form, true);
};
export const putGroups = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(`${APIS.GROUPS}/${req.ID}`, "PATCH", form, true);
};
export const deleteGroups = (id) => {
  return generalFetch(`${APIS.GROUPS}/${id}`, "DELETE");
};
export const copyallGroups = (req) => {
  const { fromLanguage, toLanguage } = req;
  return generalFetch(
    `${APIS.GROUPS_COPYALL}?fromLanguage=${fromLanguage}&toLanguage=${toLanguage}`,
    "GET"
  );
};

export const postSingleImage = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(
    `${APIS.LOGO_UPLOAD}?filename=${req.filename}`,
    "POST",
    form,
    true
  );
};

export const getThemeColors = () => {
  return generalFetch(APIS.LOGO_COLOR, "GET", null, false, { noJsonRes: true });
};
export const patchThemeColors = (req) => {
  return generalFetch(APIS.LOGO_COLOR, "PATCH", req, false, {
    noJsonRes: true,
  });
};

export const getUsers = () => {
  return generalFetch(APIS.USERS, "GET");
};
export const postUsers = (req) => {
  return generalFetch(APIS.AUTH_REGISTER, "POST", req);
};
export const putUser = (req) => {
  return generalFetch(`${APIS.USERS}/${req.id}`, "PATCH", req);
};
export const deleteUser = (id) => {
  return generalFetch(`${APIS.USERS}/${id}`, "DELETE");
};

export const login = (req) => {
  return generalFetch(APIS.AUTH_LOGIN, "POST", req);
};
export const logout = () => {
  return generalFetch(APIS.AUTH_LOGOUT, "POST");
};

export const getDPolicy = (language) => {
  return generalFetch(`${APIS.DEPOSIT_POLICY}?language=${language}`, "GET");
};
export const postDPolicy = (req) => {
  return generalFetch(APIS.DEPOSIT_POLICY, "POST", req);
};
export const putDPolicy = (req) => {
  return generalFetch(`${APIS.DEPOSIT_POLICY}/${req.id}`, "PATCH", req);
};
export const copyDPolicy = (req) => {
  const { id, toLanguage } = req;
  return generalFetch(
    `${APIS.DEPOSIT_POLICY_COPY}?id=${id}&language=${toLanguage}`,
    "GET"
  );
};

export const getWPolicy = (language) => {
  return generalFetch(`${APIS.WITHDRAWAL_POLICY}?language=${language}`, "GET");
};
export const postWPolicy = (req) => {
  return generalFetch(APIS.WITHDRAWAL_POLICY, "POST", req);
};
export const putWPolicy = (req) => {
  return generalFetch(`${APIS.WITHDRAWAL_POLICY}/${req.id}`, "PATCH", req);
};
export const copyWPolicy = (req) => {
  const { id, toLanguage } = req;
  return generalFetch(
    `${APIS.WITHDRAWAL_POLICY_COPY}?id=${id}&language=${toLanguage}`,
    "GET"
  );
};

export const getPermissions = (req) => {
  return generalFetch(`${APIS.PERMISSIONS}/${req.id}`, "GET");
};

export const putPermissions = (req) => {
  return generalFetch(`${APIS.PERMISSIONS}/${req.id}`, "PUT", req.permissions);
};

export const getSystemMetas = () => {
  return generalFetch(`${APIS.SYSTEM_METAS}`, "GET");
};

export const patchSystemMetas = (req) => {
  return generalFetch(`${APIS.SYSTEM_METAS}/${req.id}`, "PATCH", req.meta);
};

export const putSystemMetas = (req) => {
  const array = [];
  for (const key in req.metas) {
    const element = req.metas[key];
    if (typeof element === "boolean") {
      array.push({ ID: key, Value: element ? "1" : "0" });
    } else if (typeof element === "object") {
      array.push({ ID: key, Value: JSON.stringify(element) });
    } else {
      array.push({ ID: key, Value: element });
    }
  }
  return generalFetch(`${APIS.SYSTEM_METAS}`, "PUT", array);
};

export const getBankOutList = () => {
  return generalFetch(`${APIS.GET_BANK_OUT_LIST}`, "GET", null, false, { noHeader: true });
};

export const getGameLists = (includeGameDetails) => {
  return generalFetch(`${APIS.GAMELISTS}?includeGameDetails=${includeGameDetails}`, "GET");
};
export const postGameList = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else if (e === 'GameListItems') {
      for (let i = 0; i < req[e].length; i++) {
        form.append(`GameListItems[${i}]`, req[e][i]);
      }
    } else {
      form.append(e, req[e]);
    }
  });
  return generalFetch(APIS.GAMELISTS, "POST", form, true);
};
export const putGameList = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "Image") {
      req[e].forEach((file) => {
        form.append("Image", file);
      });
    } else if (e === 'GameListItems') {
      for (let i = 0; i < req[e].length; i++) {
        form.append(`GameListItems[${i}]`, req[e][i]);
      }
    } else {
      form.append(e, req[e]);
    }
  });
  return generalFetch(`${APIS.GAMELISTS}/${req.ID}`, "PATCH", form, true);
};
export const deleteGameList = (id) => {
  return generalFetch(`${APIS.GAMELISTS}/${id}`, "DELETE");
};

export const getGames = () => {
  return generalFetch(`${APIS.GAMES_FOR_AMDIN}`, "GET");
};
export const postGame = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "ImageCH") {
      req[e].forEach((file) => {
        form.append("ImageCH", file);
      });
    } else if (e === "ImageEN") {
      req[e].forEach((file) => {
        form.append("ImageEN", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(APIS.GAMES, "POST", form, true);
};
export const putGame = (req) => {
  const form = new FormData();
  Object.keys(req).forEach((e) => {
    if (e === "ImageCH") {
      req[e].forEach((file) => {
        form.append("ImageCH", file);
      });
    } else if (e === "ImageEN") {
      req[e].forEach((file) => {
        form.append("ImageEN", file);
      });
    } else form.append(e, req[e]);
  });
  return generalFetch(`${APIS.GAMES}/${req.ID}`, "PATCH", form, true);
};
export const deleteGame = (id) => {
  return generalFetch(`${APIS.GAMES}/${id}`, "DELETE");
};

export const exportGameLists = () => {
  return generalFetch(`${APIS.GAMELIST_EXPORT}?hostName=${IMAGE_DOMAIN}`, "GET", null, true, { noJsonRes: true });
};

export const importGameLists = (file) => {
  const form = new FormData();
  form.append('file', file);
  return generalFetch(APIS.GAMELIST_IMPORT, "POST", form, true);
};