import {
  IS_AW_KH_STG,
  IS_AW_MM_STG,
  IS_AW_VN_STG,
  IS_AW_BD_STG,
  IS_AW_LA_STG,
  IS_AW_KE_STG,
  IS_AW_NL_STG,
  IS_AW_KH_PROD,
  IS_AW_MM_PROD,
  IS_AW_VN_PROD,
  IS_AW_BD_PROD,
  IS_AW_LA_PROD,
  IS_AW_KE_PROD,
  IS_AW_NL_PROD,
  IS_GW_STG,
  IS_GW_PORD,
  IS_PECH_PROD,
  IS_PECH_STG,
  IS_BITSBET_PROD,
  IS_BITSBET_STG,
  IS_LOCALHOST
} from "./config";

const IS_HTTP = window.location.protocol === "http:";

let API_DOMAIN = "https://api-stg.gw123.net/api";
let IMAGE_DOMAIN = "https://api-stg.gw123.net";
let IGP_API_DOMAIN = 'https://m1.igp.gs/api';

const protocol = IS_HTTP ? "https" : "https";
if (IS_GW_PORD) {
  API_DOMAIN = `${protocol}://api.gw123.net/api`;
  IMAGE_DOMAIN = `${protocol}://api.gw123.net`;
  IGP_API_DOMAIN = 'https://my.igp.gs/api/';
} else if (IS_GW_STG) {
  API_DOMAIN = `${protocol}://api-stg.gw123.net/api`;
  IMAGE_DOMAIN = `${protocol}://api-stg.gw123.net`;
  IGP_API_DOMAIN = 'https://m1.igp.gs/api';
} else if (IS_AW_KH_PROD) {
  API_DOMAIN = `${protocol}://kh-api.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://kh-api.aw123.cc`;
  IGP_API_DOMAIN = 'https://kha.igp.gs/api';
} else if (IS_AW_MM_PROD) {
  API_DOMAIN = `${protocol}://mm-api.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://mm-api.aw123.cc`;
  IGP_API_DOMAIN = 'https://mma.igp.gs/api';
} else if (IS_AW_VN_PROD) {
  API_DOMAIN = `${protocol}://vn-api.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://vn-api.aw123.cc`;
  IGP_API_DOMAIN = 'https://vna.igp.gs/api';
} else if (IS_PECH_PROD) {
  API_DOMAIN = `${protocol}://api.pech.mobi/api`;
  IMAGE_DOMAIN = `${protocol}://api.pech.mobi`;
  IGP_API_DOMAIN = 'https://kha.igp.gs/api';
} else if (IS_AW_BD_PROD) {
  API_DOMAIN = `${protocol}://bd-api.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://bd-api.aw123.cc`;
  IGP_API_DOMAIN = 'https://bda.igp.gs/api';
} else if (IS_AW_KE_PROD || IS_BITSBET_PROD) {
  API_DOMAIN = `${protocol}://ke-api.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://ke-api.aw123.cc`;
  IGP_API_DOMAIN = 'https://kea.igp.gs/api';
} else if (IS_AW_LA_PROD) {
  API_DOMAIN = `${protocol}://la-api.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://la-api.aw123.cc`;
  IGP_API_DOMAIN = 'https://laa.igp.gs/api';
} else if (IS_AW_NL_PROD) {
  API_DOMAIN = `${protocol}://nl-api.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://nl-api.aw123.cc`;
  IGP_API_DOMAIN = 'https://nla.igp.gs/api';
} else if (IS_AW_KH_STG) {
  API_DOMAIN = `${protocol}://kh-api-stg.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://kh-api-stg.aw123.cc`;
  IGP_API_DOMAIN = 'https://k2.igp.gs/api';
} else if (IS_AW_MM_STG) {
  API_DOMAIN = `${protocol}://mm-api-stg.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://mm-api-stg.aw123.cc`;
  IGP_API_DOMAIN = 'https://m2.igp.gs/api';
} else if (IS_AW_VN_STG) {
  API_DOMAIN = `${protocol}://vn-api-stg.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://vn-api-stg.aw123.cc`;
  IGP_API_DOMAIN = 'https://v2.igp.gs/api';
} else if (IS_PECH_STG) {
  API_DOMAIN = `${protocol}://api-stg.pech.mobi/api`;
  IMAGE_DOMAIN = `${protocol}://api-stg.pech.mobi`;
  IGP_API_DOMAIN = 'https://k2.igp.gs/api';
} else if (IS_AW_BD_STG) {
  API_DOMAIN = `${protocol}://bd-api-stg.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://bd-api-stg.aw123.cc`;
  IGP_API_DOMAIN = 'https://b1.igp.gs/api';
} else if (IS_AW_KE_STG || IS_BITSBET_STG) {
  API_DOMAIN = `${protocol}://ke-api-stg.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://ke-api-stg.aw123.cc`;
  IGP_API_DOMAIN = 'https://k1.igp.gs/api';
} else if (IS_AW_LA_STG) {
  API_DOMAIN = `${protocol}://la-api-stg.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://la-api-stg.aw123.cc`;
  IGP_API_DOMAIN = 'https://l1.igp.gs/api';
} else if (IS_AW_NL_STG) {
  API_DOMAIN = `${protocol}://nl-api-stg.aw123.cc/api`;
  IMAGE_DOMAIN = `${protocol}://nl-api-stg.aw123.cc`;
  IGP_API_DOMAIN = 'https://n1.igp.gs/api';
} else if (IS_LOCALHOST) {
  API_DOMAIN = `http://localhost:5141/api`;
  IMAGE_DOMAIN = `http://localhost:5141`;
  IGP_API_DOMAIN = 'https://v2.igp.gs/api';
}
window.API_DOMAIN = API_DOMAIN;

export { API_DOMAIN, IMAGE_DOMAIN, IGP_API_DOMAIN };

export const APIS = {
  MARQUEE: `${API_DOMAIN}/Marquee`,
  MARQUEE_COPY: `${API_DOMAIN}/Marquee/copy`,
  BANNERS: `${API_DOMAIN}/Banners`,
  BANNERS_COPYALL: `${API_DOMAIN}/Banners/copyall`,
  ANNOUNCES: `${API_DOMAIN}/Announces`,
  Announces_COPY: `${API_DOMAIN}/Announces/copy`,
  NEWS: `${API_DOMAIN}/News`,
  NEWS_COPYALL: `${API_DOMAIN}/News/copyall`,
  GROUPS: `${API_DOMAIN}/Groups`,
  GROUPS_COPYALL: `${API_DOMAIN}/Groups/copyall`,
  MAINTENANCES: `${API_DOMAIN}/Maintenances`,
  LOGO_UPLOAD: `${API_DOMAIN}/Logo/upload`,
  LOGO_COLOR: `${API_DOMAIN}/Logo/color`,
  USERS: `${API_DOMAIN}/Users`,
  AUTH_REGISTER: `${API_DOMAIN}/Auth/register`,
  AUTH_LOGIN: `${API_DOMAIN}/Auth/login`,
  AUTH_LOGOUT: `${API_DOMAIN}/Auth/logout`,
  DEPOSIT_POLICY: `${API_DOMAIN}/DepositPolicy`,
  WITHDRAWAL_POLICY: `${API_DOMAIN}/WithdrawPolicy`,
  DEPOSIT_POLICY_COPY: `${API_DOMAIN}/DepositPolicy/copy`,
  WITHDRAWAL_POLICY_COPY: `${API_DOMAIN}/WithdrawPolicy/copy`,
  PERMISSIONS: `${API_DOMAIN}/permissions`,
  SYSTEM_METAS: `${API_DOMAIN}/SystemMetas`,

  HTML_EDITOR: {
    ANNOUNCES: `${API_DOMAIN}/Announces/upload`,
    NEWS: `${API_DOMAIN}/News/upload`,
    DEPOSIT_POLICY: `${API_DOMAIN}/DepositPolicy/upload`,
    WITHDRAWAL_POLICY: `${API_DOMAIN}/WithdrawPolicy/upload`,
  },
  GET_BANK_OUT_LIST: `${IGP_API_DOMAIN}/getBankOutList.ashx`,

  GAMELISTS: `${API_DOMAIN}/GameLists`,
  GAMES: `${API_DOMAIN}/Games`,
  GAMES_FOR_AMDIN: `${API_DOMAIN}/Games/Admin`,

  GAMELIST_EXPORT: `${API_DOMAIN}/GameLists/export`,
  GAMELIST_IMPORT: `${API_DOMAIN}/GameLists/import`,

};

// export const STATUS_CODE = {
//     SUCCESS: 0
// }
