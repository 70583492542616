/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import BaseModal from "src/components/shared/modals/BaseModal";
import {
  FlexDirectionConfigs,
  HTML_EDITOR_INIT_CONFIG,
  TINY_EDITOR_KEY,
} from "@constants/config";
import { UploadImageWithTitle } from "@components/shared/ImageUploadZone";
import { APIS } from "@constants/api";
import {
  ACTIVE_TINYMCE_PLUGINS,
  ACTIVE_TINYMCE_TOOLBARS,
  LANG_SELECTOR_ITEMS
} from "@constants/config";
import {
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { IMAGE_DOMAIN } from "@constants/api";
import { useSelectLang } from "@hooks/useLang";
import { postBanners, putBanners, deleteBanners, getNews } from "@api/cms";

const BannerTypeMap = {
  none: 0,
  game: 1,
  link: 2,
  announce: 3,
  news: 4
};

const BannerTypeMapRev = ["none", "game", "link", "announce", "news"];

const BannerModal = (props) => {
  const { show, item, onClose } = props;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [link, setLink] = useState(null);
  const [gameCode, setGameCode] = useState(null);

  const [tempImageFiles, setTempImageFiles] = useState({}); //id: Files
  const [tempPreview, setTempPreview] = useState({}); //id: preview url
  const [isLoading, setIsLoading] = useState(false);
  const [bannerType, setBannerType] = useState("none");

  const [selectedNews, setSelectedNews] = useState('');
  const [newsList, setNewsList] = useState([]);
  const { lang } = useSelectLang();

  const editorRef = useRef(null);
  const langMap = LANG_SELECTOR_ITEMS.reduce((acc, curr) => { return { ...acc, [curr.value]: curr } }, {});

  useEffect(() => {
    if (!show) {
      setTitle("");
      setContent("");
      setLink("");
      setGameCode("");
      setTempImageFiles({});
      setTempPreview({});

      setBannerType("none");
    } else if (item && !item.isAddNew) {
      setTitle(item.title);
      setContent(item.content || "");
      setGameCode(item.gameCode || "");
      setLink(item.url || "");
      setBannerType(BannerTypeMapRev[item.type]);
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, [show]);

  useEffect(() => { }, []);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const setTempImage = (id, file, preview) => {
    if (file && preview) {
      setTempImageFiles({ ...tempImageFiles, [id]: file });
      setTempPreview({ ...tempPreview, [id]: preview });
    }
  };

  const handleDeleteImage = async (id) => {
    if (tempImageFiles.hasOwnProperty(id)) {
      let newTempFile = { ...tempImageFiles };
      let newTempPreview = { ...tempPreview };
      delete newTempFile[id];
      delete newTempPreview[id];
      setTempImageFiles({ ...newTempFile });
      setTempPreview({ ...newTempPreview });
    }
  };
  const handleSubmit = async () => {
    if (bannerType === "announce" && !editorRef.current) {
      toast.error("抓不到Tiny Editor，請重新整理並在嘗試一次。");
      return;
    }

    if (bannerType === "news" && !selectedNews) {
      toast.error("無已綁定的News，請選擇News綁定。");
      return;
    }

    setIsLoading(true);
    let request = {
      ID: item.isAddNew ? 0 : item.id,
      Title: title,
      Content: editorRef.current ? editorRef.current.getContent() : "",
      Sort: 1,
      Language: item.language,
      Type: BannerTypeMap[bannerType],
      Url: link || "",
      GameCode: gameCode || "",
    };
    if (tempImageFiles[item.id]) {
      request.Image = tempImageFiles[item.id];
    } else {
      request.ImagePath = item.imagePath;
    }

    if (bannerType === 'news') {
      request.NewsID = selectedNews.id;
    }

    let res;

    if (item.isAddNew) res = await postBanners(request);
    else res = await putBanners(request);

    if (res) {
      toast.success("修改成功");
      onClose(true);
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    let res = await deleteBanners(item.id);
    try {
      if (JSON.stringify(res) === "{}") {
        toast.success("修改成功");
        onClose(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    toast.error("修改失敗");
    setIsLoading(false);
  };

  const handleTextChange = (event) => {
    if (event.target.name === "link") {
      setLink(event.target.value);
    } else if (event.target.name === "game") {
      setGameCode(event.target.value);
    }
  };

  const handleChange = (event) => {
    setBannerType(event.target.value);
  };
  const getData = async () => {
    const res = await getNews(lang);
    const tNewsList = res
      ? res.map((r) => {
        return { ...r, imgUrl: `${IMAGE_DOMAIN}/${r.imagePath}` };
      })
      : [];
    setNewsList(
      tNewsList
    );
    let tSelectedNews;
    if (tNewsList.length > 0 && item && item.newsID) {
      const news = tNewsList.find((newsItem) => newsItem.id === item.newsID);
      if (news) {
        // setSelectedNews(news);
        tSelectedNews = news;
      }
      // Use the 'news' variable as needed
    }


    if (tSelectedNews) {
      setSelectedNews(tSelectedNews);
    } else {
      const allNewsRes = await getNews('');
      const tAllNews = allNewsRes
        ? allNewsRes.map((r) => {
          return { ...r, imgUrl: `${IMAGE_DOMAIN}/${r.imagePath}`, origin: r.language };
        })
        : [];

      if (tAllNews.length > 0 && item && item.newsID) {

        const news = tAllNews.find((newsItem) => newsItem.id === item.newsID);
        if (news) {
          tSelectedNews = news;
          tNewsList.unshift(news);
          setNewsList(
            tNewsList
          );
          setSelectedNews(tSelectedNews);
        }
        // Use the 'news' variable as needed
      }
    }
  };

  useEffect(() => {
    getData();
  }, [lang, item]);

  const handleNewsItemChange = (e) => {
    setSelectedNews(e.target.value);
  }

  // const handleNewsItemCopy = (e) => {
  //   setContent(selectedNews.content || "");
  // }
  return (
    <BaseModal
      show={show}
      justifyContent={FlexDirectionConfigs.Center}
      alignItems={FlexDirectionConfigs.Center}
      handleOutsideClick={stopPropagation}
    >
      {item && (
        <BannerContent onClick={stopPropagation}>
          {item.isAddNew && <p>新增Banner</p>}
          <div className="new-modal-head">
            <UploadImageWithTitle
              title={`${item.imagePath?.split("/")[1] || ""}`}
              data={{ ...item, tempImageUrl: tempPreview[item.id] }}
              setTempImage={setTempImage}
              onDelete={handleDeleteImage}
              hideTrashCan={!tempPreview[item.id]}
            />
          </div>
          <div className="close-icon" onClick={() => onClose()} />
          <div className="modal-form">
            <Grid container>
              <Grid xs={12} mb={3}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    型態-點擊後動作
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={bannerType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="none"
                      control={<Radio />}
                      label="無"
                    />
                    <FormControlLabel
                      value="game"
                      control={<Radio />}
                      label="遊戲"
                    />
                    <FormControlLabel
                      value="link"
                      control={<Radio />}
                      label="連結"
                    />
                    <FormControlLabel
                      value="announce"
                      control={<Radio />}
                      label="公告"
                    />
                    <FormControlLabel
                      value="news"
                      control={<Radio />}
                      label="News內容"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {bannerType === "game" && (
                <Grid xs={12}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      遊戲ID
                    </FormLabel>
                    <TextField
                      required
                      variant="outlined"
                      name={`game`}
                      size="small"
                      value={gameCode}
                      onChange={handleTextChange}
                    />
                  </FormControl>
                </Grid>
              )}
              {bannerType === "link" && (
                <Grid xs={12}>
                  <FormControl fullWidth>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      連結
                    </FormLabel>
                    <TextField
                      required
                      variant="outlined"
                      name={`link`}
                      size="small"
                      value={link}
                      onChange={handleTextChange}
                    />
                  </FormControl>
                </Grid>
              )}
              {bannerType === 'news' && (
                <Grid xs={12}>
                  <FormControl sx={{ m: 1, minWidth: 300 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">綁定News內容</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={selectedNews}
                      onChange={handleNewsItemChange}
                      autoWidth
                      label="綁定News內容"
                    >
                      <MenuItem value="">
                        <em>請選擇</em>
                      </MenuItem>
                      {newsList.map(e => <MenuItem key={e.id} value={e}>{e.origin ? `原文[${langMap[e.origin].label}]:${e.title}` : e.title}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              )}

            </Grid>
          </div>
          {bannerType === "announce" && (
            <Grid xs={12}>

              <EditorContainer>



                <Editor
                  apiKey={TINY_EDITOR_KEY}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={content}
                  init={{
                    ...HTML_EDITOR_INIT_CONFIG,
                    images_upload_url: APIS.HTML_EDITOR.NEWS,
                    plugins: [
                      ...ACTIVE_TINYMCE_PLUGINS,
                      "video-upload",

                    ],
                    toolbar: ACTIVE_TINYMCE_TOOLBARS + "| video-upload",
                    external_plugins: {
                      "video-upload": "/tinymce-plugin/plugin.js",
                    },
                    media_live_embeds: true,
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === "media") {
                        callback("");
                      }
                    },
                    // min_height: "autoresize",
                  }}
                />
              </EditorContainer>
            </Grid>
          )}


          <br />
          <div className="new-modal-btn-wrap">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              儲存
            </Button>
            {!item.isAddNew && (
              <Button
                // className="delete-btn"
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isLoading}
              >
                刪除
              </Button>
            )}
          </div>
        </BannerContent>
      )}
    </BaseModal>
  );
};

export default BannerModal;

const EditorContainer = styled.div`
  padding: 16px;
  margin: 16px;
`;

const BannerContent = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background: white;
  height: 90vh;
  width: 70%;
  overflow-y: scroll;

  .modal-form {
    width: 80%;
    
  }

  .new-modal-head {
    display: flex;
    align-items: center;
    width: 80%;
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      width: 100%;
      > input {
        width: 100%;
      }
    }
  }

  .news-modal-upload-warp {
    margin: 0px 20px 10px 0px;

    .news-modal-upload-image {
      width: 130px;
      height: 250px;
      > div {
        height: 100%;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    mask-image: url("/images/shared/close.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    min-width: 18px;
    min-height: 18px;
    background: black;
  }

  .new-modal-btn-wrap {
    display: flex;
    margin-bottom: 5px;
    gap: 50px;
    .delete-btn {
      color: red;
    }
  }
`;
