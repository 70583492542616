import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { usePanelTheme } from "@hooks/usePanelTheme";
import { toast } from "react-toastify";
import styled from "styled-components";
import { IMAGE_DOMAIN } from '@constants/api';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

export default function GameListTransfer(props) {
    const { theme } = usePanelTheme(); // Assumed custom hook

    const { optionKey, list, onChange, fullList, maxRightItems = 0 } = props;

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {

        if (maxRightItems > 0 && right.length >= maxRightItems) {
            toast.error(`進階型態不可以選擇超過${maxRightItems}項遊戲`);
            return;
        }

        const newRight = right.concat(checked.filter(item => left.includes(item)));
        if (maxRightItems > 0 && newRight.length > maxRightItems) {
            toast.error(`加入這些遊戲將會超過數量限制(${maxRightItems}個)`);

            return;
        }

        setRight(newRight);
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.source.droppableId === 'droppable-right') {
            const items = reorder(
                right,
                result.source.index,
                result.destination.index
            );

            setRight(items);
        }
    };


    const customList = (title, items, listId, isDraggable = false) => {
        const droppableId = title === '顯示遊戲項目' ? 'droppable-right' : 'droppable-left';
        return <Card elevation={3} sx={{ borderColor: 'grey', borderWidth: 1, borderStyle: 'solid' }}>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} 已選擇`}
            />
            <Divider />
            <Droppable droppableId={droppableId} isDropDisabled={!isDraggable}>
                {(provided) => (<List
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{
                        width: 300,
                        height: 420,
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                    }}
                    dense
                    component="div"
                    role="list"
                >
                    {items.map((value, index) => {
                        const labelId = `transfer-list-all-item-${value.id}-label`;
                        const draggableId = `draggable-${value.id}`;
                        return (
                            <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={!isDraggable}>
                                {(provided) => (
                                    <ListItem
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={value.id}
                                        role="listitem"
                                        button
                                        onClick={handleToggle(value)}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={checked.indexOf(value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </ListItemIcon>
                                        <GameContainer id={labelId} >
                                            <img src={`${IMAGE_DOMAIN}/${value.imagePathEN}`} alt="English Icon" style={{ maxWidth: '50px', maxHeight: '50px', marginRight: '8px' }} />
                                            <div>
                                                <div>{`${value.source || '遊戲ID'}-${value.gameID}`}</div>
                                                <span>{`${value.nameCH}`}</span>
                                            </div>
                                        </GameContainer>
                                        {/* <ListItemText id={labelId} primary={`${value.nameCH}`}> <span>fdsafd</span> </ListItemText> */}
                                    </ListItem>)}
                            </Draggable>
                        );
                    })}
                    {provided.placeholder}
                </List>)}

            </Droppable>

        </Card>
    };
    useEffect(() => {

        setRight(list);

        const array = fullList.filter((val) => {
            return !list.find(e => e.id === val.id);
        })

        setLeft(array)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, fullList])


    // useEffect(() => {
    //     const array = fullList.filter((val) => {
    //         return !list.find(e => e.id === val.id) && !right.find(e => e.id === val.id);
    //     })

    //     setLeft(array)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fullList])

    const notifyChange = (newOptions) => {
        if (typeof onChange === "function") {
            onChange(optionKey, newOptions);
        }
    };

    useEffect(() => {
        notifyChange(right);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [right])


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2} justifyContent="start" alignItems="center">
                <Grid item>{customList('所有遊戲項目', left, 'left')}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{
                                my: 0.5, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', color: numberOfChecked(left) > 0 ? theme.admin_primaryButtonColor : ''
                            }}
                            variant="outlined"
                            size="large"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            <Icon>arrow_forward</Icon>
                        </Button>
                        <Button
                            sx={{
                                my: 0.5, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', color: numberOfChecked(right) > 0 ? theme.admin_primaryButtonColor : ''
                            }}
                            variant="outlined"
                            size="large"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            <Icon>arrow_backward</Icon>
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList('顯示遊戲項目', right, 'right', true)}</Grid>
            </Grid>
        </DragDropContext>
    );
}

const GameContainer = styled.div`

  display: flex;
  align-items: start;


`;
