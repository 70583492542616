/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';

import { Card, Icon, Tabs, Tab } from "@mui/material";
import GamesTab from '@components/games/GamesTab';
import { Popover, List, ListItemIcon, ListItem, ListItemText, CircularProgress } from "@mui/material";
import GameListTab from '@components/games/GameListTab';
import { exportGameLists, importGameLists } from '@api/cms';
import { toast } from 'react-toastify';


export default function GamesPage() {

    const [anchorEl, setAnchorEl] = useState(null);
    const [importLoading, setImportLoading] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);

    const fileInputRef = useRef(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    const [activeTab, setActiveTab] = useState(0);
    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };



    const handleImportGames = async () => {
        //importGameLists(file)
        fileInputRef.current.click();
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Assuming importGameLists is a function that handles the import logic
            try {
                setImportLoading(true);
                await importGameLists(file);
                toast.success("匯入成功");
                setRefreshCount((prev) => prev + 1)
                // Handle success scenario (e.g., show a notification to the user)
            } catch (error) {
                // Handle error scenario (e.g., show error message to the user)
                toast.error(error);

            }
            setImportLoading(false);


        }

        handleClose();
        // Reset the file input after handling the file
        event.target.value = null;
    }

    const handleExportGames = async () => {
        const blob = await exportGameLists();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'GameExport.xlsx'); // Create a link to download the file
        document.body.appendChild(link);
        link.click();
        link.remove();
        handleClose();

    }


    return (
        <Card
            elevation={3}
            className="w-full overflow-auto"
            style={{ padding: "24px" }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '10px', marginBottom: '10px' }}>

                <Tabs value={activeTab} onChange={handleChange} aria-label="game tabs">
                    <Tab label="遊戲清單" />
                    <Tab label="遊戲" />
                </Tabs>
                <Icon
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }} // Add cursor style to indicate it's clickable
                    aria-describedby={id}
                >
                    more_vert
                </Icon>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List component="nav" aria-label="import export actions">
                        <ListItem button onClick={handleImportGames}>
                            <ListItemIcon>
                                <Icon>upload</Icon>
                            </ListItemIcon>
                            <ListItemText primary="匯入遊戲及清單" />
                        </ListItem>
                        <ListItem button onClick={handleExportGames}>
                            <ListItemIcon>
                                <Icon>download</Icon>
                            </ListItemIcon>
                            <ListItemText primary="匯出遊戲及清單" />
                        </ListItem>
                    </List>
                </Popover>
            </div>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }} // Hide the file input
                accept=".xlsx" // Optionally, specify that you only accept Excel files
            />
            {importLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <CircularProgress />
                </div>
            )}

            {activeTab === 0 &&
                (<GameListTab needRefresh={refreshCount} />)
            }
            {activeTab === 1 &&
                (<GamesTab needRefresh={refreshCount} />)
            }
        </Card>
    );
}

