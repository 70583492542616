import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import BaseModal from "src/components/shared/modals/BaseModal";
import { FlexDirectionConfigs } from "@constants/config";
import { deleteGameList, postGameList, putGameList, getGames } from "@api/cms";
// import { NUMBER_REGEX } from "@utils/util";
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";
import GameListTransfer from "@components/shared/GameListTransfer";

const GameListModal = (props) => {
  const { show, item, onClose, allLists } = props;
  const [name, setName] = useState("");
  const [type, setType] = useState(0);
  const [games, setGames] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [initListItems, setInitListItems] = useState([]);
  const allGamesRef = useRef(null);
  // const [editingItem, setEditingItem] = useState({ name: '', type: 0, listItems: [] });
  const [openConfirmDialog, setOpenConfirmDialog] = useState({ display: false, value: 0 });

  const handleCloseDialog = () => {
    setOpenConfirmDialog((prev) => { return { ...prev, display: false } });
  };




  const handleSwitchType = (typeToChange) => {
    setType(typeToChange);
    setListItems([]);
    setInitListItems([]);
    setOpenConfirmDialog({ value: 0, display: false })
  };

  const [isLoading, setIsLoading] = useState(false);

  const initStates = () => {
    setName("");
    setType(0);
    setListItems([]);
    setInitListItems([]);

  };

  const init = async () => {
    if (!show) {
      initStates();
    } else if (item) {
      setName(item.name);
      setType(item.type || 0);

      if (item.listItems) {
        const theListItems = item.listItems.map(e => e.game)
        setListItems(theListItems)
        setInitListItems(theListItems);
      }
    }

    if (!allGamesRef.current) {
      const games = await getGames();
      allGamesRef.current = games;
    }


    if (type === 0 && allLists) {

      const gameListItems = allLists
        .filter((list) => {
          if (item && item.id) {
            return list.id !== item.id && list.type === 0;
          } else {
            return list.type === 0;
          }
        })
        .flatMap((list) => list.listItems.map((item) => item.game));



      const filteredGames = allGamesRef.current.filter((game) => !gameListItems.some((item) => item.id === game.id));

      setGames(filteredGames);

    } else {

      setGames(allGamesRef.current);
    }
    setIsLoading(false);

  }


  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [show]);


  useEffect(() => {


    if (!allGamesRef.current) return;


    if (type === 0 && allLists) {

      const gameListItems = allLists
        .filter((list) => {
          if (item && item.id) {
            return list.id !== item.id && list.type === 0;
          } else {
            return list.type === 0;
          }
        })
        .flatMap((list) => list.listItems.map((item) => item.game));



      const filteredGames = allGamesRef.current.filter((game) => !gameListItems.some((item) => item.id === game.id));

      setGames(filteredGames);

    } else {
      setGames(allGamesRef.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])


  const stopPropagation = (e) => {
    e.stopPropagation();
  };


  const handleSubmit = async () => {

    if (!name || name.trim() === "") {
      toast.error("名稱欄位不可為空");
      return; // Exit the function to prevent further execution
    }
    setIsLoading(true);

    const items = listItems.map((e) => e.id);

    let request = {
      ID: item.isAddNew ? 0 : item.id,
      Name: name,
      Type: type,
      GameListItems: items
    };

    let res;

    try {
      if (item.isAddNew) res = await postGameList(request);
      else res = await putGameList(request);
      if (res?.errors)
        try {
          toast.error(`${res?.status}. ${JSON.stringify(res?.errors)}`);

        } catch (error) {
          toast.error(`${res?.status}. ${res?.errors}`);

        }
      else if (res) {
        toast.success(item.isAddNew ? "新增成功" : "修改成功");
        onClose(true);
      }
    } catch (error) {
      toast.error(`${error}`);
    }


    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    let res = await deleteGameList(item.id);
    try {
      if (JSON.stringify(res) === "{}") {
        toast.success("修改成功");
        onClose(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    toast.error("修改失敗");
    setIsLoading(false);
  };

  const handleGameListChange = (key, newOption) => {

    setListItems(newOption);

  };
  return (
    <BaseModal
      show={show}
      justifyContent={FlexDirectionConfigs.Center}
      alignItems={FlexDirectionConfigs.Center}
      handleOutsideClick={stopPropagation}
    >
      {item && (
        <GroupsContent onClick={stopPropagation}>
          {item.isAddNew && <p>新增遊戲清單</p>}
          <div className="groups-modal-head">
            <div>
              <span>清單名稱</span>
              <input value={name} onChange={(e) => setName(e.target.value)} />

              <span>遊戲選擇過濾方式</span>
              <select value={type} onChange={(e) => {
                if (listItems.length > 0) {
                  setOpenConfirmDialog({ display: true, value: Number(e.target.value) });
                  return;
                }
                setType(Number(e.target.value))

              }}>
                <option value="0">一般</option>
                <option value="1">進階</option>
              </select>
            </div>

          </div>
          <div className="close-icon" onClick={() => onClose()} />

          <br />
          <div className="groups-modal-body-wrap">

            <GameListTransfer optionKey={'listItems'} fullList={games} list={initListItems} maxRightItems={0} onChange={handleGameListChange} />

          </div>

          <div className="groups-modal-btn-wrap">

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              儲存
            </Button>
            {!item.isAddNew && (
              <Button
                // className="delete-btn"
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isLoading}
              >
                刪除
              </Button>
            )}
          </div>
        </GroupsContent>
      )}
      <Dialog open={openConfirmDialog.display} onClose={handleCloseDialog}>
        <DialogTitle>型態切換</DialogTitle>
        <DialogContent>
          <DialogContentText>
            型態切換會清除目標清單, 請問是否要切換?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained"
            color="secondary">取消</Button>
          <Button onClick={() => { handleSwitchType(openConfirmDialog.value) }} variant="contained"
            color="error">切換</Button>
        </DialogActions>
      </Dialog>
    </BaseModal>
  );
};

export default GameListModal;

const GroupsContent = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 5px;
  background: white;
  max-width: 90%;
  max-height: 100vh;  // Sets a maximum height for the modal
  overflow-y: auto;   // Enables vertical scrolling if content overflows

  .groups-modal-head {
    display: flex;
    align-items: center;
    width: 80%;
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      width: 100%;
      > input {
        width: 100%;
        padding-left: 10px;
      }
      > span:nth-child(odd) {
        margin-top: 20px;
      }
    }
  }

  .groups-modal-body-wrap {
    margin: 20px;
  }


  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    mask-image: url("/images/shared/close.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    min-width: 18px;
    min-height: 18px;
    background: black;
  }

  .groups-modal-btn-wrap {
    display: flex;
    margin-bottom: 5px;
    gap: 50px;
    .delete-btn {
      color: red;
    }
  }
`;


