/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { copyMarquee, getMarquee, postMarquee, putMarquee } from '@api/cms';
import { useSelectLang } from '@hooks/useLang';
import { API_DATE_TIME_FORMAT, DISPLAY_MUI_DATE_TIME_FORMAT } from '@constants/config';
import QuicklySelectTimeRange from '@components/shared/QuicklySelectTimeRange';
import QuicklyCopySettings from '@components/shared/QuicklyCopySettings';
import {
    Grid,
    CircularProgress,
    FormControl,
    Box,
    Card,
    FormControlLabel,
    Radio,
    RadioGroup,
    Button,
    TextField,
    FormGroup,
    Icon
} from "@mui/material";
import {
    LoaderContainer,
    FormTitleTypography,
} from "@/styled_components/common";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";



export default function MarqueePage() {
    const { lang } = useSelectLang()
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [noEndDate, setNoEndDate] = useState(false);
    const [content, setContent] = useState('');
    const [marquee, setMarquee] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const initData = () => {
        setStartDate(moment());
        setNoEndDate(false);
        setEndDate(moment());
        setContent('')
    }

    const getData = async () => {
        const res = await getMarquee(lang);
        const firstMarquee = res?.[0]
        setMarquee(firstMarquee)
        if (firstMarquee) {
            if (firstMarquee?.startDate) setStartDate(moment(firstMarquee.startDate))
            setNoEndDate(!firstMarquee.isPeriod)
            if (firstMarquee.isPeriod && firstMarquee.endDate) setEndDate(moment(firstMarquee.endDate))
            setContent(firstMarquee.content || '')
        } else {
            initData()
        }
    }
    useEffect(() => {
        getData();
    }, [lang])

    const handleStartDateFromQuickButton = (d) => {
        const startDateMoment = moment(d);

        setStartDate(startDateMoment)
        setNoEndDate(false);
    }
    const handleEndDateRadio = (e) => {
        setNoEndDate(e.currentTarget.value === 'true')
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        const request = {
            id: marquee ? marquee.id : 0,
            content: content,
            startDate: moment(startDate).format(API_DATE_TIME_FORMAT),
            endDate: noEndDate ? null : moment(endDate).format(API_DATE_TIME_FORMAT),
            language: marquee ? marquee.language : lang,
            isPeriod: !noEndDate
        }

        let res;
        if (marquee === undefined) res = await postMarquee(request)
        else res = await putMarquee(request)

        if (res) {
            await getData()
            toast.success("修改成功")
        }
        setIsLoading(false)
    }

    const handleQuicklyCopySubmit = async (toLanguage) => {
        if (!marquee?.language || !toLanguage) {
            toast.error("請重新整理網頁後再嘗試")
        } else {
            const res = await copyMarquee({ id: marquee.id, toLanguage })
            if (res && res.id) toast.success(`複製到 ${toLanguage} 成功`)
        }
    }

    if (marquee === null)
        return (
            <LoaderContainer>
                <CircularProgress />
            </LoaderContainer>
        );

    return (
        <Card
            elevation={3}
            className="w-full overflow-auto"
            style={{ padding: "24px" }}
        >
            <QuicklyCopySettings onSubmit={handleQuicklyCopySubmit} />
            <QuicklySelectTimeRange setStartDate={handleStartDateFromQuickButton} setEndDate={setEndDate} />
            <Box mb={"16px"} display="flex">
                <FormTitleTypography>{"開始時間"}</FormTitleTypography>
                <FormControl style={{ maxWidth: "600px" }}>
                    <DateTimePicker
                        label="開始時間(年/月/日)"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        format={DISPLAY_MUI_DATE_TIME_FORMAT}
                    />
                </FormControl>
            </Box>
            <Box mb={"16px"} display="flex">
                <FormTitleTypography>{"結束時間"}</FormTitleTypography>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel
                            value="female"
                            control={
                                <Radio
                                    id="endDate"
                                    value={false}
                                    checked={noEndDate === false}
                                    onChange={handleEndDateRadio}
                                />
                            }
                            label="指定時間"
                        />
                        <DateTimePicker
                            label="結束時間(年/月/日)"
                            value={endDate}
                            onChange={(date) => setEndDate(date)}
                            format={DISPLAY_MUI_DATE_TIME_FORMAT}
                        />
                        <FormControlLabel
                            value="male"
                            control={
                                <Radio
                                    id="noEndDate"
                                    value={true}
                                    checked={noEndDate === true}
                                    onChange={handleEndDateRadio}
                                />
                            }
                            label="無結束日期"
                        />
                    </RadioGroup>
                </FormControl>
            </Box>

            <FormGroup>

                <TextField
                    id="standard-multiline-static"
                    label="跑馬燈內容"
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                    variant="outlined"
                    value={content} onChange={e => setContent(e.target.value)}
                />
            </FormGroup>
            <br />
            <Grid container justifyContent={"end"}>
                <Grid>
                    <Button
                        sx={{ mt: 8 }}
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        mt={10}
                    >
                        <Icon>save</Icon>&nbsp;
                        儲存
                    </Button>
                </Grid>
            </Grid>
        </Card>
    );
}