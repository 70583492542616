import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import BaseModal from "src/components/shared/modals/BaseModal";
import {
  FlexDirectionConfigs,
  HTML_EDITOR_INIT_CONFIG,
  TINY_EDITOR_KEY,
} from "@constants/config";
import { UploadImageWithTitle } from "@components/shared/ImageUploadZone";
import { deleteNews, postNews, putNews } from "@api/cms";
import { APIS } from "@constants/api";
import {
  ACTIVE_TINYMCE_PLUGINS,
  ACTIVE_TINYMCE_TOOLBARS,
} from "@constants/config";
import { Button } from "@mui/material";

const NewsModal = (props) => {
  const { show, item, onClose } = props;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tempImageFiles, setTempImageFiles] = useState({}); //id: Files
  const [tempPreview, setTempPreview] = useState({}); //id: preview url
  const [isLoading, setIsLoading] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    if (!show) {
      setTitle("");
      setContent("");
      setTempImageFiles({});
      setTempPreview({});
    } else if (item) {
      setTitle(item.title);
      setContent(item.content || "");
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, [show]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const setTempImage = (id, file, preview) => {
    if (file && preview) {
      setTempImageFiles({ ...tempImageFiles, [id]: file });
      setTempPreview({ ...tempPreview, [id]: preview });
    }
  };

  const handleDeleteImage = async (id) => {
    if (tempImageFiles.hasOwnProperty(id)) {
      let newTempFile = { ...tempImageFiles };
      let newTempPreview = { ...tempPreview };
      delete newTempFile[id];
      delete newTempPreview[id];
      setTempImageFiles({ ...newTempFile });
      setTempPreview({ ...newTempPreview });
    }
  };
  const handleSubmit = async () => {
    if (!editorRef.current) {
      toast.error("抓不到Tiny Editor，請重新整理並在嘗試一次。");
      return;
    }

    setIsLoading(true);
    let request = {
      ID: item.isAddNew ? 0 : item.id,
      Title: title,
      Content: editorRef.current.getContent(),
      Sort: 1,
      Language: item.language,
    };
    if (tempImageFiles[item.id]) {
      request.Image = tempImageFiles[item.id];
    } else {
      request.ImagePath = item.imagePath;
    }

    let res;
    if (item.isAddNew) res = await postNews(request);
    else res = await putNews(request);

    if (res) {
      toast.success("修改成功");
      onClose(true);
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    let res = await deleteNews(item.id);
    try {
      if (JSON.stringify(res) === "{}") {
        toast.success("修改成功");
        onClose(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    toast.error("修改失敗");
    setIsLoading(false);
  };

  return (
    <BaseModal
      show={show}
      justifyContent={FlexDirectionConfigs.Center}
      alignItems={FlexDirectionConfigs.Center}
      handleOutsideClick={stopPropagation}
    >
      {item && (
        <NewsContent onClick={stopPropagation}>
          {item.isAddNew && <p>新增News</p>}
          <div className="new-modal-head">
            <UploadImageWithTitle
              width="130px"
              classnames="news-modal-upload-warp"
              outerClassname="news-modal-upload-image"
              title={`${item.imagePath?.split("/")[1] || ""}`}
              data={{ ...item, tempImageUrl: tempPreview[item.id] }}
              setTempImage={setTempImage}
              onDelete={handleDeleteImage}
              hideTrashCan={!tempPreview[item.id]}
            />
            <div>
              <span>標題</span>
              <input value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
          </div>
          <div className="close-icon" onClick={() => onClose()} />

          <Editor
            apiKey={TINY_EDITOR_KEY}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={content}
            init={{
              ...HTML_EDITOR_INIT_CONFIG,
              images_upload_url: APIS.HTML_EDITOR.NEWS,
              plugins: [...ACTIVE_TINYMCE_PLUGINS, "video-upload"],
              toolbar: ACTIVE_TINYMCE_TOOLBARS + "| video-upload",
              external_plugins: { "video-upload": "/tinymce-plugin/plugin.js" },
              media_live_embeds: true,
              file_picker_callback(callback, value, meta) {
                console.log(" file picker  ");
                if (meta.filetype === "media") {
                  callback("");
                }
              },
            }}
          />
          <br />

          <div className="new-modal-btn-wrap">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              儲存
            </Button>
            {!item.isAddNew && (
              <Button
                // className="delete-btn"
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isLoading}
              >
                刪除
              </Button>
            )}
          </div>
        </NewsContent>
      )}
    </BaseModal>
  );
};

export default NewsModal;

const NewsContent = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  background: white;
  height: 90vh;
  width: 70%;

  .new-modal-head {
    display: flex;
    align-items: center;
    width: 80%;
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      width: 100%;
      > input {
        width: 100%;
      }
    }
  }

  .news-modal-upload-warp {
    margin: 0px 20px 10px 0px;

    .news-modal-upload-image {
      width: 130px;
      height: 250px;
      > div {
        height: 100%;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    mask-image: url("/images/shared/close.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    min-width: 18px;
    min-height: 18px;
    background: black;
  }

  .new-modal-btn-wrap {
    display: flex;
    margin-bottom: 5px;
    gap: 50px;
    .delete-btn {
      color: red;
    }
  }
`;
