/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import { useSelectLang } from "@hooks/useLang";
import {
  copyAnnouncement,
  getAnnouncement,
  postAnnouncement,
  putAnnouncement,
} from "@api/cms";
import {
  API_DATE_TIME_FORMAT,
  HTML_EDITOR_INIT_CONFIG,
  TINY_EDITOR_KEY,
  DISPLAY_MUI_DATE_TIME_FORMAT
} from "@constants/config";
import { toast } from "react-toastify";
import { Z_INDEXS } from "@constants/numbers";
import { APIS } from "@constants/api";
import QuicklySelectTimeRange from "@components/shared/QuicklySelectTimeRange";
import QuicklyCopySettings from "@components/shared/QuicklyCopySettings";
import {
  ACTIVE_TINYMCE_PLUGINS,
  ACTIVE_TINYMCE_TOOLBARS,
} from "@constants/config";
import {
  Grid,
  FormControl,
  Box,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  TextField,
  FormGroup,
  Icon
} from "@mui/material";
import {
  FormTitleTypography,
} from "@/styled_components/common";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function AnnouncementPage() {
  const { lang } = useSelectLang();
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [noEndDate, setNoEndDate] = useState(false);
  const [title, setTitle] = useState("");
  const [announcement, setAnnouncement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const editorRef = useRef(null);

  const initData = () => {
    setStartDate(moment());
    setNoEndDate(false);
    setEndDate(moment());
    setTitle("");
    if (editorRef.current) editorRef.current.setContent("");
  };
  const getData = async () => {
    const res = await getAnnouncement(lang);
    const firstAnnouncement = res?.[0];
    setAnnouncement(firstAnnouncement);
    if (firstAnnouncement) {
      setTitle(firstAnnouncement.title);
      setStartDate(moment(firstAnnouncement.startDate));
      setNoEndDate(firstAnnouncement.endDate === null);
      if (firstAnnouncement.isPeriod && firstAnnouncement.endDate)
        setEndDate(moment(firstAnnouncement.endDate));
      if (editorRef.current)
        editorRef.current.setContent(firstAnnouncement.content || "");
    } else {
      initData();
    }
  };
  useEffect(() => {
    getData();
  }, [lang]);

  const handleEndDateRadio = (e) => {
    setNoEndDate(e.currentTarget.value === "true");
  };

  const handleStartDateFromQuickButton = (d) => {
    const startDateMoment = moment(d);
    setStartDate(startDateMoment);
    setNoEndDate(false);
  };

  const handleSubmit = async () => {
    if (!editorRef.current) {
      toast.error("抓不到Tiny Editor，請重新整理並在嘗試一次。");
      return;
    }
    setIsLoading(true);
    const request = {
      id: announcement ? announcement.id : 0,
      title: title,
      content: editorRef.current.getContent(),
      sort: 1,
      language: announcement ? announcement.language : lang,
      startDate: moment(startDate).format(API_DATE_TIME_FORMAT),
      endDate: noEndDate ? null : moment(endDate).format(API_DATE_TIME_FORMAT),
      isPeriod: !noEndDate,
    };

    let res;
    if (announcement === undefined) res = await postAnnouncement(request);
    else res = await putAnnouncement(request);

    if (res) {
      await getData();
      toast.success("修改成功");
    }
    setIsLoading(false);
  };

  const handleQuicklyCopySubmit = async (toLanguage) => {
    if (!announcement?.language || !toLanguage) {
      toast.error("請重新整理網頁後再嘗試");
    } else {
      const res = await copyAnnouncement({ id: announcement.id, toLanguage });
      if (res && res.id) toast.success(`複製到 ${toLanguage} 成功`);
    }
  };

  return (
    <Card
      elevation={3}
      className="w-full overflow-auto"
      style={{ padding: "24px" }}
    >
      <AnnouncementPageWrap>
        <QuicklyCopySettings onSubmit={handleQuicklyCopySubmit} />
        <QuicklySelectTimeRange
          setStartDate={handleStartDateFromQuickButton}
          setEndDate={setEndDate}
        />

        {/* <div className="grid">
          <p>開始時間(年/月/日)</p>
          <div>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              locale={zhCN}
              dateFormat={DISPLAY_DATE_TIME_FORMAT}
            />
          </div>

          <p>結束時間(年/月/日)</p>
          <div>
            <input
              type="radio"
              id="endDate"
              value={false}
              checked={noEndDate === false}
              onChange={handleEndDateRadio}
            />
            <div className="date-picker-wrap" htmlFor="endDate">
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                locale={zhCN}
                dateFormat={DISPLAY_DATE_TIME_FORMAT}
                disabled={noEndDate}
              />
            </div>
            <input
              type="radio"
              id="noEndDate"
              value={true}
              checked={noEndDate === true}
              onChange={handleEndDateRadio}
            />
            <label htmlFor="noEndDate">無結束日期</label>
          </div>

          <p>公告標題</p>
          <input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div> */}

        <Box mb={"16px"} display="flex">
          <FormTitleTypography>{"開始時間"}</FormTitleTypography>
          <FormControl style={{ maxWidth: "600px" }}>
            <DateTimePicker
              label="開始時間(年/月/日)"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              format={DISPLAY_MUI_DATE_TIME_FORMAT}
            />
          </FormControl>
        </Box>
        <Box mb={"16px"} display="flex">
          <FormTitleTypography>{"結束時間"}</FormTitleTypography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="female"
                control={
                  <Radio
                    id="endDate"
                    value={false}
                    checked={noEndDate === false}
                    onChange={handleEndDateRadio}
                  />
                }
                label="指定時間"
              />
              <DateTimePicker
                label="結束時間(年/月/日)"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                format={DISPLAY_MUI_DATE_TIME_FORMAT}
              />
              <FormControlLabel
                value="male"
                control={
                  <Radio
                    id="noEndDate"
                    value={true}
                    checked={noEndDate === true}
                    onChange={handleEndDateRadio}
                  />
                }
                label="無結束日期"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <br />
        <FormGroup>
          {/* <FormTitleTypography>{"公告標題"}</FormTitleTypography> */}
          <TextField
            id="standard-multiline-static"
            label="公告標題"
            defaultValue="Default Value"
            variant="outlined"
            value={title} onChange={(e) => setTitle(e.target.value)} />
        </FormGroup>

        <p>公告內容</p>
        <Editor
          apiKey={TINY_EDITOR_KEY}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={announcement ? announcement.content : ""}
          init={{
            ...HTML_EDITOR_INIT_CONFIG,
            images_upload_url: APIS.HTML_EDITOR.ANNOUNCES,
            plugins: [...ACTIVE_TINYMCE_PLUGINS, "video-upload"],
            toolbar: ACTIVE_TINYMCE_TOOLBARS + "| video-upload",
            external_plugins: { "video-upload": "/tinymce-plugin/plugin.js" },
            media_live_embeds: true,
            file_picker_callback(callback, value, meta) {
              console.log(" file picker  ");
              if (meta.filetype === "media") {
                callback("");
              }
            },
          }}
        />

        <br />
        <Grid container justifyContent={"end"}>
          <Grid>
            <Button
              sx={{ mt: 8 }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
              mt={10}
            >
              <Icon>save</Icon>&nbsp;
              儲存
            </Button>
          </Grid>
        </Grid>
      </AnnouncementPageWrap>
    </Card>
  );
}

const AnnouncementPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 10px;
    width: 80%;
    z-index: ${Z_INDEXS.ANNOUNCEMENT_GRID};

    > div {
      margin: auto 0px;
    }
    .date-picker-wrap {
      display: inline-block;
    }
  }
`;
