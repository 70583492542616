import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import BaseModal from "src/components/shared/modals/BaseModal";
import { FlexDirectionConfigs } from "@constants/config";
import { deleteUser, postUsers, putPermissions, putUser } from "@api/cms";
import { getPermissions } from "@api/cms";
import { Button } from "@mui/material";

const INIT_INFO = {
  email: "",
  password: "",
  phone: "",
  remark: "",
};
const UserModal = (props) => {
  const { show, item, onClose } = props;
  const [info, setInfo] = useState({ ...INIT_INFO });
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState({
    announcement: false,
    banner: false,
    policy: false,
    groups: false,
    logos: false,
    marquee: false,
    news: false,
    theme: false,
    users: false,
    maintenances: false,
    system: false,
  });

  const permissionOrderedList = [
    "marquee",
    "logos",

    "banner",
    "theme",

    "announcement",
    "maintenances",

    "news",
    "users",

    "groups",
    "games",
    "system",

    "policy",

    'adminPanel'
  ];

  const permissionNameMap = {
    announcement: "公告",
    banner: "橫幅",
    policy: "存款/提款 Policy",
    groups: "分類",
    games: "遊戲",
    logos: "Logos",
    marquee: "跑馬燈",
    news: "News",
    theme: "主題色",
    users: "後台使用者",
    maintenances: "維護",
    withdrawpolicy: "提款Policy",
    depositpolicy: "存款Policy",
    system: "前台設定",
    adminPanel: "後台設定",

  };

  useEffect(() => {
    if (!show) {
      setInfo({ ...INIT_INFO });
    } else if (item) {
      setInfo({ ...INIT_INFO, ...item });
    }
    setIsLoading(false);
    // eslint-disable-next-line
  }, [show]);

  const getData = async () => {
    let res = await getPermissions({ id: item.id });
    if (res) {
      delete res["withdrawpolicy"];
      delete res["depositpolicy"];

      setPermissions(res);
    }
  };
  useEffect(() => {
    if (item) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  const handleInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    setInfo((prev) => {
      return { ...prev, [name]: target.value };
    });
  };
  const handlePermissionsChange = (e) => {
    const { name, checked } = e.target;
    setPermissions((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const isValidEmail = (email) => {
    // This regex pattern matches most common email formats
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return pattern.test(email);
  };

  const handleSubmit = async () => {
    const { email, password, phone, remark } = info;
    if (!email) {
      toast.warn("請輸入帳號");
      return;
    }
    if (!email || !isValidEmail(email)) {
      toast.warn("請輸入有效的帳號 (email)");
      return;
    }
    if (item.isAddNew && !password) {
      toast.warn("請輸入密碼");
      return;
    }
    setIsLoading(true);

    const request = {
      email,
      password,
      phone,
      remark,
      id: item.isAddNew ? -1 : item.id,
    };
    let res;

    try {
      if (item.isAddNew) {
        res = await postUsers(request);
      } else {
        res = await putUser(request);
      }

      const userId = item.isAddNew ? res.userId : item.id;
      await putPermissions({
        id: userId,
        permissions: permissions,
      });

      if (res) {
        toast.success("修改成功");
        onClose(true);
      }
    } catch (error) {
      console.loe(" save user error ->  ", error);
    }

    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    let res = await deleteUser(item.id);
    try {
      if (JSON.stringify(res) === "{}") {
        toast.success("修改成功");
        onClose(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
    toast.error("修改失敗");
    setIsLoading(false);
  };

  return (
    <BaseModal
      show={show}
      justifyContent={FlexDirectionConfigs.Center}
      alignItems={FlexDirectionConfigs.Center}
      handleOutsideClick={stopPropagation}
    >
      {item && (
        <UserContent onClick={stopPropagation}>
          {item.isAddNew ? (
            <h3>新增User</h3>
          ) : (
            <>
              <h3>修改User</h3>
              <p>
                密碼空值則代表密碼不變
              </p>
            </>
          )}
          <div className="user-modal-head">
            <label>
              {" "}
              帳號：
              <input
                name="email"
                value={info?.email}
                disabled={!item.isAddNew}
                onChange={handleInputChange}
              />
            </label>
            <label>
              {" "}
              密碼：
              <input
                name="password"
                value={info?.passowrd}
                onChange={handleInputChange}
              />
            </label>
            <label>
              {" "}
              手機：
              <input
                name="phone"
                value={info?.phone}
                onChange={handleInputChange}
              />
            </label>
            <label>
              {" "}
              備註：
              <input
                name="remark"
                value={info?.remark}
                onChange={handleInputChange}
              />
            </label>
            <UserPermissionWrap>
              <h3>管理用戶權限</h3>
              <form onSubmit={handleSubmit}>
                <div className="permissions-container">
                  {permissionOrderedList.map((e) => (
                    <div className="permission-item" key={e}>
                      <input
                        type="checkbox"
                        id={e}
                        name={e}
                        checked={permissions[e]}
                        onChange={handlePermissionsChange}
                      />
                      <label htmlFor={e}>{permissionNameMap[e] ?? e}</label>
                    </div>
                  ))}
                </div>
                {/* <button type="submit">保存設定</button> */}
              </form>
            </UserPermissionWrap>
          </div>
          <div className="close-icon" onClick={() => onClose()} />
          <br />
          <div className="user-modal-btn-wrap">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              儲存
            </Button>
            {!item.isAddNew && (
              <Button
                // className="delete-btn"
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isLoading}
              >
                刪除
              </Button>
            )}
          </div>
        </UserContent>
      )}
    </BaseModal>
  );
};

export default UserModal;

const UserContent = styled.div`
  width: 60%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 5px;
  background: white;
  padding: 40px;
  > p {
    text-align: center;
  }
  .user-modal-head {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }




  .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    mask-image: url("/images/shared/close.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    min-width: 18px;
    min-height: 18px;
    background: black;
  }

  .user-modal-btn-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    gap: 50px;
    .delete-btn {
      color: red;
    }
  }
`;

const UserPermissionWrap = styled.div`
  padding: 20px;
  //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  //   border-radius: 5px;

  h3 {
    margin-bottom: 20px;
  }

  div {
    margin-bottom: 10px;

    label {
      margin-left: 10px;
    }
  }

  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #0056b3;
    }
  }

  .permissions-container {
    display: flex;
    flex-wrap: wrap;
  }

  .permission-item {
    flex-basis: calc(
      50% - 10px
    ); /* Assuming you want a 10px gap between columns */
    margin-bottom: 10px; /* Gap between rows */
  }
`;
