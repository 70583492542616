/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import moment from "moment";
import { toast } from "react-toastify";
import { getMaintenances, postMaintenances, putMaintenances } from "@api/cms";
import {
  API_DATE_TIME_FORMAT,
  DISPLAY_MUI_DATE_TIME_FORMAT,
} from "@constants/config";
import QuicklySelectTimeRange from "@components/shared/QuicklySelectTimeRange";

import {
  Grid,
  CircularProgress,
  Typography,
  FormControl,
  Box,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Icon
} from "@mui/material";
import { StyledGrid } from "@/styled_components/styled_grid";
import {
  LoaderContainer,
  FormTitleTypography,
} from "@/styled_components/common";

export default function MaintenancesPage() {
  const lang = "all";
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [noEndDate, setNoEndDate] = useState(false);
  const [maintenance, setMaintenance] = useState(null);
  // Additional state variables...
  const initData = () => {
    setStartDate(moment());
    setNoEndDate(false);
    setEndDate(moment());
  };
  const getData = async () => {
    const res = await getMaintenances(lang);
    const first = res?.[0];
    setMaintenance(first);
    if (first) {
      if (first?.startDate) setStartDate(moment(first.startDate));
      setNoEndDate(!first.isPeriod);
      if (first.isPeriod && first.endDate) setEndDate(moment(first.endDate));
      // setContent(first.content)
    } else {
      initData();
    }
  };
  useEffect(() => {
    getData();
  }, [lang]);

  const handleEndDateRadio = (e) => {
    setNoEndDate(e.currentTarget.value === "true");
  };

  const handleSubmit = async () => {
    const request = {
      id: maintenance ? maintenance.id : 0,
      content: "",
      // content: content,
      startDate: moment(startDate).format(API_DATE_TIME_FORMAT),
      endDate: noEndDate ? null : moment(endDate).format(API_DATE_TIME_FORMAT),
      language: maintenance ? maintenance.language : lang,
      isPeriod: !noEndDate,
    };

    let res;
    if (maintenance === undefined) res = await postMaintenances(request);
    else res = await putMaintenances(request);

    if (res) {
      toast.success("修改成功");
      setMaintenance({ ...res });
    }
    setIsLoading(false);
  };

  const handleStartDateFromQuickButton = (d) => {
    console.log("handleStartDateFromQuickButton ->   ", d);
    const startDateMoment = moment(d);
    setStartDate(startDateMoment);
    setNoEndDate(false);
  };

  const handleEndDateFromQuickButton = (d) => {
    console.log("handleEndDateFromQuickButton ->   ", d);
    const endDateMoment = moment(d);
    setEndDate(endDateMoment);
  };

  if (maintenance === null)
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );

  // Additional handlers...

  return (
    <Card
      elevation={3}
      className="w-full overflow-auto"
      style={{ padding: "24px" }}
    >
      {/* <StyledContainer> */}
      <QuicklySelectTimeRange
        setStartDate={handleStartDateFromQuickButton}
        setEndDate={handleEndDateFromQuickButton}
      />
      <Typography variant="subtitle1" gutterBottom>
        維護設定沒有分語言，在哪個語言下作設定都可以。（不管在哪個語言下設定都會一致）
      </Typography>

      <StyledGrid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {/* Additional UI elements... */}
          <Box mb={"16px"} display="flex">
            <FormTitleTypography>{"開始時間"}</FormTitleTypography>
            <FormControl style={{ maxWidth: "600px" }}>
              <DateTimePicker
                label="開始時間(年/月/日)"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                format={DISPLAY_MUI_DATE_TIME_FORMAT}
              />
            </FormControl>
          </Box>

          <Box mb={"16px"} display="flex">
            <FormTitleTypography>{"結束時間"}</FormTitleTypography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      id="endDate"
                      value={false}
                      checked={noEndDate === false}
                      onChange={handleEndDateRadio}
                    />
                  }
                  label="指定時間"
                />
                <DateTimePicker
                  label="結束時間(年/月/日)"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  format={DISPLAY_MUI_DATE_TIME_FORMAT}
                />
                <FormControlLabel
                  value="male"
                  control={
                    <Radio
                      id="noEndDate"
                      value={true}
                      checked={noEndDate === true}
                      onChange={handleEndDateRadio}
                    />
                  }
                  label="無結束日期"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/* <DatePicker
            label="開始時間(年/月/日)"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />

          <DatePicker
            label="結束時間(年/月/日)"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"end"}>
            <Grid>
              <Button
                sx={{ mt: 8 }}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
                mt={10}
              >
                <Icon>save</Icon>&nbsp;
                儲存
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {isLoading && <CircularProgress />}
      </StyledGrid>
      {/* </StyledContainer> */}
    </Card>
  );
}
