/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UploadImageWithTitle } from "@components/shared/ImageUploadZone";
import { useSelectLang } from "@hooks/useLang";
import {
  copyallBanners,
  deleteBanners,
  getBanners,
  postBanners,
  putBanners,
} from "@api/cms";
import { IMAGE_DOMAIN } from "@constants/api";
import { toast } from "react-toastify";
import QuicklyCopySettings from "@components/shared/QuicklyCopySettings";
import BannerModal from "@components/banners/BannerModal";
import { Box } from "@mui/material";
import { Card } from "@mui/material";

export default function BannerPage() {
  const { lang } = useSelectLang();
  const [banners, setBanners] = useState(null);
  const [tempImageFiles, setTempImageFiles] = useState({}); //id: Files
  const [tempPreview, setTempPreview] = useState({}); //id: preview url
  const [selectedBanner, setSelectedBanner] = useState(null);

  const getData = async () => {
    const res = await getBanners(lang);
    let maxId = 0;
    let data = res
      ? res.map((r) => {
        if (maxId < r.id) maxId = r.id;
        return { ...r, imgUrl: `${IMAGE_DOMAIN}/${r.imagePath}` };
      })
      : [];
    data.push({ id: maxId + 1, imgUrl: "", isAddNew: true, language: lang });
    setBanners(data);
  };
  useEffect(() => {
    getData();
  }, [lang]);

  const setTempImage = (id, file, preview) => {
    if (file && preview) {
      setTempImageFiles({ ...tempImageFiles, [id]: file });
      setTempPreview({ ...tempPreview, [id]: preview });
    }
  };
  const handleCheckImage = async (id) => {
    const selectedImg = banners.filter((r) => r.id === id)[0];
    let res;

    if (selectedImg && selectedImg.imgUrl) {
      res = await putBanners({
        ID: selectedImg.id,
        Sort: selectedImg.sort,
        Language: selectedImg.language,
        Image: tempImageFiles[id],
      });
    } else {
      res = await postBanners({
        ID: 0,
        Sort: 1,
        Language: lang,
        Image: tempImageFiles[id],
      });
    }

    if (res.status && res.statu !== 200) {
      toast.error("修改失敗");
    } else if (res) {
      toast.success("修改成功");
      await getData();
      let newTempFile = { ...tempImageFiles };
      let newTempPreview = { ...tempPreview };
      delete newTempFile[id];
      delete newTempPreview[id];
      setTempImageFiles({ ...newTempFile });
      setTempPreview({ ...newTempPreview });
    } else {
      toast.error("修改失敗");
    }
  };
  const handleDeleteImage = async (id) => {
    if (tempImageFiles.hasOwnProperty(id)) {
      let newTempFile = { ...tempImageFiles };
      let newTempPreview = { ...tempPreview };
      delete newTempFile[id];
      delete newTempPreview[id];
      setTempImageFiles({ ...newTempFile });
      setTempPreview({ ...newTempPreview });
    } else {
      let res = await deleteBanners(id);
      try {
        if (JSON.stringify(res) === "{}") {
          toast.success("修改成功");
          await getData();
          return;
        }
      } catch (e) {
        console.log(e);
      }
      toast.error("修改失敗");
    }
  };

  const handleQuicklyCopySubmit = async (toLanguage) => {
    if (!lang || !toLanguage) {
      toast.error("請重新整理網頁後再嘗試");
    } else {
      const res = await copyallBanners({ fromLanguage: lang, toLanguage });
      if (res && typeof res === "object" && banners)
        if (res?.length === banners?.length - 1)
          toast.success(`複製到 ${toLanguage} 成功`);
    }
  };

  const handleCloseModal = async (reGetData) => {
    if (reGetData) await getData();
    setSelectedBanner(null);
  };

  const handleEdit = (id) => {
    const selectedBanner = banners.filter((r) => r.id === id)[0];
    setSelectedBanner(selectedBanner);
  };

  const handleCreate = (id) => {
    const selectedBanner = banners.filter((r) => r.id === id)[0];
    setSelectedBanner(selectedBanner);
  };

  if (!banners) return <div>Loading...</div>;

  return (
    <Card
      elevation={3}
      className="w-full overflow-auto"
      style={{ padding: "24px" }}
    >
      <BannerPageWrap>
        <QuicklyCopySettings onSubmit={handleQuicklyCopySubmit} />
        Banner - 1080 x 440 （長x寬）.jpg 。<strong>圖片檔名不可重複</strong>
        修改教學：透過點選左上角圖片/拖拉來選擇圖片，選擇後點擊右上角的勾勾 <br />
        刪除教學：透過點選左側的垃圾桶來刪除該圖片 <br />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {banners &&
            banners.map((i, idx) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <UploadImageWithTitle

                  key={i.id}
                  title={`橫幅 ${idx + 1}`}
                  data={{ ...i, tempImageUrl: tempPreview[i.id] }}
                  setTempImage={setTempImage}
                  onCheck={handleCheckImage}
                  onDelete={handleDeleteImage}
                  onEdit={handleEdit}
                  onCreate={handleCreate}
                />
              </Box>

            ))}
        </div>
        <BannerModal
          show={selectedBanner !== null}
          item={selectedBanner}
          onClose={handleCloseModal}
        />
      </BannerPageWrap>
    </Card>
  );
}

const BannerPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .banner-list {
    display: block;
    width: 100%;
  }
`;
