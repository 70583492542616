import React, { Fragment } from 'react'
import { Menu,Box, Avatar } from '@mui/material'
import styled from "styled-components";

const MatxMenu = (props) => {
    const {account} = props;
    const [anchorEl, setAnchorEl] = React.useState(null)
    const children = React.Children.toArray(props.children)
    let { shouldCloseOnItemClick = true, horizontalPosition = 'left' } = props

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Fragment>
            <div onClick={handleClick}>
                {props.menuButton}
            </div>
            <Menu
                    
                    elevation={8}
                    getContentAnchorEl={null}
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: horizontalPosition,
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: horizontalPosition,
                    }}
                    MenuListProps={{
                        style:{
                            paddingTop: '0px'
                        }
                    }}
                >
                    <div>
                    <Box style={{ width: '100%', height: '100px',
                            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("/images/shared/profile_background.png")', 
                            backgroundSize: 'cover',
                            backdropFilter: 'blur(50px)' ,
                            display: 'flex',
                            alignItems: 'center',
                            padding: "0 10px"
                         }}>
                        <AvatarWrap>
                            <Avatar
                                style={{ backgroundColor: 'rgb(158, 119, 237)', color: '#fff', width: '30px', height: '30px' }}
                                className="cursor-pointer"

                            > {account && account.charAt(0).toUpperCase()} </Avatar>
                            <span style={{ marginLeft: '8px' }}>
                            <strong>{account}</strong>
                                </span>
                        </AvatarWrap>
                    </Box>

                    {children.map((child, index) => (
                        <div
                            onClick={
                                shouldCloseOnItemClick ? handleClose : () => {}
                            }
                            key={index}
                        >
                            {child}
                        </div>
                    ))}
                    </div>
                </Menu>
        </Fragment>
    )
}

export default MatxMenu

const AvatarWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  
`;
