import React from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GlobalToast = () => {
  return (
    <GlobalToastWrap>
      <ToastContainer autoClose={500} />
    </GlobalToastWrap>
  );
};

export default GlobalToast;

const GlobalToastWrap = styled.div`
  // .Toastify__toast-theme--light {
  //     background: red;
  //     color: white;
  // }
  // .Toastify__close-button--light {
  //     color: white;
  // }
`;
