import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)`
  && {
    // background-color: #4caf50;
    color: #fff;
    margin-top: 20px;
    padding: 10px 20px;
    // font-size: 16px;
    transition: background-color 0.3s;

    // &:hover {
    //   background-color: #388e3c;
    // }

    // &:disabled {
    //   background-color: #a5d6a7;
    //   color: #fff;
    // }
  }
`;
