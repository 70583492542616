import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ModalContainer } from "@components/shared/modals/BaseModal";
import SidebarMenuProvider from "@hooks/useSidebarMenu";
import ROUTES from "@constants/routes";
import Root from "@routes/Root";
import ErrorPage from "@routes/ErrorPage";
// import HomePage from '@routes/HomePage';
import LoginPage from "@routes/LoginPage";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "@constants/GlobalStyles";
import MarqueePage from "@routes/MarqueePage";
import BannerPage from "@routes/BannerPage";
import AnnouncementPage from "@routes/AnnouncementPage";
import SelectedLangProvider from "@hooks/useLang";
import GlobalToast from "@components/shared/GlobalToast";
import NewsPage from "@routes/NewsPage";
import MaintenancesPage from "@routes/MaintenancesPage";
import GroupsPage from "@routes/GroupsPage";
import GamesPage from "@routes/GamesPage";
import LogosPage from "@routes/LogosPage";
import ThemePage from "@routes/ThemePage";
import UsersPage from "@routes/UsersPage";
import DWPolicyPage from "@routes/DWPolicyPage";
import SystemMetasPage from "@routes/SystemMetasPage";
import AdminPanelThemePage from "@routes/AdminPanelThemePage";

import PanelThemeProvider from "@hooks/usePanelTheme";

// import SegoeUIWoff2 from './fonts/SegoeUI-Roman-VF_web.woff2';


const router = createBrowserRouter([
  {
    path: ROUTES.LOGIN,
    element: <LoginPage />,
  },
  {
    path: ROUTES.ROOT,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.MARQUEE} />,
        // element: <HomePage />,
      },
      {
        index: true,
        path: ROUTES.MARQUEE,
        element: <MarqueePage />,
      },
      {
        path: ROUTES.BANNER,
        element: <BannerPage />,
      },
      {
        path: ROUTES.ANNOUNCEMENT,
        element: <AnnouncementPage />,
      },
      {
        path: ROUTES.NEWS,
        element: <NewsPage />,
      },
      {
        path: ROUTES.GROUPS,
        element: <GroupsPage />,
      },
      {
        path: ROUTES.GAMES,
        element: <GamesPage />,
      },
      {
        path: ROUTES.D_W_POLICY,
        element: <DWPolicyPage />,
      },
      {
        path: ROUTES.THEME,
        element: <ThemePage />,
      },
      {
        path: ROUTES.LOGOS,
        element: <LogosPage />,
      },
      {
        path: ROUTES.MAINTENANCES,
        element: <MaintenancesPage />,
      },
      {
        path: ROUTES.USERS,
        element: <UsersPage />,
      },
      {
        path: ROUTES.SYSTEM,
        element: <SystemMetasPage />,
      },
      {
        path: ROUTES.ADMIN_PANEL,
        element: <AdminPanelThemePage />,
      },
      {
        path: ROUTES.EMPTY,
        element: <></>,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <PanelThemeProvider>
        <SelectedLangProvider>
          <SidebarMenuProvider>
            <GlobalStyles />
            <RouterProvider router={router} />
            <GlobalToast />
            <ModalContainer />
          </SidebarMenuProvider>
        </SelectedLangProvider>
      </PanelThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
