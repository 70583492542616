export const Primary = {

    SideBar: '#1C2536',
    MunuItemText: '#B5BCC4',

    Black: 'black',
    White: 'white',
    Red: 'red',
    Green: '#01DC66',
    LightGreen: '#dffff4',
    Color: 'rgb(220,40,1)',
    Color2: 'rgb(255,234,223)',
}

export const Transparent = {
    Dark20: '#10161E33',
    Dark30: '#10161E4D',
    Dark50: '#10161E7F'
}
