/* eslint no-console: ["error", { allow: ["log", "error"] }] */
// import { STATUS_CODE } from '@constants/api';
import { lowerCaseObjectKeys } from "src/utils/util";
import { getTokenFromLS, setTokenToLS } from "./ls";
import { SWITCH_ROUTES } from "@constants/routes";
// import { toast } from 'react-toastify';

export const FETCH_METHOD = "POST" | "GET" | "DELETE" | "PUT" | "PATCH";
export function headerConfig(type, payload, isForm, config) {


  let headers = isForm
    ? {
      Authorization: `bearer ${getTokenFromLS()}`,
    }
    : {
      // cache: 'no-cache',
      Authorization: `bearer ${getTokenFromLS()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };

  if (config && config.noHeader) {
    headers = {}
  }

  const realPayload = isForm ? payload : JSON.stringify(payload);
  const body = type === "GET" ? null : realPayload;
  return {
    method: type,
    headers,
    body,
  };
}

export async function generalFetch(url, method, payload, isForm, config) {
  return fetch(url, { ...headerConfig(method, payload, isForm, config) })
    .then((response) => {
      if (response && response.status === 401) {
        setTokenToLS(null);
        if (window.location.pathname !== SWITCH_ROUTES.LOGIN)
          window.location.href = SWITCH_ROUTES.LOGIN;
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json()
      } else if (contentType && contentType.indexOf("application/vnd.openxmlformats") !== -1) {
        return response.blob()
      } else {
        return response.text().then(text => {
          try {
            const jsonData = JSON.parse(text);
            return jsonData;
          } catch (error) {
            throw text;
          }

        });
      }
    })
    .then((res) => {
      if (config?.noJsonRes) return res;

      let result;
      if (res && typeof res === "object") result = lowerCaseObjectKeys(res);
      // const { rtn, msg } = res;
      // if (code !== STATUS_CODE.SUCCESS)
      //     toast.error(`Error Code:${res.code}. Msg:${msg}`);

      return result;
    })
    .catch((response) => {
      console.log("error", `${url}. ${response}`);
      throw response
    });
}
