/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  // TextField,
  Switch, FormGroup
} from "@mui/material";

import { StyledFormControlLabel } from "@/styled_components/styled_form_control_label";

export function StepperOptionForm({ key, optionKey, value, onChange }) {
  const [options, setOptions] = useState({
    display: false,
    step: 10,
  });

  const notifyChange = (newOptions) => {
    setOptions(newOptions);
    if (typeof onChange === "function") {
      onChange(optionKey, newOptions);
    }
  };

  useEffect(() => {
    if (Object.keys(value).length > 0) setOptions(value);
  }, []);

  const handleDisplayChange = (e) => {
    const target = e.target;
    const name = target.name;

    setOptions((prev) => {
      return { ...prev, [name]: target.checked };
    });
    notifyChange({
      ...options,
      [name]: target.checked,
    });
  };


  // const handleTextChange = (e) => {
  //   const target = e.target;
  //   const name = target.name;
  //   setOptions((prev) => {
  //     return { ...prev, [name]: parseFloat(target.value) };
  //   });
  //   notifyChange({
  //     ...options,
  //     [name]: parseFloat(target.value),
  //   });
  // };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormGroup>
            <StyledFormControlLabel
              control={
                <Switch
                  name="display"
                  onChange={handleDisplayChange}
                  checked={options.display}
                />
              }
              label="啟用"
            />
          </FormGroup>
        </Grid>

        {/* <Grid item xs={12} sm={3}>
          <TextField
            required
            label="級距"
            variant="outlined"
            name={`step`}
            value={options.step}
            onChange={handleTextChange}
            fullWidth
          />
        </Grid> */}

      </Grid>

    </>
  );
}
