/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UploadImageWithTitle } from "@components/shared/ImageUploadZone";
import { useSelectLang } from "@hooks/useLang";
import { postSingleImage } from "@api/cms";
import { IMAGE_DOMAIN } from "@constants/api";
import { toast } from "react-toastify";
import { LOGO_SETUP_ITEMS } from "@constants/config";
import { getCurrentYYYYMMDDhhmmss } from "@utils/util";
import { Card } from "@mui/material";

export default function LogosPage() {
  const { lang } = useSelectLang();
  const [logos, setLogos] = useState(null);
  const [tempImageFiles, setTempImageFiles] = useState({}); //id: Files
  const [tempPreview, setTempPreview] = useState({}); //id: preview url

  const initData = async () => {
    setLogos(
      LOGO_SETUP_ITEMS.map((l) => {
        return {
          ...l,
          imgUrl: `${IMAGE_DOMAIN}/Images/${l.filename}${l.extension
            }?gettime=${getCurrentYYYYMMDDhhmmss()}`,
        };
      })
    );
  };
  useEffect(() => {
    initData();
  }, [lang]);

  const setTempImage = (id, file, preview) => {
    if (file && preview) {
      setTempImageFiles({ ...tempImageFiles, [id]: file });
      setTempPreview({ ...tempPreview, [id]: preview });
    }
  };
  const handleCheckImage = async (id) => {
    const selectedImg = logos.filter((r) => r.id === id)[0];

    if (selectedImg && selectedImg.imgUrl) {
      const res = await postSingleImage({
        filename: selectedImg.filename,
        Image: tempImageFiles[id],
      });
      if (res.status && res.statu !== 200) {
        toast.error("修改失敗");
      } else if (res) {
        toast.success("修改成功");
        await initData();
        let newTempFile = { ...tempImageFiles };
        let newTempPreview = { ...tempPreview };
        delete newTempFile[id];
        delete newTempPreview[id];
        setTempImageFiles({ ...newTempFile });
        setTempPreview({ ...newTempPreview });
      } else {
        toast.error("修改失敗");
      }
    }
  };
  const handleDeleteImage = async (id) => {
    if (tempImageFiles.hasOwnProperty(id)) {
      let newTempFile = { ...tempImageFiles };
      let newTempPreview = { ...tempPreview };
      delete newTempFile[id];
      delete newTempPreview[id];
      setTempImageFiles({ ...newTempFile });
      setTempPreview({ ...newTempPreview });
    } else {
      toast.info("無法刪除Logo");
    }
  };

  if (!logos) return <div>Loading....</div>;

  return (
    <Card
      elevation={3}
      className="w-full overflow-auto"
      style={{ padding: "24px" }}
    >
      <LogosPageWrap>
        <strong>
          Logos設定沒有分語言，在哪個語言下作設定都可以。（不管在哪個語言下設定都會一致）
        </strong>
        <strong>新圖片請依照指定的size和副檔名</strong>
        修改教學：透過點選左上角圖片/拖拉來選擇圖片，選擇後點擊右上角的勾勾 <br />
        不支援刪除Logo <br />
        <div className="banner-list">
          {logos.map((i, idx) => (
            <UploadImageWithTitle
              key={i.id}
              title={`${i.name} (${i.limitInfo}${i.extension})`}
              data={{ ...i, tempImageUrl: tempPreview[i.id] }}
              setTempImage={setTempImage}
              onCheck={handleCheckImage}
              onDelete={handleDeleteImage}
            />
          ))}
        </div>
      </LogosPageWrap>
    </Card>
  );
}

const LogosPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .banner-list {
    display: block;
    width: 100%;
  }
`;
