/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  FormLabel,
  FormGroup,
  Switch,
} from "@mui/material";

import { getBankOutList } from "@api/cms";
import { StyledFormControlLabel } from "@/styled_components/styled_form_control_label";

export function BankQRCodeOptionForm({ key, optionKey, value, onChange }) {
  const [options, setOptions] = useState({

  });

  const [bankList, setBankList] = useState([]);

  const notifyChange = (newOptions) => {
    if (typeof onChange === "function") {
      onChange(optionKey, newOptions);
    }
  };

  useEffect(() => {
    if (value && Object.keys(value).length > 0) setOptions(value);
  }, []);

  const handleDisplayChange = (e, bankCode) => {
    const target = e.target;
    const name = target.name;

    setOptions((prev) => {
      return { ...prev, [bankCode]: { ...options[bankCode], [name]: target.checked } };
    });
    notifyChange({
      ...options,
      [bankCode]: { ...options[bankCode], [name]: target.checked },
    });
  };

  // const handleTextChange = (e, bankCode) => {
  //   const target = e.target;
  //   const name = target.name;

  //   setOptions((prev) => {
  //     return { ...prev, [bankCode]: { ...options[bankCode], [name]: target.value } };
  //   });
  //   notifyChange({
  //     ...options,
  //     [bankCode]: { ...options[bankCode], [name]: target.value }
  //   });
  // };

  const getData = async () => {
    getBankOutList().then((res) => {
      if (res?.rtn === 0 && res?.bank_list) {
        setBankList(res?.bank_list.map((e) => {
          const [bankcode] = e;
          return bankcode.trim();
        }));
      }
    });
  };
  console.log(bankList)

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {bankList.map((bankCode) => (
          <React.Fragment key={bankCode}>

            <Grid item xs={12} sm={12}>

              <FormGroup key={`${key}-${bankCode}`}>
                <FormLabel style={{ marginBottom: '10px' }}>
                  {bankCode}
                </FormLabel>

              </FormGroup>
              <StyledFormControlLabel
                control={
                  <Switch
                    name="display"
                    onChange={(e) => { handleDisplayChange(e, bankCode) }}
                    checked={options[bankCode] && options[bankCode].display}
                  />
                }
                label="顯示QRCode"
              />
            </Grid>
            {/* <Grid key={`news-lang-select-${bankCode}`} item xs={12} sm={12}>
              <TextField
                label="QRCode內容文字(網址)"
                variant="outlined"
                name={`content`}
                value={options[bankCode] && options[bankCode].content}
                onChange={(e) => { handleTextChange(e, bankCode) }}
                fullWidth
              />
            </Grid> */}
          </React.Fragment>

        ))}
      </Grid>
    </>
  );
}
