import jwt from "jwt-decode";

export function getKVInLS(key) {
  return window.localStorage.getItem(key);
}
export function setKVInLs(key, value) {
  if (typeof window === "undefined") return;
  window.localStorage.setItem(key, value);
}

const SIDEBAR_MODE_KEY = "sidebarMode"; //'collapse' : 'expand'
export function getSidebarMode() {
  const result = getKVInLS(SIDEBAR_MODE_KEY);
  return result;
  // return result || (window.innerWidth < 1280 ? 'collapse' : 'expand');
}
export function setSidebarMode(type) {
  setKVInLs(SIDEBAR_MODE_KEY, type);
}

const LANG_KEY = "selectedLang"; // config.js -> LANGS
export function getSelectedLang() {
  const result = getKVInLS(LANG_KEY);
  return result;
}
export function setSelectedLang(lang) {
  setKVInLs(LANG_KEY, lang);
}

const TOKEN_KEY = "token";

export function getTokenFromLS() {
  const result = getKVInLS(TOKEN_KEY);
  return result;
}


export function setTokenToLS(t) {
  setKVInLs(TOKEN_KEY, t);
}

const EMAIL_KEY = "email";

export function getEmailFromLS() {
  const result = getKVInLS(EMAIL_KEY);
  return result;
}

export function setEmailToLS(t) {
  setKVInLs(EMAIL_KEY, t);
}


const PERMISSIONS_KEY = "permissions";
export function getPermissionsFromLS() {
  const result = getKVInLS(PERMISSIONS_KEY);
  return JSON.parse(result);
}

export function setPermissionsFromTokenToLS(t) {
  const user = jwt(t);
  setKVInLs(PERMISSIONS_KEY, user.Permissions ?? null);
}

export function setPermissionsToLS(permissions) {
  setKVInLs(PERMISSIONS_KEY, JSON.stringify(permissions) ?? null);
}
